import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../frontend/components/Footer";
import UserApiClient from "../../utilities/apiClients";
import Swal from "sweetalert2";
import Masonry from "react-masonry-css";

const DiscoverVideoBox = React.memo(() => {
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [pageLinks, setPageLinks] = useState({ next: null, prev: null });
  const navigate = useNavigate();

  const fetchVideoData = async (pageLink) => {
    const pageUrl =
      typeof pageLink === "string"
        ? pageLink
        : `api/video/hunyuan/?status=completed`;

    await UserApiClient.get(
      `api/video/hunyuan/?status=completed`,
      {},
      false
    ).then(async (res) => {
      if (res.success) {
        console.log(res, "res");
        const newVideos = res.data;
        if (!newVideos || newVideos.length === 0) {
          Swal.fire({
            title: "No more videos to load!",
            icon: "info",
            confirmButtonColor: "#990013",
            timer: 3000,
          });
          setShowMoreBtn(false);
          return;
        }
        setVideoData((prevData) => [...prevData, ...newVideos]);
        setPageLinks({ next: res.data.next, prev: res.data.previous });
      } else {
        Swal.fire({
          title: "Our Servers are busy!",
          text: "Please wait a moment and try again.",
          icon: "warning",
          timer: 3000,
        });
      }
    });
  };

  useEffect(() => {
    fetchVideoData();
  }, []);

  const handleVideoView = (videoId) => {
    navigate(`/dashboard/VideoView/${videoId}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <>
      <p className="text-2xl lg:text-4xl font-medium mt-1 lg:mt-10 text-center">
        You Might Also Enjoy
      </p>
      <div className="p-5 md:p-10">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {videoData.map((item, index) => (
            <div
              className="relative group"
              key={index}
              onClick={() => handleVideoView(item.id)}
            >
              <video
                className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
                src={`${item.url}#t=0.1`}
                muted
                typeof="video/mp4"
                autoPlay
                preload="metadata"
                loop
                playsInline
              />
              <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-100 transition-opacity duration-300 p-4 text-white text-center border-1 border-gray-400 rounded-[10px]">
                <p className="text-sm sm:text-sm md:text-md truncate">
                  {item.prompt}
                </p>
                <h2 className="text-red-800 text-xl text-wrap">@{item.user}</h2>
              </div>
            </div>
          ))}
        </Masonry>
      </div>
      {showMoreBtn && pageLinks.next && (
        <div className="flex items-center w-full justify-center mt-10">
          <button
            className="bg-red-800 w-75% text-white font-medium rounded-2xl py-2 px-4"
            onClick={() => fetchVideoData(pageLinks.next)}
          >
            <i className="fa-solid fa-angles-down mr-1"></i> Load More
          </button>
        </div>
      )}
      <Footer />
    </>
  );
});

export default DiscoverVideoBox;
