import React, { useState } from "react";
import GenerationImagesBox from "../../components/GenerationImagesBox";
import Footer from "../../../frontend/components/Footer";
import PageWrapper from '../../../utilities/Metatags'
import GenerationVideosBox from "../../components/GenerationVideosBox";
import { useSearchParams } from "react-router-dom";


const Generations = () => {
  const [open, setOpen] = useState(false);
  // const [activeComponent, setActiveComponent] = useState("Images")
  const [searchParams, setSearchParams] = useSearchParams();
  const activeComponent = searchParams.get("view") || "Images";

  const updateView = (view, status = null) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("view", view);
    if (status) {
        newParams.set("status", status);
    }
    setSearchParams(newParams);
};

  const Showcasedata = [
    // { name: 'Waiting', icon: <IoIosTime size={20} color='#616161' /> }, { name: 'All', icon: <AiFillLike size={20} color='#616161' /> }, { name: 'Upscaled', icon: <GiRoundStar size={20} color='#616161' /> }, { name: 'Liked', icon: <FaHeart size={20} color='#616161' /> },
  ];

  const afterModelData = [
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
    { name: "AFTER SEX" },
  ];

  return (
    <>
      <PageWrapper
        title={"Eromantic.ai- Watch Your AI Gf/Bf"}
        description={
          "Your dream companion awaits! Create your AI Girlfriend, shape her look, personality, and bring her to life in one click."
        }
        keywords={
          "AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        }
      >
        <div className="mt-20 lg:px-20 px-2  text-black font-josefin-sans">
          <div className="flex justify-center xl:justify-between flex-wrap items-center">
            <div className="flex justify-center lg:justify-start items-center flex-wrap">
              {Showcasedata.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-60 md:w-48 m-2 justify-center flex items-center  gap-2 px-4 py-2 border-2 bg-[#6161614A] hover:cursor-pointer hover:bg-[#FF00133D] border-zinc-600 text-black text-lg rounded-full hover:border-red-700 hover:text-red-500"
                  >
                    {item.icon}
                    <p className="text-center">{item.name}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {open && (
            <div>
              <div className="border-2 px-11 py-8 border-zinc-600 bg-[#6161614A] rounded-xl w-full h-[465px] overflow-y-auto scrollbar-thumb-rose-500 mt-5">
                <div className="flex flex-wrap justify-center gap-7 ">
                  {afterModelData.map((item1, index) => {
                    return (
                      <div
                        key={index}
                        className="bg-[#ECECEC] w-[268px] h-[172px] rounded-2xl mt-3"
                      >
                        <div className="aftersexBg opacity-60 w-[268px] h-[172px] rounded-2xl flex justify-center items-center">
                          <p className="text-xl">{item1.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row rounded-tr-xl rounded-br-xl rounded-tl-xl rounded-bl-xl justify-between lg:text-xl bg-red-600">
            <button
              className={`flex-1 flex items-center justify-center gap-2 md:gap-5 px-3 md:px-5 xl:px-10 py-3 rounded-tl-xl md:rounded-bl-xl rounded-tr-xl md:rounded-tr-none  ${activeComponent === "Images"
                  ? "bg-red-800"
                  : "bg-red-600 hover:bg-red-800"
                }`}
              onClick={() => updateView("Images")}
            >
              <p className="text-white text-sm md:text-base xl:text-lg">
                Images
              </p>
            </button>
            <button
              className={`flex-1 flex items-center justify-center gap-2 md:gap-5 px-3 md:px-5 xl:px-10 py-3 relative  rounded-xl md:rounded-tl-none md:rounded-bl-none ${activeComponent === "Videos"
                  ? "bg-red-800"
                  : "bg-red-600 hover:bg-red-800"
                }`}
              onClick={() => updateView('Videos')}
            >
              <p className="text-white text-sm md:text-base xl:text-lg">
                Videos
              </p>
            </button>
          </div>

          {activeComponent === "Images" ?
            <GenerationImagesBox /> : <GenerationVideosBox  updateView={updateView}/>}


        </div>
      </PageWrapper>
      <Footer />
    </>
  );
};

export default Generations;
