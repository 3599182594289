

import React, { useState, useEffect, useRef } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import UserApiClient from "../../utilities/apiClients";
import Swal from "sweetalert2";
import loaders from "../../frontend/components/loaders";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircularWithValueLabel from "../dashboardPages/CreateDreamGF/CircularWithValueLabel";
import { toast, ToastContainer } from "react-toastify";
import UpgradeNow from "./UpgradeNow";
import Masonry from "react-masonry-css";
import { Skeleton } from "@mui/material";

const GenerationImagesBox = () => {
  const navigate = useNavigate();

  const [loadingStates, setLoadingStates] = useState({});
  const [imageData, setImageData] = useState([]);
  const [imageDataLen, setImageDataLen] = useState(0);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [upscalecurrenttag, setUpscalecurrenttag] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoadingsingleimage, setIsLoadingsingleimage] = useState(true);
  const [errorisOpen, setErrorIsOpen] = useState(false);
  const [hover, setHover] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState("");
  const modalRef = useRef(null);
  const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });
  const [sortOption, setSortOption] = useState('newest'); // Default is 'newest'

  // Handle change event for the select input
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const errorCloseModal = () => {
    setErrorIsOpen(false); // Close the modal
    navigate('/'); // Navigate to the root ("/") route
  };
  const errorOpenModal = () => setErrorIsOpen(true);

  // Handler for when the image finishes loading
  const handleImageLoad = () => {
    setTimeout(() => {
      setIsLoadingsingleimage(false);
    }, 2000)
  };

  // Handle input change and update search query state
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  useEffect(() => {
    fetchImageData();
  }, [sortOption]);

  // Filter image data based on search query (search within the generation_prompt field)
  const filteredItems = imageData.filter((item) =>
    item.generation_prompt.toLowerCase().includes(searchQuery)
  );

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const upscale = async (generation_id, image_file_name, imgIndex, index) => {
    try {
      setLoadingStates((prev) => {
        return { ...prev, [image_file_name]: true };
      });
      const res = await UserApiClient.post(
        "api/v1/image/upscale/",
        { generation_id, image_file_name },
        false,
        false,
        false
      );

      if (res.success) {
        setImageData((prevData) => {
          const updatedData = [...prevData];
          const updatedImageFileNames = [...updatedData[index].image_file_name];
          updatedImageFileNames[imgIndex] = res.data.images;

          updatedData[index] = {
            ...updatedData[index],
            image_file_name: updatedImageFileNames,
          };
          return updatedData;
        });
        setUpscalecurrenttag(imgIndex);
      } else {
        Swal.fire(
          "Insufficient tokens for image upscaling.",
          "",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong during the upscale process.", "error");
    } finally {
      setLoadingStates((prev) => ({ ...prev, [image_file_name]: false }));
    }
  };



  const fetchImageData = async (pageLink) => {
    loaders(true, "heart");

    const pageUrl = typeof pageLink === 'string' ? pageLink : `api/v1/get_allGenerated_image/?page_size=10&order_by=${sortOption}`;
    await UserApiClient.get(pageUrl, {}, false).then(async (res) => {
      if (res.success) {
        const newImages = res.data.results;

        if (!newImages || newImages.length === 0) {
          Swal.fire({
            title: "No more images to load!",
            icon: "info",
            confirmButtonColor: "#990013",
            timer: 3000,
          });
          setShowMoreBtn(false);
          loaders(false, "heart");
          return;
        }
        // setImageData((prevData) => {
        //   const newData = [...prevData];
        //   newImages.forEach((newImage) => {
        //     const duplicate = newData.some(
        //       (image) => image.generation_id === newImage.generation_id
        //     );
        //     if (!duplicate) {
        //       newData.push(newImage);
        //     }
        //   });
        //   return newData;
        // });
        setImageData(newImages);

        setPageLinks({ next: res.data.next, prev: res.data.previous });
        setImageDataLen(newImages.length);
        loaders(false, "heart");
      } else {
        loaders(false, "heart");
        Swal.fire({
          title: "Oops! You don't have any images generated...",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#990013",
          backdrop: "#b1b1b1eb",
          timer: 3000,
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/ai-porn-image-generator");
          }
        });
      }
    });
  };

  useEffect(() => {
    const subscription = localStorage.getItem('subscription_type');
    // const FreeWeek = localStorage.getItem('FWeek');
    if (subscription === "free") {
      errorOpenModal();
      return;
    }
    // fetchImageData();
    window.scrollTo(0, 0);
  }, []);

  const deleteImage = async (imageUrl, generation_id, imgIndex) => {
    try {
      const res = await UserApiClient.delete("api/v1/image/delete/", { img_url: imageUrl, generation_id });
      if (res.success) {

        setImageData((prevData) => {
          return prevData.map((item) => {
            if (item.generation_id === generation_id) {
              // Remove the specific imageUrl from the image_file_name array
              item.image_file_name = item.image_file_name.filter((img, index) => index !== imgIndex);
            }
            return item;
          }).filter((item) => item.image_file_name.length > 0); // Optional: filter out objects with no images left
        });

        setUpdateTrigger(`${generation_id}-${imgIndex}`);
        Swal.fire("Success!", "Image deleted successfully.", "success");
        setShowMoreBtn(true);
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };


  const downloadImage = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch the image');
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `generated-image_${Date.now()}.png`;
  
      // Perform the download action
      document.body.appendChild(link);
      link.click();
  
      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
  
      // Show success toast only after download is triggered
      toast.success("Downloading...", {
        position: "top-left",
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        autoClose: 2000,
        pauseOnHover: false,
        draggable: false,
        theme: "colored"
      });
      
    } catch (error) {
      console.error('Failed to download the image:', error);
    }
  };
  
  // if (loading && !currentLoadingImageId) return <div>Loading...</div>;
  const breakpointColumnsObj = {
    default: 4,  // 4 columns by default
    1100: 3,     // 3 columns if viewport is < 1100px
    700: 2,      // 2 columns if viewport is < 700px
    500: 1       // 1 column if viewport is < 500px
  };
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    // Set a timeout to allow hover effect after 2 seconds
    const timer = setTimeout(() => {
      setHasLoaded(true);
    }, 2500); // 2 seconds

    // Cleanup the timer when the component unmounts or image is reloaded
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="p-0 md:p-10">
        <div className="flex-wrap md:flex justify-between">
          <div className="m-0 mo:m-2 px-3 py-2 text-black w-full mo:w-1/2 border-2 border-zinc-600 flex items-center gap-5 bg-white rounded-full">
            <IoSearchOutline color="black" size={23} />
            <input type="text" placeholder="Search anything" className="bg-white border-0 w-full mo:w-1/2 rounded-full focus:outline-none text-xl" value={searchQuery}
              onChange={handleSearchChange} />
          </div>

          <div className="flex items-center space-x-2 mt-2 md:mt-0">
            <label htmlFor="sort-options" className="text-lg font-semibold">Sort by:</label>
            <select id="sort-options" name="sort-options"
              className="py-1 px-4 border-0 rounded-md bg-white text-gray-700"
              value={sortOption} // Bind state value to the select element
              onChange={handleSortChange} // Handle change event
            >
              <option value="latest">Latest</option>
              <option value="oldest">Oldest</option>
              <option value="upscale">Upscaled</option>
            </select>
          </div>

        </div>

        {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 mt-16"> */}
        <Masonry breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {filteredItems.map((item, index) => {
            return item.image_file_name.map((imageUrl, imgIndex) => {
              const isLoading = loadingStates[imageUrl];
              return (
                <div className={`relative group rounded-2xl aspect-auto  bg-url(${imageUrl})`}
                  key={`${index}-${imgIndex}`} onMouseEnter={() => setHover(`${index}-${imgIndex}`)} onMouseLeave={() => setHover(null)} onTouchStart={() => setHover(`${index}-${imgIndex}`)}>
                  <div className={`relative w-full aspect-auto min-h-[130px] rounded-2xl ${(isLoadingsingleimage) ? 'h-60' : 'h-full'}`}>
                    {(isLoadingsingleimage) && (
                      <div className="absolute inset-0 flex justify-center items-center bg-[#2a2a2a] rounded-2xl">
                        {/* Skeleton with Shimmer Animation */}
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          height="100%"
                          width="100%"
                          sx={{
                            borderRadius: "12px",
                            background: "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                            backgroundSize: "200% 100%",
                          }}
                        />

                        {/* Controls and Buttons Skeletons */}
                        <div className="absolute top-2 left-2 right-2 flex justify-between items-center">
                          <div className="flex gap-1">
                            {/* Skeleton for the Upscale Button */}
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={40}
                              sx={{
                                borderRadius: "9999px",
                                background: "black",
                              }}
                            />

                            {/* Skeleton for the Delete Button */}
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                              sx={{
                                background: "black",
                              }}
                            />
                          </div>

                          {/* Skeleton for the Download Button */}
                          <Skeleton
                            variant="circular"
                            width={40}
                            height={40}
                            sx={{
                              background: "black",
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {isLoading && (
                      <div className="absolute inset-0 flex justify-center items-center bg-[#2a2a2a] rounded-2xl">
                        <CircularWithValueLabel isLoadingAIResponse={isLoading} loadingText="loading" percentageShow={true} />
                      </div>
                    )}
                    {/* Image Component */}
                    <img
                      loading="lazy"
                      className={`h-full w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out ${hover === `${index}-${imgIndex}` && hasLoaded ? 'transform scale-105 shadow-lg' : ''}`}
                      src={`${imageUrl}?auto=format&fit=crop`}
                      alt={`gallery-photo-${imgIndex}`}
                      style={{ filter: isLoading ? "blur(8px)" : "none" }}
                      onLoad={handleImageLoad}
                      onClick={() => navigate(`/dashboard/generatedImage/${item.generation_id}?imgIndex=${imgIndex}`)}
                    />
                  </div>
                  {hover === `${index}-${imgIndex}` && (
                    <>
                      {upscalecurrenttag === imgIndex || item.upscale[imgIndex] ? (
                        <div className="absolute -top-1 left-0 right-0">
                          <div className="flex items-center justify-center h-14 px-2 py-1 bg-black bg-opacity-70 text-white rounded-t-2xl text-xs font-semibold  transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300">
                            <i className="fa-solid text-yellow-500 fa-star"></i>
                            <p className="ml-1">UPSCALED</p>
                          </div>
                          <div className="absolute top-2 right-0 left-0 flex justify-between items-center px-4 ">
                            <Tooltip title="Delete" slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                  {
                                    margin: '0px',
                                  },
                                }
                              }
                            }}>
                              <button onClick={() => deleteImage(imageUrl, item.generation_id, imgIndex)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                                <i className="fa-solid fa-trash-can text-white"></i>
                              </button>
                            </Tooltip>
                            <Tooltip title="Download" slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                  {
                                    margin: '0px',
                                  },
                                }
                              }
                            }}>
                              <button onClick={() => downloadImage(imageUrl)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                                {/* <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M12 16L12 8" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M3 15L3 16L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 16L21 15" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> </g></svg> */}
                                <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                              </button>

                            </Tooltip>
                          </div>
                        </div>
                      ) : (
                        <div className="absolute top-2 left-2 right-2 flex justify-between items-center">

                          <div className="flex gap-1">
                            <button
                              className="flex items-center space-x-2 px-4 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white rounded-full text-xs font-semibold hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300"
                              onClick={() => upscale(item.generation_id, imageUrl, imgIndex, index)}
                            >
                              <span>Upscale</span>
                            </button>
                            <Tooltip title="Delete" slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                  {
                                    margin: '0px',
                                  },
                                }
                              }
                            }}>
                              <button onClick={() => deleteImage(imageUrl, item.generation_id, imgIndex)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                                <i className="fa-solid fa-trash-can text-white"></i>
                              </button>
                            </Tooltip>
                          </div>
                          <Tooltip title="Download" slotProps={{
                            popper: {
                              sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                {
                                  margin: '0px',
                                },
                              }
                            }
                          }}>
                            <button onClick={() => downloadImage(imageUrl)} className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500">
                              <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                              {/* <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth={0} /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"> <path d="M12 16L12 8" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> <path d="M3 15L3 16L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 16L21 15" stroke="#ff8a8a" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /> </g></svg> */}
                            </button>
                          </Tooltip>
                        </div>

                      )}
                      <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-70 opacity-100 transition-opacity duration-300 p-4 text-white border-1 border-gray-400 rounded-[10px]">
                        <div className="flex justify-between w-full gap-2">
                          <Tooltip title={item.generation_prompt} placement="top">
                            <p className="text-sm sm:text-sm md:text-md">
                              {item.generation_prompt.slice(0, 50)}...
                            </p>
                          </Tooltip>

                          {/* <h2 className="text-red-800 text-xl text-wrap">
                          @{item.user_name}
                        </h2> */}

                          {item.Aititle ? <div className="flex flex-col items-start">
                            <h2 className="text-xs sm:text-sm  text-red-400">MODEL</h2>
                            <p className="text-white text-sm sm:text-base ">
                              {item.Aititle}
                            </p>
                          </div>
                            : null}
                          {item.extensions ? <div className="flex flex-col items-start">
                            <h2 className="text-xs sm:text-sm  text-red-400">EXTENSIONS</h2>
                            <h2 className="text-white text-sm sm:text-base ">
                              {item.extensions}
                            </h2>
                          </div> : null}


                        </div>
                      </div>
                    </>
                  )}
                </div>

              );
            });
          })}
        </Masonry>

        {imageDataLen === 0 ?
          <>
            <div className="flex-wrap lg:flex items-center justify-between p-10 rounded-lg shadow-lg bg-[#d5d5d5]">
              <div className="flex-wrap lg:flex gap-4">
                <div className="logo flex items-center justify-center">
                  <i className="fa-solid fa-heart-circle-plus text-red-600 text-4xl" />
                </div>
                <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                  <h2 className="text-gray-900 text-2xl font-semibold">
                    Create Your Personalized Characters
                  </h2>
                  <p className="text-gray-800 text-sm">
                    Explore the power of AI to design unique images and characters
                    tailored to your preferences.
                  </p>
                </div>
              </div>
              <div className="text-center lg:text-right">
                <button onClick={() => navigate("/ai-porn-image-generator")} className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                  Start Creating
                </button>
              </div>
            </div>
          </>
          :
          <>
            {showMoreBtn && pageLinks.next && (
              <div className="flex items-center w-full justify-center mt-10">
                <button
                  className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
                  onClick={() => fetchImageData(pageLinks.next)}
                >
                  <i className="fa-solid fa-angles-down mr-1"></i> Load More
                </button>
              </div>
            )}
          </>
        }

      </div>

      {errorisOpen && (
        <UpgradeNow errorCloseModal={errorCloseModal} />
      )}

      {/* Modal (fullscreen image view) */}
      {isModalOpen && selectedImage && (
        <div
          ref={modalRef}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
        >
          <div className="relative w-[35rem] max-h-full p-4">
            <button
              onClick={closeModal}
              className="absolute top-8 right-8 text-white text-md w-8 h-8 bg-[#808080] p-[5px] font-bold rounded-full"
            >
              X
            </button>
            <img loading='lazy'
              className="max-w-full max-h-screen object-contain"
              src={selectedImage}
              alt="Fullscreen View"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GenerationImagesBox;
