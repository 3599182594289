const BaseApiUrl = process.env.REACT_APP_baseApiUrl;
const AppDomain = process.env.REACT_APP_appDomain;

const GoogleClientId = process.env.REACT_APP_googleClientId;
const GoogleRedirectUrl = process.env.REACT_APP_googleRedirectUrl;

const PlanBronzeLink = process.env.REACT_APP_planBronzeLink;
const PlanSilverLink = process.env.REACT_APP_planSilverLink;
const PlanGoldLink = process.env.REACT_APP_planGoldLink;
const PlanTrialLink = process.env.REACT_APP_planTrialLink

const AddOn8Link = process.env.REACT_APP_addOn8;
const AddOn29Link = process.env.REACT_APP_addOn29;
const AddOn44Link = process.env.REACT_APP_addOn44;
const AddOn89Link = process.env.REACT_APP_addOn89;
const AddOn179Link = process.env.REACT_APP_addOn179;
const AddOn269Link = process.env.REACT_APP_addOn269;

export {
    BaseApiUrl, AppDomain, GoogleClientId, GoogleRedirectUrl, PlanBronzeLink, PlanSilverLink, PlanGoldLink, 
    AddOn8Link, AddOn29Link, AddOn44Link, AddOn89Link, AddOn179Link, AddOn269Link, PlanTrialLink
};