import React, { useState, useEffect, cloneElement } from "react";
// import { Helmet } from "react-helmet";
import UserApiClient from "../../utilities/apiClients";
import PageWrapper from "../../utilities/Metatags";

function Seodata({ children }) {
  const [metaData, setMetaData] = useState({
    title: "Eromantic.AI Chat & Customize Virtual Girlfriend",
    description: "",
    keywords: "",
  });

  // Function to fetch SEO data based on the current page's path
  const fetchSeoData = () => {
    const path = window.location.pathname.replaceAll("/", "");
    UserApiClient.get(
      `api/v1/admin/meta-content/${path}/`,
      {},
      false,
      true,
      false
    ).then(async (res) => {
      if (res.success) {
        setMetaData(res.data);
      }
    });
  };

  useEffect(() => {
    fetchSeoData();
  }, [window.location.pathname]); // Refetch SEO data on route change

  return (
    <>
      <PageWrapper
        title={metaData.title}
        description={metaData.description}
        keywords={metaData.keywords}
      />

      {/* Render child component(s) passed to this wrapper */}
      <div>{React.Children.map(children, (child) => cloneElement(child))}</div>
    </>
  );
}

export default Seodata;
