import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import UserApiClient from "../../utilities/apiClients";
import { BaseApiUrl } from "../../utilities/enviroment";
import DiscoverImagesBox from "./DiscoverImagesBox";
import ScrolltoTop from "../../frontend/components/ScrolltoTop";
import CircularWithValueLabel from "../dashboardPages/CreateDreamGF/CircularWithValueLabel";
import { hd } from "../../assets";
import DiscoverVideoBox from "./DiscoverVideoBox";
import VideoPlayer from "./VideoPlayer";
import { toast, ToastContainer } from "react-toastify";

const ViewGeneratedVideos = () => {
  const query = new URLSearchParams(useLocation().search);
  const { id } = useParams();
  console.log(id);
  const imgIndex = query.get("imgIndex");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [generatedVideo, setGeneratedVideo] = useState("");
  const [generatedUser, setGeneratedUser] = useState("");
  const [likesStatus, setLikesStatus] = useState(false);

  const BASE_URL = BaseApiUrl;

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}api/video/hunyuan/${id}/`);
        console.log(response.data);
        setGeneratedVideo(response.data);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 10);
      } catch (error) {
        Swal.fire(
          error.response?.data?.msg || "Our Servers are busy!",
          "Please wait a moment and try again.",
          "warning"
        );
      }
    };
    fetchVideoData();
  }, [BASE_URL, id, imgIndex]);

  const toggleLike = async (generation_id, generatedImage) => {
    const u_id = localStorage.getItem("u_id");
    const payload = { generation_id, generatedImage, u_id };

    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}api/v1/public/image-like/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        setLikesStatus(response.data.message);
      }
    } catch (error) {
      console.error("Error liking image:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await UserApiClient.delete(
        `api/video/hunyuan/${generatedVideo.prompt_id}/`,
        {},
        true
      );
      console.log(response);
      if (response.success) {
        Swal.fire("Success!", "Video deleted successfully.", "success").then(
          () => {
            navigate("/dashboard/my-gallery?view=Videos&status=completed"); // Redirect to dashboard
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadVideo = async () => {
    try {
      const response = await fetch(generatedVideo.url);
      if (!response.ok) {
        throw new Error("Failed to fetch the video");
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `generated_video_${Date.now()}.mp4`;

      // Perform the download action
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);

      // Show success toast only after download is triggered
      toast.success("Downloading...", {
        position: "top-left",
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        autoClose: 2000,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
    } catch (error) {
      console.error("Failed to download the video:", error);
    }
  };

  return (
    <>
      <ScrolltoTop />
      <ToastContainer/>
      <div className="flex items-center justify-center px-4 md:px-20">
        <div className="max-w-7xl w-full bg-[#cfcfcf40] rounded-xl shadow-2xl  p-6">
          <div className="grid md:grid-cols-2 gap-5">
            {/* Image Section */}
            <div className="relative rounded-xl flex justify-center items-center">
              <VideoPlayer
                videoUrl={generatedVideo.url}
                className="w-full max-h-[70vh] rounded-xl object-contain transition-all duration-300"
              />
              {/* {isImageLoading && (
                  <div className="absolute inset-0 flex items-center justify-center z-10">
                    <CircularWithValueLabel
                      isLoadingAIResponse={isImageLoading}
                      loadingText="loading"
                      percentageShow={true}
                    />
                  </div>
                )} */}
              {/* <video
                  src={generated}
                  loading="lazy"
                  alt="Generated Artwork"
                  className={`w-full max-h-[70vh] rounded-xl object-contain transition-all duration-300 ${
                    loading ? "blur-md opacity-50" : ""
                  }`}
                /> */}
            </div>

            {/* Details Section */}
            <div className="flex flex-col gap-6">
              <p className="text-[#99000B] font-semibold text-base md:text-lg xl:text-xl">
                By @{generatedVideo?.user || "Unknown"}
              </p>

              <div className="flex flex-wrap gap-3">
                {likesStatus === "Like added" ? (
                  <button
                    className="px-4 py-2 rounded-lg shadow-md bg-red-500 text-white"
                    // onClick={() =>
                    //   toggleLike(generatedUser.generation_id, generatedImage)
                    // }
                  >
                    <i className="fas fa-heart"></i> Liked
                  </button>
                ) : (
                  <button
                    className="px-4 py-2 rounded-lg shadow-md bg-gray-200 text-gray-600"
                    // onClick={() =>
                    //   toggleLike(generatedUser.generation_id, generatedImage)
                    // }
                  >
                    <i className="far fa-heart"></i> Like
                  </button>
                )}

                {generatedUser?.upscale &&
                Array.isArray(generatedUser.upscale) &&
                imgIndex < generatedUser.upscale.length ? (
                  generatedUser.upscale[imgIndex] === true ? (
                    <button
                      className="px-4 py-2 rounded-lg shadow-md bg-indigo-500 text-white hover:bg-indigo-600 flex"
                      disabled
                    >
                      <img src={hd} alt="hd" className="w-5 mr-1 invert" />{" "}
                      Upscaled
                    </button>
                  ) : (
                    <button
                      className="px-4 py-2 rounded-lg shadow-md bg-indigo-500 text-white hover:bg-indigo-600 flex"
                      // onClick={() =>
                      //   upscaleImage(
                      //     generatedUser.generation_id,
                      //     generatedImage
                      //   )
                      // }
                      // disabled={isImageLoading}
                    >
                      <img src={hd} alt="hd" className="w-5 mr-1 invert" />{" "}
                      Upscale
                    </button>
                  )
                ) : null}

                <button
                  className="px-4 py-2 rounded-lg shadow-md bg-gray-800 text-white hover:bg-gray-700"
                  onClick={downloadVideo}
                >
                  <i class="fa-solid fa-file-arrow-down"></i> Download
                </button>
                <button
                  className="px-4 py-2 rounded-lg shadow-md bg-red-600 text-white hover:bg-red-700"
                  onClick={handleDelete}
                >
                  <i className="fas fa-trash"></i> Delete
                </button>
                <button
                  className="px-4 py-2 rounded-lg shadow-md bg-green-600 text-white hover:bg-green-700"
                  // onClick={genVideo}
                >
                  <i class="fa-solid fa-video"></i> Video
                </button>
              </div>

              <div
                className="flex flex-col gap-4 overflow-y-auto h-[500px]"
                style={{ scrollBehavior: "smooth", scrollbarWidth: "none" }}
              >
                <div>
                  <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">
                    PROMPT:
                  </p>
                  <p className="text-base md:text-lg xl:text-xl capitalize">
                    {generatedVideo?.prompt || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">
                    CREATED AT:
                  </p>
                  <p className="text-base md:text-lg xl:text-xl capitalize">
                    {generatedVideo?.created_at?.split("T")[0] ||
                      "Date not available"}
                  </p>
                </div>
                <div>
                  <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">
                    EXTENSIONS:
                  </p>
                  <p className="text-base md:text-lg xl:text-xl capitalize">
                    {generatedVideo.extensions.length > 0
                      ? generatedVideo?.extensions
                      : "N/A"}
                  </p>
                </div>
                <div>
                  <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">
                    Environment
                  </p>
                  <p className="text-base md:text-lg xl:text-xl capitalize">
                    {generatedVideo.environment
                      ? generatedVideo.environment
                      : "N/A"}
                  </p>
                </div>
                <div>
                  <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">
                    Hair Color
                  </p>
                  <p className="text-base md:text-lg xl:text-xl capitalize">
                    {generatedVideo.hair_color
                      ? generatedVideo.hair_color
                      : "N/A"}
                  </p>
                </div>
                <div>
                  <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">
                    Hair Cut
                  </p>
                  <p className="text-base md:text-lg xl:text-xl capitalize">
                    {generatedVideo.hair_cut ? generatedVideo.hair_cut : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-40">
        <DiscoverVideoBox />
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default ViewGeneratedVideos;
