import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  Bodyicon,
  Bodyback,
  Relationshipicon,
  Location,
  age,
  alert,
  brest,
  country,
  eyes,
  hair,
  occupation,
  manbody,
  personality,
} from "../../assets";
import Characters from "./LandingPage/Characters";
import Accordion from "./LandingPage/Accordion";
import Blogs from "./LandingPage/blogs";
import Footer from "../components/Footer";
import LandingPopup from "./LandingPopup";
import { useSelector } from "react-redux";
import { useRef } from "react";
import UserApiClient from "../../utilities/apiClients";
import { BaseApiUrl } from "../../utilities/enviroment";
import { Tooltip } from "@mui/material";
import PageWrapper from "../../utilities/Metatags";
import UpgradeNow from "../../dashboard/components/UpgradeNow";
import Skelton from "../components/Skeleton";

const LandingPage = ({ activeTab }) => {
  // Static Data
  const [realisticGirlData, SetrealisticGirlData] = useState([]);
  const [animeGirlData, SetanimeGirlData] = useState([]);
  const [realisticBoyData, SetrealisticBoyData] = useState([]);
  const [animeBoyData, SetanimeBoyData] = useState([]);
  const [imgdata, setImgdata] = useState([]);
  const [imgdataanime, setImgdataanime] = useState([]);
  const [imgboydata, setImgboydata] = useState([]);
  const [imgboydataani, setImgboydataani] = useState([]);

  // Others
  // const [selected, setSelected] = useState("Saved");
  const [Userlogger, setUserlogger] = useState(null);
  const [showPopup, setShowPopup] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modaldata, setModaldata] = useState("");
  const [modalImg, setModalImg] = useState("");
  const [liked, setLiked] = useState(false);
  const [activeTabani, setActiveTabani] = useState(1);
  const [LikesCount, setLikesCount] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [likesStatus, setLikesStatus] = useState("");
  const [SeoContent, setSeoContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const [bannerList, setBannerList] = useState({
    boys: [],
    girls: [],
  });
  const timeRef = useRef(1800000); // 30 minutes in milliseconds

  // let LikesCount=0;
  const BASE_URL = BaseApiUrl + "assistant_api/";

  const openModal = async (assistant_id, image) => {
    if (!assistant_id) {
      console.error("assistant_id is required");
      return;
    }
    if (image) {
      setModalImg(image);
    }

    await UserApiClient.get(
      `assistant_api/fetch_static_data/?assistant_id=${assistant_id}`,
      {},
      false
    ).then(async (res) => {
      if (res.success) {
        setModaldata(res.data);
        setLikesCount(res.data.likes);
        setLikesStatus(res.data.like_state);
        setIsModalOpen(true);
      }
    });
  };

  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);


  useEffect(() => {
    fetchData();
    fetchSeoContent();
    const token = localStorage.getItem("token");
    setUserlogger(token);
    loadStaticData();
  }, []);
  useEffect(() => {
    if (imgdata && imgdata.length > 0) {
      let totalImages = imgdata.length * 2; // Since each item has two images (img_url and img_url2)
      let loaded = 0;

      imgdata.forEach((item) => {
        const img1 = new Image();
        const img2 = new Image();

        img1.src = item.character.img_url;
        img2.src = item.character.img_url2;

        img1.onload = img2.onload = () => {
          loaded++;
          setImagesLoaded((prev) => prev + 1);
          if (loaded >= totalImages) setLoading(false);
        };
      });
    }
    else if (imgdataanime && imgdataanime.length > 0) {
      let totalImages = imgdataanime.length * 2; // Since each item has two images (img_url and img_url2)
      let loaded = 0;

      imgdataanime.forEach((item) => {
        const img1 = new Image();
        const img2 = new Image();

        img1.src = item.character.img_url;
        img2.src = item.character.img_url2;

        img1.onload = img2.onload = () => {
          loaded++;
          setImagesLoaded((prev) => prev + 1);
          if (loaded >= totalImages) setLoading(false);
        };
      });
    }
    else if (imgboydata && imgboydata.length > 0) {
      let totalImages = imgboydata.length * 2; // Since each item has two images (img_url and img_url2)
      let loaded = 0;

      imgboydata.forEach((item) => {
        const img1 = new Image();
        const img2 = new Image();

        img1.src = item.character.img_url;
        img2.src = item.character.img_url2;

        img1.onload = img2.onload = () => {
          loaded++;
          setImagesLoaded((prev) => prev + 1);
          if (loaded >= totalImages) setLoading(false);
        };
      });
    }
    else if (imgboydataani && imgboydataani.length > 0) {
      let totalImages = imgboydataani.length * 2; // Since each item has two images (img_url and img_url2)
      let loaded = 0;

      imgboydataani.forEach((item) => {
        const img1 = new Image();
        const img2 = new Image();

        img1.src = item.character.img_url;
        img2.src = item.character.img_url2;

        img1.onload = img2.onload = () => {
          loaded++;
          setImagesLoaded((prev) => prev + 1);
          if (loaded >= totalImages) setLoading(false);
        };
      });
    }
  }, [imgdata, imgdataanime, imgboydata, imgboydataani]);

  const loadMoreRecards = async (state) => {
    if (state === "Girlfriend_1") {
      setImgdata(realisticGirlData?.slice(0, imgdata.length + 10));
    }
    if (state === "Girlfriend_2") {
      setImgdataanime(animeGirlData?.slice(0, imgdataanime.length + 10));
    }
    if (state === "Boyfriend_1") {
      setImgboydata(realisticBoyData?.slice(0, imgboydata.length + 10));
    }
    if (state === "Boyfriend_2") {
      setImgboydataani(animeBoyData?.slice(0, imgboydataani.length + 10));
    }
    // loadStaticData(realisticGirlData.length + 10)
  };
  const loadStaticData = async () => {
    await UserApiClient.get(`assistant_api/all_static_data/`, {}, false).then(
      async (res) => {
        if (res.success) {
          // Fetch data and slice it to get the first 10 items
          SetrealisticGirlData(res.data?.static_data?.RealisticGirl || []);
          SetanimeGirlData(res.data?.static_data?.AnimeGirl || []);
          SetrealisticBoyData(res.data?.static_data?.RealisticBoy || []);
          SetanimeBoyData(res.data?.static_data?.AnimeBoy || []);
        }
      }
    );
  };

  useEffect(() => {
    setImgdata(realisticGirlData?.slice(0, user.isAuthenticated ? 10 : 9));
    setImgdataanime(animeGirlData?.slice(0, user.isAuthenticated ? 10 : 9));
    setImgboydata(realisticBoyData?.slice(0, user.isAuthenticated ? 10 : 9));
    setImgboydataani(animeBoyData?.slice(0, user.isAuthenticated ? 10 : 9));
  }, [realisticGirlData, animeGirlData, realisticBoyData, animeBoyData]);

  const CreateDreamGF = () => {
    const token = localStorage.getItem("token");
    navigate(token ? "/ai-girlfriend-generator" : "/login");
  };

  const [errorisOpen, setErrorIsOpen] = useState(false);

  const errorCloseModal = () => {
    setErrorIsOpen(false);
    navigate('/');
  };
  const Chatnow = async (id) => {
    if (user.isAuthenticated) {
      if (localStorage.getItem("subscription_type") !== "free") {
        await UserApiClient.post(
          "assistant_api/static_chat/",
          { assistant_id: id, u_id: user.user.id },
          true
        ).then(async (res) => {
          if (res.success) {
            navigate(`/aichat/${id}`);
          }
        });
      }
      else {
        setErrorIsOpen(true);

      }
    } else {
      navigate("/login");
    }
  };

  const CreateDreamBf = () => {
    const token = localStorage.getItem("token");
    navigate(token ? "/ai-boyfriend-generator" : "/login");
  };
  const handleClosePopup = () => {
    // window.location.reload();
    setShowPopup(false);
  };

  const toggleLike = async (assistant_id) => {
    const u_id = localStorage.getItem("u_id");
    const payload = { assistant_id: assistant_id, u_id: u_id };

    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}like_static/`, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        setLiked(!liked);

        setLikesCount(response.data.likes);
        setLikesStatus(response.data.like_state);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleTabClick = (tabIndex) => {
    setActiveTabani(tabIndex);
  };
  const handleTabClickboy = (tabIndex) => {
    setActiveTabani(tabIndex);
  };

  const itemsToDisplay =
    (modaldata.Butt_Size === "" || modaldata.Butt_Size === "NA") &&
      (modaldata.Breast_Size === "" || modaldata.Breast_Size === "NA")
      ? [
        {
          label: "Relationship:",
          value: modaldata.Relationship,
          src: Relationshipicon,
        },
        { label: "Age:", value: modaldata.age, src: age },
        {
          label: "Personality:",
          value: modaldata.Personality,
          src: personality,
        },
        {
          label: "Occupation:",
          value: modaldata.Occupation,
          src: occupation,
        },
        {
          label: "Hair Style and Color:",
          value: `${modaldata.hair_Style} ${modaldata.hair_color}`,
          src: hair,
        },
        { label: "Eyes:", value: modaldata.Eyes, src: eyes },
        { label: "Ethnicity:", value: modaldata.Ethnicity, src: Location },
        { label: "Region:", value: modaldata.region, src: country },
        { label: "Body Type:", value: modaldata.Body_Type, src: manbody },
      ]
      : [
        {
          label: "Relationship:",
          value: modaldata.Relationship,
          src: Relationshipicon,
        },
        { label: "Age:", value: modaldata.age, src: age },
        {
          label: "Personality:",
          value: modaldata.Personality,
          src: personality,
        },
        {
          label: "Occupation:",
          value: modaldata.Occupation,
          src: occupation,
        },
        {
          label: "Hair Style and Color:",
          value: `${modaldata.hair_Style} ${modaldata.hair_color}`,
          src: hair,
        },
        { label: "Eyes:", value: modaldata.Eyes, src: eyes },
        { label: "Ethnicity:", value: modaldata.Ethnicity, src: Location },
        { label: "Region:", value: modaldata.region, src: country },
        { label: "Body Type:", value: modaldata.Body_Type, src: Bodyicon },
        { label: "Breast Size:", value: modaldata.Breast_Size, src: brest },
        { label: "Butt Size:", value: modaldata.Butt_Size, src: Bodyback },
      ];

  let timerInterval;
  useEffect(() => {
    timerInterval = setInterval(() => {
      if (timeRef.current <= 0) {
        clearInterval(timerInterval); // Stop the timer when it reaches 0
        return;
      }

      // Update timeRef without triggering a re-render
      timeRef.current -= 5;

      // Format the time and update the state
      setFormattedTime(formatTime(timeRef.current));
    }, 1); // Update every millisecond

    // Clean up the interval when the component is unmounted or the timer ends
    return () => clearInterval(timerInterval);
  }, []);

  // Function to format the time in mm:ss:ms format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000); // Extract minutes
    const seconds = Math.floor((time % 60000) / 1000); // Extract seconds
    const milliseconds = Math.floor((time % 1000) / 10); // Extract milliseconds

    return `${String(minutes).padStart(2, "0")} : ${String(seconds).padStart(
      2,
      "0"
    )} : ${String(milliseconds).padStart(2, "0")}`;
  };

  const [formattedTime, setFormattedTime] = useState(
    formatTime(timeRef.current)
  );

  const fetchData = () => {
    UserApiClient.get("api/banner/setting/", {}, false).then(async (res) => {
      if (res.success) {
        setBannerList({
          boys: res.data.data.boys_banners || [],
          girls: res.data.data.girls_banners || [],
        });
      }
    });
  };

  const fetchSeoContent = () => {
    UserApiClient.get("api/v1/admin/sco-content/", {}, false).then(
      async (res) => {
        if (res.success) {
          setSeoContent(res.data);
        }
      }
    );
  };

  return (
    <>
      <PageWrapper title={"Chat & Customize Virtual Girlfriend"} description={"Create and interact with your virtual AI girlfriend or boyfriend. Experience meaningful conversations and deep connections with Eromantic AI, anytime, anywhere"} keywords={"AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"}>

        <div className="max-h-full">
          <div className="px-2 xl:px-10 pb-5 text-white font-josefin-sans">
            {Userlogger
              ? ""
              : isVisible && (
                <div className="bg-gradient-to-r from-red-600 h-20 px-10 to-white w-full flex  justify-between items-center relative rounded-xl mb-2">
                  {/* Close button */}
                  <button
                    onClick={() => {
                      setIsVisible(false);
                      clearInterval(timerInterval); // Stop the timer when it reaches 0
                    }}
                    className="absolute top-2 right-2 text-black text-2xl"
                  >
                    ×
                  </button>

                  <div className="text-xl lg:text-2xl">First Subscription</div>
                  <div className="border-2 px-4 border-[#99000B] rounded-full p-1 md:p-2 transition-colors duration-300 bg-[#99000B] text-white">
                    Up to 70% OFF
                  </div>
                  <div className="border-2 w-[130px] px-5 border-[#807D7D] rounded-full p-2 transition-colors duration-300 bg-[#807D7D] text-white">
                    {/* {formatTime(timeLeft)} */}
                    {formattedTime}
                    <div className="flex justify-between">
                      <p className="text-xs ">Min</p>
                      <p className="text-xs ">Sec</p>
                      <p className="text-xs  ">Ms</p>
                    </div>
                  </div>
                </div>
              )}

            {activeTab === "Girlfriend" ? (
              <>
                {bannerList.girls.length > 0 ? (
                  <Carousel
                    autoPlay
                    interval={3000}
                    infiniteLoop
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    transitionTime={500}
                    className="rounded-xl overflow-hidden cursor-pointer "
                  >
                    {bannerList.girls.map((banner, index) => (
                      <div key={index} onClick={() => navigate(`${banner.name}`)}>
                        <img height="" width="" src={banner.url} alt={`Girls Banner ${index + 1}`} />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  null
                )}

                <div className="text-2xl lg:text-3xl lg:my-4 mt-0 font-bold lg:py-4 py-0 text-black text-center uppercase">
                  Meet Our Best{" "}
                  <span className="text-[#991b1b]">AI GirlFriends</span>
                </div>

                <div className="flex justify-center space-x-4">
                  <button
                    onClick={() => handleTabClick(1)}
                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tl-lg rounded-bl-lg transition-colors duration-300 ${activeTabani === 1
                      ? "bg-[#991b1b] text-white"
                      : "bg-gray-200 text-gray-600"
                      }`}
                  >
                    Realistic
                  </button>
                  <button
                    onClick={() => handleTabClick(2)}
                    style={{ margin: "0" }}
                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tr-lg rounded-br-lg transition-colors duration-300 ${activeTabani === 2
                      ? "bg-[#991b1b] text-white"
                      : "bg-gray-200 text-gray-600"
                      }`}
                  >
                    Anime
                  </button>
                </div>

                <div className="mt-4">
                  {activeTabani === 1 && (
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                      {loading
                        ? Array.from({ length: imgdata?.length || 10 }).map((_, index) => (
                          <Skelton key={index} />
                        ))
                        : imgdata.map((item, index) => (
                          <div
                            key={index}
                            className={`relative ${index === -1 ? "rounded-md border-2 border-red-600 shadow-md shadow-red-800 " : ""}`}
                            onMouseEnter={() => {
                              setHoveredIndex(index);
                              if (item.character.vid_url) {
                                const videoElement = document.getElementById(`hover-video-${index}`);
                                if (videoElement) {
                                  videoElement.load();
                                  videoElement.play().catch(e => console.log("Video play error:", e));
                                  videoElement.oncanplaythrough = () => {
                                    videoElement.play().catch(e => console.log("Video play error:", e));
                                  };
                                }
                              }
                            }}

                            onMouseLeave={() => setHoveredIndex(null)}
                            onTouchEnd={() => setHoveredIndex(null)}
                            onTouchStart={() => {
                              setHoveredIndex(index);
                              if (item.character.vid_url) {
                                const videoElement = document.getElementById(`hover-video-${index}`);
                                if (videoElement) {
                                  videoElement.load();
                                  videoElement.play().catch(e => console.log("Video play error:", e));
                                  videoElement.oncanplaythrough = () => {
                                    videoElement.play().catch(e => console.log("Video play error:", e));
                                  };
                                }
                              }
                            }}
                          >
                            <div
                              className="overflow-hidden rounded-xl"
                              onClick={() => openModal(item.assistant, item.character.img_url)}
                            >
                              <img
                                loading="lazy"
                                src={!item.character.vid_url && hoveredIndex === index ? item.character.img_url2 : item.character.img_url}
                                alt={item.character.name ? item.character.name.charAt(0).toUpperCase() + item.character.name.slice(1).toLowerCase() : ""}
                                className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-all duration-500 ease-in-out ${hoveredIndex === index && !item.character.vid_url ? "scale-105 rounded-xl" : ""
                                  }`}
                                style={{
                                  opacity: item.character.vid_url && hoveredIndex === index ? 0 : 1, // Hide when video plays
                                }}
                              />
                              {/* Video element - only rendered if item has video URL */}
                              {item.character.vid_url && (
                                <video
                                  id={`hover-video-${index}`}
                                  src={item.character.vid_url}
                                  alt={`Video of ${item.character.name}`}
                                  className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-500 ease-in-out`}
                                  autoPlay={hoveredIndex === index}
                                  loop
                                  muted
                                  preload="auto"
                                  playsInline
                                  style={{
                                    opacity: hoveredIndex === index ? 1 : 0,
                                    transition: 'opacity 500ms ease-in-out',
                                    position: 'absolute',
                                    top: 0,
                                    zIndex: 0
                                  }}
                                />
                              )}
                            </div>
                            <div
                              id="char-info-GR"
                              className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                              onClick={(e) => {
                                if (e.target.id === "char-info-GR") openModal(item.assistant, item.character.img_url);
                              }}
                            >
                              <div className="grid justify-end pt-4 rounded-lg">
                                <Tooltip title={"Chat Now"} className="grid" placement="left">
                                  <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                    <img height="" width=""
                                      loading="lazy"
                                      src="/assets/img/Group-icon.png"
                                      onClick={() => Chatnow(item.assistant)}
                                      alt="Icon"
                                      className="w-10 h-10"
                                    />
                                  </button>
                                </Tooltip>
                                <Tooltip title={"Image Create"} className="grid" placement="left">
                                  <button onClick={() => navigate(`/dashboard/generate/${item.assistant}`)}
                                    className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                  >
                                    <img height="" width=""
                                      loading="lazy"
                                      src="/assets/img/Group-icon-msg.png"
                                      alt="Icon"
                                      className="w-10 h-10"
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                <div className="flex justify-between flex-wrap py-1">
                                  <span className="text-lg lg:text-lg font-medium">
                                    {item.character.name ? item.character.name.charAt(0).toUpperCase() + item.character.name.slice(1).toLowerCase() : ""}
                                  </span>
                                  <span className="text-lg lg:text-lg font-medium">
                                    {item.character.age}
                                  </span>
                                </div>
                                <Tooltip title={item.character.summary} placement="top">
                                  <p className="text-[14px] lg:text-base truncate">
                                    {item.character.summary}
                                  </p>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        ))}

                      {Userlogger ? (
                        <></>
                      ) : (
                        <div className="relative bg-[#990013] rounded-xl">
                          <div className=" inset-0 flex flex-col duration-700 py-5 px-3 sm:p-5">
                            <div className="flex justify-center p-0 sm:p-4">
                              <i className="fa-solid fa-gift fa-4x"></i>
                            </div>
                            <div className="bg-opacity-70 my-3 sm:my-10 text-white p-2 rounded-b-lg">
                              <div className="flex flex-col gap-4 sm:gap-10 text-center py-1">
                                <span className="text-lg sm:text-xl lg:text-2lg xl:text-3xl font-medium">
                                  Register To View More
                                </span>
                                <span className="text-lg sm:text-xl lg:text-2lg xl:text-3xl font-medium">
                                  It's FREE To Join
                                </span>
                              </div>
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg">
                              <button
                                className="bg-white font-semibold [w-75%] text-[#990013] rounded-2xl p-4 "
                                onClick={CreateDreamBf}
                              >
                                Register To Join
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {activeTabani === 2 && (
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                      {loading
                        ? Array.from({ length: imgdataanime?.length || 10 }).map((_, index) => (<Skelton key={index} />))
                        : imgdataanime.map((item, index) => (
                          <div
                            key={index}
                            className={`relative ${index === -1 ? "rounded-md border-2 border-red-600 shadow-md shadow-red-800 " : ""}`}
                            onMouseEnter={() => {
                              setHoveredIndex(index)
                              if (item.character.vid_url) {
                                const videoElement = document.getElementById(`hover-video-${index}`);
                                if (videoElement) {
                                  videoElement.load();
                                  videoElement.play().catch(e => console.log("Video play error:", e));
                                }
                              }
                            }}
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <div
                              className="overflow-hidden rounded-xl"
                              onClick={() => openModal(item.assistant, item.character.img_url)}
                            >
                              <img
                                loading="lazy"
                                src={!item.character.vid_url && hoveredIndex === index ? item.character.img_url2 : item.character.img_url}
                                alt={item.character.name ? item.character.name.charAt(0).toUpperCase() + item.character.name.slice(1).toLowerCase() : ""}
                                className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-all duration-1000 ease-in-out ${hoveredIndex === index && !item.character.vid_url ? "scale-105 rounded-xl" : ""
                                  }`}
                                style={{
                                  opacity: item.character.vid_url && hoveredIndex === index ? 0 : 1, // Hide when video plays
                                }}
                              />
                              {/* Video element - only rendered if item has video URL */}
                              {item.character.vid_url && (
                                <video
                                  id={`hover-video-${index}`}
                                  src={item.character.vid_url}
                                  alt={`Video of ${item.character.name}`}
                                  className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out`}
                                  autoPlay={hoveredIndex === index}
                                  loop
                                  muted
                                  playsInline
                                  style={{
                                    opacity: hoveredIndex === index ? 1 : 0,
                                    transition: 'opacity 1000ms ease-in-out',
                                    position: 'absolute',
                                    top: 0,
                                    zIndex: 0
                                  }}
                                />
                              )}
                            </div>
                            <div
                              id="char-info-GR"
                              className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                              onClick={(e) => {
                                if (e.target.id === "char-info-GR") openModal(item.assistant, item.character.img_url);
                              }}
                            >
                              <div className="grid justify-end pt-4 rounded-lg">
                                <Tooltip title={"Chat Now"} className="grid" placement="left">
                                  <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                    <img height="" width=""
                                      loading="lazy"
                                      src="/assets/img/Group-icon.png"
                                      onClick={() => Chatnow(item.assistant)}
                                      alt="Icon"
                                      className="w-10 h-10"
                                    />
                                  </button>
                                </Tooltip>
                                <Tooltip title={"Image Create"} className="grid" placement="left">
                                  <button onClick={() => navigate(`/dashboard/generate/${item.assistant}`)}
                                    className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                  >
                                    <img height="" width=""
                                      loading="lazy"
                                      src="/assets/img/Group-icon-msg.png"
                                      alt="Icon"
                                      className="w-10 h-10"
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                <div className="flex justify-between flex-wrap py-1">
                                  <span className="text-lg lg:text-lg font-medium">
                                    {item.character.name ? item.character.name.charAt(0).toUpperCase() + item.character.name.slice(1).toLowerCase() : ""}
                                  </span>
                                  <span className="text-lg lg:text-lg font-medium">
                                    {item.character.age}
                                  </span>
                                </div>
                                <Tooltip title={item.character.summary} placement="top">
                                  <p className="text-[14px] lg:text-base truncate">
                                    {item.character.summary}
                                  </p>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        ))}
                      {Userlogger ? (
                        ""
                      ) : (
                        <div className="relative bg-[#990013] rounded-xl">
                          <div className=" inset-0 flex flex-col duration-700 py-5 px-3 sm:p-5">
                            <div className="flex justify-center p-0 sm:p-4">
                              <i className="fa-solid fa-gift fa-4x"></i>
                            </div>
                            <div className="bg-opacity-70 my-3 sm:my-10 text-white p-2 rounded-b-lg">
                              <div className="flex flex-col gap-4 sm:gap-10 text-center py-1">
                                <span className="text-lg sm:text-xl lg:text-2lg xl:text-3xl font-medium">
                                  Register To View More
                                </span>
                                <span className="text-lg sm:text-xl lg:text-2lg xl:text-3xl font-medium">
                                  It's FREE To Join
                                </span>
                              </div>
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg">
                              <button
                                className="bg-white [w-75%] text-[#990013] font-semibold rounded-2xl p-4"
                                onClick={CreateDreamBf}
                              >
                                Register To Join
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

              </>
            ) : (
              <>
                {bannerList.boys.length > 0 ? (
                  <Carousel
                    autoPlay
                    interval={3000}
                    infiniteLoop
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    transitionTime={500}
                    className="rounded-xl overflow-hidden"
                  >
                    {bannerList.boys.map((banner, index) => (
                      <div key={index} onClick={() => navigate(`${banner.name}`)}>
                        <img height="" width="" src={banner.url} alt={`Boys Banner ${index + 1}`} />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <p></p>
                )}
                <div className="text-2xl lg:text-3xl lg:my-4 mt-0 font-bold lg:py-4 py-0 text-black text-center uppercase">
                  Meet Our Best{" "}
                  <span className="text-[#991b1b]">AI BoyFriends</span>
                </div>
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={() => handleTabClickboy(1)}
                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tl-lg rounded-bl-lg transition-colors duration-300 ${activeTabani === 1
                      ? "bg-[#991b1b] text-white"
                      : "bg-gray-200 text-gray-600"
                      }`}
                  >
                    Realistic
                  </button>
                  <button
                    onClick={() => handleTabClickboy(2)}
                    style={{ margin: "0" }}
                    className={`px-4 py-2 w-[25%] font-semibold text-lg rounded-tr-lg rounded-br-lg transition-colors duration-300 ${activeTabani === 2
                      ? "bg-[#991b1b] text-white"
                      : "bg-gray-200 text-gray-600"
                      }`}
                  >
                    Anime
                  </button>
                </div>
                <div className="mt-4">
                  {activeTabani === 1 && (
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                      {loading
                        ? Array.from({ length: imgboydata?.length || 10 }).map((_, index) => (<Skelton key={index} />))
                        : imgboydata.map((item, index) => (
                          <div
                            key={index}
                            className={`relative ${index === -1 ? "rounded-md border-2 border-red-600 shadow-md shadow-red-800 " : ""}`}
                            onMouseEnter={() => {
                              setHoveredIndex(index)
                              if (item.character.vid_url) {
                                const videoElement = document.getElementById(`hover-video-${index}`);
                                if (videoElement) {
                                  videoElement.load();
                                  videoElement.play().catch(e => console.log("Video play error:", e));
                                }
                              }
                            }}
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <div
                              className="overflow-hidden rounded-xl"
                              onClick={() => openModal(item.assistant, item.character.img_url)}
                            >
                              <img
                                loading="lazy"
                                src={!item.character.vid_url && hoveredIndex === index ? item.character.img_url2 : item.character.img_url}
                                alt={item.character.name ? item.character.name.charAt(0).toUpperCase() + item.character.name.slice(1).toLowerCase() : ""}
                                className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-all duration-1000 ease-in-out ${hoveredIndex === index && !item.character.vid_url ? "scale-105 rounded-xl" : ""
                                  }`}
                                style={{
                                  opacity: item.character.vid_url && hoveredIndex === index ? 0 : 1, // Hide when video plays
                                }}
                              />
                              {/* Video element - only rendered if item has video URL */}
                              {item.character.vid_url && (
                                <video
                                  id={`hover-video-${index}`}
                                  src={item.character.vid_url}
                                  alt={`Video of ${item.character.name}`}
                                  className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out`}
                                  autoPlay={hoveredIndex === index}
                                  loop
                                  muted
                                  playsInline
                                  style={{
                                    opacity: hoveredIndex === index ? 1 : 0,
                                    transition: 'opacity 1000ms ease-in-out',
                                    position: 'absolute',
                                    top: 0,
                                    zIndex: 0
                                  }}
                                />
                              )}
                            </div>
                            <div
                              id="char-info-GR"
                              className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                              onClick={(e) => {
                                if (e.target.id === "char-info-GR") openModal(item.assistant, item.character.img_url);
                              }}
                            >
                              <div className="grid justify-end pt-4 rounded-lg">
                                <Tooltip title={"Chat Now"} className="grid" placement="left">
                                  <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                    <img height="" width=""
                                      loading="lazy"
                                      src="/assets/img/Group-icon.png"
                                      onClick={() => Chatnow(item.assistant)}
                                      alt="Icon"
                                      className="w-10 h-10"
                                    />
                                  </button>
                                </Tooltip>
                                <Tooltip title={"Image Create"} className="grid" placement="left">
                                  <button onClick={() => navigate(`/dashboard/generate/${item.assistant}`)}
                                    className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                  >
                                    <img height="" width=""
                                      loading="lazy"
                                      src="/assets/img/Group-icon-msg.png"
                                      alt="Icon"
                                      className="w-10 h-10"
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                <div className="flex justify-between flex-wrap py-1">
                                  <span className="text-lg lg:text-lg font-medium">
                                    {item.character.name ? item.character.name.charAt(0).toUpperCase() + item.character.name.slice(1).toLowerCase() : ""}
                                  </span>
                                  <span className="text-lg lg:text-lg font-medium">
                                    {item.character.age}
                                  </span>
                                </div>
                                <Tooltip title={item.character.summary} placement="top">
                                  <p className="text-[14px] lg:text-base truncate">
                                    {item.character.summary}
                                  </p>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        ))}
                      {Userlogger ? (
                        ""
                      ) : (
                        <div className="relative bg-[#990013] rounded-xl">
                          {/* <img height="" width="" loading ='lazy' src={img1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' /> */}
                          <div className=" inset-0 flex flex-col duration-700 py-5 px-3 sm:p-5">
                            <div className="flex justify-center p-0 sm:p-4">
                              <i className="fa-solid fa-gift fa-4x"></i>
                            </div>
                            <div className="bg-opacity-70 my-3 sm:my-10 text-white p-2 rounded-b-lg">
                              <div className="flex flex-col gap-4 sm:gap-10 text-center py-1">
                                <span className="text-lg sm:text-xl lg:text-2lg xl:text-3xl font-medium">
                                  Register To View More
                                </span>
                                <span className="text-lg sm:text-xl lg:text-2lg xl:text-3xl font-medium">
                                  It's FREE To Join
                                </span>
                              </div>
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg">
                              <button
                                className="bg-white [w-75%] text-[#990013] font-semibold rounded-2xl p-4"
                                onClick={CreateDreamBf}
                              >
                                Register To Join
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {activeTabani === 2 && (
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                      {loading
                        ? Array.from({ length: imgboydataani?.length || 10 }).map((_, index) => (<Skelton key={index} />))
                        : imgboydataani.map((item, index) => (
                          <div
                            key={index}
                            className={`relative ${index === -1 ? "rounded-md border-2 border-red-600 shadow-md shadow-red-800 " : ""}`}
                            onMouseEnter={() => {
                              setHoveredIndex(index)
                              if (item.character.vid_url) {
                                const videoElement = document.getElementById(`hover-video-${index}`);
                                if (videoElement) {
                                  videoElement.load();
                                  videoElement.play().catch(e => console.log("Video play error:", e));
                                }
                              }
                            }}
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <div
                              className="overflow-hidden rounded-xl"
                              onClick={() => openModal(item.assistant, item.character.img_url)}
                            >
                              <img
                                loading="lazy"
                                src={!item.character.vid_url && hoveredIndex === index ? item.character.img_url2 : item.character.img_url}
                                alt={item.character.name ? item.character.name.charAt(0).toUpperCase() + item.character.name.slice(1).toLowerCase() : ""}
                                className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-all duration-1000 ease-in-out ${hoveredIndex === index && !item.character.vid_url ? "scale-105 rounded-xl" : ""
                                  }`}
                                style={{
                                  opacity: item.character.vid_url && hoveredIndex === index ? 0 : 1, // Hide when video plays
                                }}
                              />
                              {/* Video element - only rendered if item has video URL */}
                              {item.character.vid_url && (
                                <video
                                  id={`hover-video-${index}`}
                                  src={item.character.vid_url}
                                  alt={`Video of ${item.character.name}`}
                                  className={`h-80 w-full xl:h-[500px] rounded-xl object-cover transition-transform duration-700 ease-in-out`}
                                  autoPlay={hoveredIndex === index}
                                  loop
                                  muted
                                  playsInline
                                  style={{
                                    opacity: hoveredIndex === index ? 1 : 0,
                                    transition: 'opacity 1000ms ease-in-out',
                                    position: 'absolute',
                                    top: 0,
                                    zIndex: 0
                                  }}
                                />
                              )}
                            </div>
                            <div
                              id="char-info-GR"
                              className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700"
                              onClick={(e) => {
                                if (e.target.id === "char-info-GR") openModal(item.assistant, item.character.img_url);
                              }}
                            >
                              <div className="grid justify-end pt-4 rounded-lg">
                                <Tooltip title={"Chat Now"} className="grid" placement="left">
                                  <button className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                    <img height="" width=""
                                      loading="lazy"
                                      src="/assets/img/Group-icon.png"
                                      onClick={() => Chatnow(item.assistant)}
                                      alt="Icon"
                                      className="w-10 h-10"
                                    />
                                  </button>
                                </Tooltip>
                                <Tooltip title={"Image Create"} className="grid" placement="left">
                                  <button onClick={() => navigate(`/dashboard/generate/${item.assistant}`)}
                                    className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                                  >
                                    <img height="" width=""
                                      loading="lazy"
                                      src="/assets/img/Group-icon-msg.png"
                                      alt="Icon"
                                      className="w-10 h-10"
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                                <div className="flex justify-between flex-wrap py-1">
                                  <span className="text-lg lg:text-lg font-medium">
                                    {item.character.name ? item.character.name.charAt(0).toUpperCase() + item.character.name.slice(1).toLowerCase() : ""}
                                  </span>
                                  <span className="text-lg lg:text-lg font-medium">
                                    {item.character.age}
                                  </span>
                                </div>
                                <Tooltip title={item.character.summary} placement="top">
                                  <p className="text-[14px] lg:text-base truncate">
                                    {item.character.summary}
                                  </p>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        ))}
                      {Userlogger ? (
                        ""
                      ) : (
                        <div className="relative bg-[#990013] rounded-xl">
                          {/* <img height="" width="" loading ='lazy' src={img1} alt="img1" className='h-80 w-full xl:h-[500px] rounded-xl object-cover' /> */}
                          <div className=" inset-0 flex flex-col duration-700 py-5 px-3 sm:p-5">
                            <div className="flex justify-center p-0 sm:p-4">
                              <i className="fa-solid fa-gift fa-4x"></i>
                            </div>
                            <div className="bg-opacity-70 my-3 sm:my-10 text-white p-2 rounded-b-lg">
                              <div className="flex flex-col gap-4 sm:gap-10 text-center py-1">
                                <span className="text-lg sm:text-xl lg:text-2lg xl:text-3xl font-medium">
                                  Register To View More
                                </span>
                                <span className="text-lg sm:text-xl lg:text-2lg xl:text-3xl font-medium">
                                  It's FREE To Join
                                </span>
                              </div>
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 text-center bg-opacity-70 text-black p-2 rounded-b-lg">
                              <button
                                className="bg-white [w-75%] text-[#990012] font-semibold rounded-2xl p-4"
                                onClick={CreateDreamBf}
                              >
                                Register To Join
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            {Userlogger ? (
              <div className="text-center mt-4 bg-opacity-70 text-black p-2 rounded-b-lg">
                <button
                  className="bg-red-800 [w-75%] text-white font-medium rounded-2xl py-2 px-4"
                  onClick={() => loadMoreRecards(`${activeTab}_${activeTabani}`)}
                  style={{
                    display:
                      `${activeTab}_${activeTabani}` === "Girlfriend_1" &&
                        realisticGirlData.length === imgdata.length
                        ? "none"
                        : `${activeTab}_${activeTabani}` === "Girlfriend_2" &&
                          animeGirlData.length === imgdataanime.length
                          ? "none"
                          : `${activeTab}_${activeTabani}` === "Boyfriend_1" &&
                            realisticBoyData.length === imgboydata.length
                            ? "none"
                            : `${activeTab}_${activeTabani}` === "Boyfriend_2" &&
                              animeBoyData.length === imgboydataani.length
                              ? "none"
                              : "",
                  }}
                >
                  <i className="fa-solid fa-angles-down mr-1"></i> Load More
                </button>
              </div>
            ) : (
              ""
            )}
            <div className="py-0 mo:py-4 mt-4">
              <h2 className="hidden md:block text-[20px] md:text-2xl lg:text-3xl font-extrabold text-center my-8 text-gray-800 uppercase">
                How To Make Your Virtual{" "}
                <span className="text-[#990012]">AI {activeTab}</span> With
                Eromantic
              </h2>
              <h2 className="block md:hidden text-[22px] md:text-2xl lg:text-3xl font-extrabold text-center my-8 text-gray-800 uppercase">
                How To Make Your Virtual <br />
                <span className="text-[#990012]">AI {activeTab}</span> With
                Eromantic
              </h2>
              <p className="text-lg text-center mb-1 mo:mb-[5rem] capitalize text-gray-600">
                It’s super easy to create your dream {activeTab}. Follow these
                simple steps:
              </p>
              <Characters />
            </div>
            <Blogs />
            <Accordion />
            <Footer />
          </div>
          {showPopup && <LandingPopup onClose={handleClosePopup} />}

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center">
              <div
                className="bg-white p-8 rounded w-[85%] md:w-[80%] mt-10 xxs:h-[70%] md:h-[80%] flex flex-col items-center justify-center"
                style={{
                  // backgroundImage: `url(${modalImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  // filter: 'contrast(0.5)',
                }}
              >
                <div className="relative -top-[3%] left-[50%]">
                  <button
                    onClick={closeModal}
                    className="text-black border-none  py-2 rounded"
                  >
                    <i className="fa-regular fa-2xl fa-circle-xmark"></i>
                  </button>
                </div>
                <div
                  className="flex flex-col gap-5 sm:gap-16 sm:flex-row overflow-scroll"
                  style={{ scrollbarWidth: "none" }}
                >
                  <div>
                    <img height="" width=""
                      loading="lazy"
                      src={modalImg}
                      alt="Generated Img"
                      className="w-full h-[300px] sm:h-[330px] md:h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="flex flex-col gap-8">
                    <div className="flex justify-between">
                      <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                        {modaldata.name
                          ? modaldata.name.charAt(0).toUpperCase() +
                          modaldata.name.slice(1).toLowerCase()
                          : ""}
                      </h2>
                      <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                        <span
                          onClick={() => toggleLike(modaldata.assistant_id)}
                          className="ms-5"
                        >
                          {likesStatus ? (
                            <>
                              <i className="fa-solid fa-sm fa-heart"></i>{" "}
                              <span className="text-lg">{LikesCount} Liked</span>
                            </>
                          ) : (
                            <>
                              <i className="fa-regular fa-sm fa-heart"></i>{" "}
                              <span className="text-lg">{LikesCount} Likes</span>
                            </>
                          )}
                        </span>
                      </h2>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 mb-3">
                      {itemsToDisplay.map(({ label, value, src }, index) => (
                        <div key={index} className="flex  items-start gap-2">
                          <img height="" width=""
                            loading="lazy"
                            src={src}
                            className="icon w-5 md:w-10 mt-1 "
                            alt="icon"
                          />

                          <div className="flex flex-col">
                            <span className="text-muted text-gray-800 font-bold text-[14px] mo:text-lg md:text-xl  ">
                              {label}
                            </span>
                            <span className="uppercase text-[12px] mo:text-base md:text-lg">
                              {value}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="flex items-start">
                      <span className="icon mt-1 mr-2 icon-summary"></span>
                      <div className="flex flex-col">
                        <span className="font-semibold text-muted text-base mo:text-lg md:text-xl lg:text-2xl ">
                          About:
                        </span>
                        <span className="text-[12px] mo:text-base md:text-lg lg:text-xl">
                          {modaldata.Summary}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%] mt-4"
                  onClick={() => Chatnow(modaldata.assistant_id)}
                >
                  Chat Now
                </button>
              </div>
            </div>
          )}

        </div>

        {
          errorisOpen && <UpgradeNow errorCloseModal={errorCloseModal} />
        }
      </PageWrapper>
    </>
  );
};

export default LandingPage;
