import { useState, useEffect, useRef } from "react";
// import axios from 'axios';
// import { RiCompassDiscoverLine, RiAiGenerate } from "react-icons/ri";
// import { TbGenderDemigirl, TbPremiumRights, TbArrowGuide, TbGenderDemiboy } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import UserApiClient from "../../utilities/apiClients";
import { alert } from "../../assets"
import UserApiClient from "../../utilities/apiClients";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import UpgradeNow from "../../dashboard/components/UpgradeNow";

export const Sidebar = ({ isOpen, setActiveTab, setIsOpen, closeTrial }) => {
  const userData = useSelector(state => state.user);
  const user = userData.user;
  const [active, setActive] = useState(1);
  const [Userlogger, setUserlogger] = useState(null);
  const [usercheck, setUsercheck] = useState(null);
  const [subtype, setSubtype] = useState('');
  // const [errors, setErrors] = useState({});
  const [isAffiliated, setIsAffiliated] = useState(false);
  const goto = (index) => setActive(index);
  const navigate = useNavigate();

  const [errorisOpen, setErrorIsOpen] = useState(false);
  const [subscriptionType, SetSubscriptiontype] = useState(null);
  const errorCloseModal = () => setErrorIsOpen(false);
  const errorOpenModal = () => setErrorIsOpen(true);
  const users = useSelector(state => state.user);

  useEffect(() => {
    setUsercheck(localStorage.getItem('hasVisited'));
    setUserlogger(localStorage.getItem('token') && userData.isAuthenticated);
  }, []);

  const closeSidebar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (userData.isAuthenticated && Userlogger) {
      setIsAffiliated(user.is_affiliated);
      const subscription = localStorage.getItem('subscription_type');
      SetSubscriptiontype(subscription);
    }
  }, [userData, Userlogger]);

  const LatestCharacter = () => {
    UserApiClient.get("assistant_api/bot_fetch/", {}, false, true, false
    ).then(async (res) => {
      if (res.data) {
        if (Userlogger && res.data.data) {
          navigate(`/dashboard/generate/${res.data.data[0].assistant_id}`)
        } else {
          navigate("/ai-girlfriend-generator");
        }
      } else {
        Swal.fire(
          'Our Servers are busy!',
          'Please wait a moment and try again.',
          'warning',
          3000
        );
      }
    });
  }

  const location = useLocation();
  const isAidreamGFchat = location.pathname.startsWith('/AidreamGFchat');
  const AiChat = location.pathname.includes('aichat');

  const navItems = [
    { label: "Home", icon: <i className="fa-solid fa-house"></i>, link: "/" },
    { label: "Create AI Companion", icon: <i className="fa-solid fa-children"></i>, link: Userlogger ? "/ai-girlfriend-generator" : "/login" },
    { label: "Porn Generator", icon: <i className="fa-solid fa-heart-circle-plus"></i>, link: Userlogger ? "/ai-porn-image-generator" : "/login" },
    { label: "Chat", icon: <i className="fa-solid fa-comments"></i>, link: Userlogger ? "/aichat" : "/login" },
    // { label: "Generations", icon: <i className="fa-solid fa-camera-retro"></i>, link: Userlogger ? `/dashboard/generate/${assistant_id}` : "/login" },
    { label: "Companion Image Generator", icon: <i className="fa-solid fa-camera-retro"></i> },
    { label: "Creations", icon: <i className="fa-solid fa-shield-heart"></i>, link: Userlogger ? "/dashboard/mycreation" : "/login" },
    { label: "Discover", icon: <i className="fa-solid fa-compass"></i>, link: Userlogger ? "/dashboard/discover-ai-characters" : "/login" },
    { label: "My Gallery", icon: <i className="fa-solid fa-file-circle-plus"></i>, link: Userlogger ? "/dashboard/my-gallery" : "/login" },
    { label: "Pricing", icon: <i className="fa-solid fa-square-poll-vertical"></i>, link: Userlogger ? "/subscription-plan" : "/login" },
    {
      label: "Affiliates",
      icon: <i className="fa-solid fa-user-plus"></i>,
      link: isAffiliated
        ? (Userlogger ? "/dashboard/referrals" : "/login")
        : (Userlogger ? "/dashboard/become-an-affiliate" : "/login")
    },
    { label: "Support", icon: <i className="fa-solid fa-headset"></i>, link: "https://support.eromantic.ai/hc/en-us", isExternal: true },

  ];

  const showTrial = localStorage.getItem("showtrial")
  // const subtype = localStorage.getItem("subscription_type")
    useEffect(() => {
    if (userData.isAuthenticated) {
      UserApiClient.get("api/v1/get_token/", {}, true).then(
        async (response) => {
          if (response.success) {
            setSubtype(response.data.subscription_type);
          }
        }
      );
    }
  }, []);
  useEffect(() => {

  }, [showTrial])

  return (
    <>
      <ToastContainer />
      <div className="">
        {
          subtype == "free trial" ?
            <aside style={{ background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover', top: ((location.pathname === '/' && closeTrial) ? (users.isAuthenticated ? "100px" : "65px") : (isAidreamGFchat || AiChat) ? "0px" : "65px") }} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}>
              {/* <aside style={{ background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover', ...(isAidreamGFchat ? { top: '0' } : {}) }} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}> */}
              <div className="inner">
                <nav className="menu">
                  {navItems.map((item, index) => (
                    <div key={item.label} style={{ textDecoration: 'none' }}>
                      <button
                        className={active === index ? "active justify-start" : " justify-start"}
                        onClick={() => {
                          goto(index);
                          if (item.onClick) {
                            item.onClick();
                          } else if (item.isExternal) {
                            window.open(item.link, "_blank"); // Opens the link in a new tab
                          } else {
                            navigate(item.link); // Navigates for internal routes
                          }
                          closeSidebar()
                          if (item.label === "Companion Image Generator") { LatestCharacter() };
                        }}
                        style={{
                          backgroundColor: item.backgroundColor ? item.backgroundColor : '',
                          borderRight: item.borderRight ? item.borderRight : '',
                          borderTopRightRadius: item.borderTopRightRadius ? item.borderTopRightRadius : '', // Apply top-right border radius
                          borderBottomRightRadius: item.borderBottomRightRadius ? item.borderBottomRightRadius : '', // Apply bottom-right border radius
                          paddingRight: item.borderRight ? '10px' : '' // Add padding to avoid overlapping with the border
                        }}
                      >
                        <div className="w-[10%] flex justify-center"><span className="text-[18px] xl:text-[22px] text-black">{item.icon}</span></div>
                        <div className=""><p className="text-[13px] lg:text-[11px] xl:text-[12px] text-black">{item.label}</p></div>
                      </button>

                    </div>
                  ))}
                </nav>
              </div>
            </aside>
            :
            <aside style={{ background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover', top: ((location.pathname === '/' && closeTrial) ?  "70px" : (isAidreamGFchat || AiChat) ? "0px" : "65px") }} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}>
              {/* <aside style={{ background: 'linear-gradient(135deg, rgb(255 255 255 / 81%), rgb(161 145 145) 40%, rgb(109 109 109 / 80%) 70%) center center / cover', ...(isAidreamGFchat ? { top: '0' } : {}) }} className={`sidebar ${isOpen ? 'sidewidth' : ''}`}> */}
              <div className="inner">
                <nav className="menu">
                  {navItems.map((item, index) => (
                    <div key={item.label} style={{ textDecoration: 'none' }}>
                      <button
                        className={active === index ? "active justify-start" : " justify-start"}
                        onClick={() => {
                          goto(index);
                          if (item.onClick) {
                            item.onClick();
                          } else if (item.isExternal) {
                            window.open(item.link, "_blank"); // Opens the link in a new tab
                          } else {
                            navigate(item.link); // Navigates for internal routes
                          }
                          closeSidebar()
                          if (item.label === "Companion Image Generator") { LatestCharacter() };
                        }}
                        style={{
                          backgroundColor: item.backgroundColor ? item.backgroundColor : '',
                          borderRight: item.borderRight ? item.borderRight : '',
                          borderTopRightRadius: item.borderTopRightRadius ? item.borderTopRightRadius : '', // Apply top-right border radius
                          borderBottomRightRadius: item.borderBottomRightRadius ? item.borderBottomRightRadius : '', // Apply bottom-right border radius
                          paddingRight: item.borderRight ? '10px' : '' // Add padding to avoid overlapping with the border
                        }}
                      >
                        <div className="w-[10%] flex justify-center"><span className="text-[18px] xl:text-[22px] text-black">{item.icon}</span></div>
                        <div className=""><p className="text-[13px] lg:text-[11px] xl:text-[12px] text-black">{item.label}</p></div>
                      </button>

                    </div>
                  ))}
                </nav>
              </div>
            </aside>
        }
      </div>
      <div className="">
        <aside className={`sidebarmob ${isOpen ? 'sidewidthmob' : ''}`}>
          <div className="inner">
            <nav className="menu">
              {navItems.map((item, index) => (
                <div key={item.label} style={{ textDecoration: 'none' }}>
                  <button
                    className={active === index ? "active" : ""}
                    onClick={() => {
                      goto(index);
                      if (item.onClick) {
                        item.onClick();
                      } else if (item.isExternal) {
                        window.open(item.link, "_blank"); // Opens the link in a new tab
                      } else {
                        navigate(item.link); // Navigates for internal routes
                      }
                      closeSidebar()
                      if (item.label === "Companion Image Generator") { LatestCharacter() };
                    }}
                    style={{
                      backgroundColor: item.backgroundColor ? item.backgroundColor : '',
                      borderRight: item.borderRight ? item.borderRight : '',
                      borderTopRightRadius: item.borderTopRightRadius ? item.borderTopRightRadius : '', // Apply top-right border radius
                      borderBottomRightRadius: item.borderBottomRightRadius ? item.borderBottomRightRadius : '', // Apply bottom-right border radius
                      paddingRight: item.borderRight ? '10px' : '' // Add padding to avoid overlapping with the border
                    }}
                  >
                    <div className="w-[10%] flex justify-center"><span className="text-[18px] xl:text-[24px] text-black">{item.icon}</span></div>
                    <div className=""><p className="text-[12px] xl:text-[13px] text-black">{item.label}</p></div>
                  </button>

                </div>
              ))}
            </nav>
          </div>
        </aside>
      </div>


      {errorisOpen && (
           <UpgradeNow errorCloseModal={errorCloseModal} />
      )}

    </>
  );
};

export default Sidebar;
