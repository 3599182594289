import React, { useEffect, useState } from 'react';
import Togal from '../components/Togal';
import { DeleteImg, eye } from '../../assets';
import UserApiClient from '../../utilities/apiClients';
import Swal from 'sweetalert2';
import AdminPagination from './AdminPagination';
import PagenameSearchBox from '../components/PagenameSearchBox';


const Characters = () => {
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [currentStaff, setCurrentStaff] = useState({});
    const [characterList, setCharacterList] = useState([]);
    const [pageLinks, setPageLinks] = useState({ "next": null, "prev": null });
    const [searchQuery, setSearchQuery] = useState(null);
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(null);
    const [pageSize, setPageSize] = useState(null);

    const fetchData = async (pageLink) => {
        await UserApiClient.get(pageLink && pageLink.includes('?page') ?
            `${pageLink}${"&search=" + (searchQuery != null ? searchQuery : '')}${pageSize != null ? '&page_size=' + pageSize : ''}` :
            `api/v1/admin/character/${"?search=" + (searchQuery != null ? searchQuery : '')}${pageSize != null ? '&page_size=' + pageSize : ''}`,
            {}, true).then(async res => {
                if (res.success) {
                    setPageLinks({ 'next': res.data.next, 'prev': res.data.previous });
                    setCharacterList(res.data.results);
                }
            })
    }

    useEffect(() => {
        fetchData();
    }, [])

    // const openModal = () => setModalIsOpen(true);

    // const closeModal = () => {
    //     setPermissions(permission);
    //     setModalIsOpen(false);
    //     setLastName('');
    //     setFirstName('');
    //     setEmail('');
    //     setPassword('');
    // };

    const openDetailModal = (staff) => {
        setCurrentStaff(staff);
        setDetailModalOpen(true);
    };
    const closeDetailModal = () => {
        setDetailModalOpen(false);
    };

    // const handleAddStaff = async (event) => {
    //     event.preventDefault();
    //     if (!firstName || !lastName || !email || !password) {
    //         alert('Please fill all fields');
    //         return;
    //     }
    //     const permissionData = new FormData();
    //     permissions.map((perm, index) => {
    //         permissionData.append(perm.name, perm.value);
    //     })
    //     permissionData.append('first_name', firstName)
    //     permissionData.append('last_name', lastName)
    //     permissionData.append('email', email)
    //     permissionData.append('password', password)

    //     await UserApiClient.post('api/v1/admin/staff/', permissionData, true).then(async res => {
    //         if (res.success) {
    //             await Swal.fire(res.data.message, '', 'success');
    //             await fetchData();
    //         }
    //         closeModal();
    //     })
    // };

    // const handleDelete = async (e, id) => {
    //     await Swal.fire({
    //         title: "Are you sure?",
    //         text: `You won't be able to revert this!`,
    //         icon: "question",
    //         showCancelButton: true,
    //         confirmButtonColor: "#d33",
    //         confirmButtonText: `Yes!`,
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             await UserApiClient.delete(`api/v1/admin/character/${id}/`, {}, true).then(async res => {
    //                 if (res.success) {
    //                     await Swal.fire('Charachter freezed.', '', 'success');
    //                     await fetchData();
    //                 }
    //             });
    //         }
    //     });
    // };

    const handleStatusChange = async (e, id) => {
        await Swal.fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            icon: "question",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: `Yes!`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                await UserApiClient.patch(`api/v1/admin/character/${id}/`, { is_trashed: e.target.checked }, true).then(async (res) => {
                    if (res.success) {
                        Swal.fire(`Character ${e.target.checked ? "Inactivate" : "Activated"}.`, "", "success", 3000);
                    }
                });
            } else {
                e.target.checked = e.target.checked;
            }
        });
    };


    const handlePrevNextPage = async (btn) => {
        if (btn === 'next') {
            fetchData(pageLinks.next);
        } else if (btn === 'prev') {
            fetchData(pageLinks.prev);
        }
    };


    // const togglePermission = (name, currentValue) => {
    //     setPermissions((prevPermissions) =>
    //         prevPermissions.map((permission) =>
    //             permission.name === name
    //                 ? { ...permission, value: !currentValue }
    //                 : permission
    //         )
    //     );
    // };

    const handlePermissionStatus = async (e, name) => {
        console.log(name, e.target.checked, currentStaff)
        await UserApiClient.patch(`api/v1/admin/staff/${currentStaff.id}/`, { [name]: e.target.checked }, true).then(async (res) => {
            if (!res.success) {
                e.target.checked = e.target.checked;
            }
        });
    };


    useEffect(() => {
        if (searchQuery != null) {
            const timer = setTimeout(() => {
                setDebouncedSearchQuery(searchQuery);
            }, 700);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [searchQuery]);

    useEffect(() => {
        if (debouncedSearchQuery != null) {
            fetchData()
        }
    }, [debouncedSearchQuery]);

    useEffect(() => {
        if (pageSize != null) {
            fetchData()
        }
    }, [pageSize]);

    return (
        <div className="h-full bg-white px-7 pb-20 font-josefin-sans">
            <div className="overflow-x-auto">
                <PagenameSearchBox
                    title="Staff List"
                    placename="Search by email"
                    inputValue={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                />
                <div className="flex justify-end space-x-4">
                    <select className="py-1.5 px-3 focus:outline-none text-lg rounded h-10" onChange={(e) => setPageSize(e.target.value)}>
                        <option value='20'>20</option>
                        <option value='50'>50</option>
                        <option value='100'>100</option>
                        <option value='500'>500</option>
                        <option value='1000'>1000</option>
                    </select>
                    {/* <button onClick={openModal} className="mb-6 bg-red-700 text-white py-2 px-4 rounded float-right">
                        Add Staff
                    </button> */}
                </div>
                <div className="relative overflow-x-auto rounded-lg mt-10 font-josefin-sans">
                    <table className="w-full text-left text-gray-500 border border-stone-300 table-auto">
                        <thead className="text-xl text-black bg-stone-300">
                            <tr>
                                <th scope="col" className="px-2 2xl:px-6 py-3">#ID</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Name</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Email</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Created At</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3">Status</th>
                                <th scope="col" className="px-2 2xl:px-6 py-3 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {characterList.map((staff, index) => (
                                <tr key={staff.id} className="bg-white text-xl font-normal text-black border-b border-stone-300">
                                    <td className="px-2 2xl:px-6 py-4">#{staff.id}.</td>
                                    <td className="px-2 2xl:px-6 py-4 cursor-pointer flex items-center gap-4" onClick={() => openDetailModal(staff)}>
                                        <img src={staff.img_url} className='w-10' />
                                        {staff.name}
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4 capitalize">{staff.gender}/{staff.age}</td>
                                    <td className="px-2 2xl:px-6 py-4">{staff.create_time?.split("T")[0]}</td>
                                    <td className="px-6 py-4">
                                        <label className="inline-flex items-center cursor-pointer ">
                                            <input
                                                type="checkbox"
                                                className="sr-only peer"
                                                onChange={async (e) => { await handleStatusChange(e, staff.id); await fetchData() }}
                                                checked={!staff.is_trashed}
                                            />
                                            <div className="relative w-11 h-6 bg-red-500 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500" />
                                        </label>
                                    </td>
                                    <td className="px-2 2xl:px-6 py-4 flex-wrap flex gap-5 justify-center items-center">
                                        <img loading='lazy'
                                            src={eye}
                                            alt="eye"
                                            className="hover:cursor-pointer"
                                            onClick={() => openDetailModal(staff)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <AdminPagination pageLinks={pageLinks} handlePrevNextPage={handlePrevNextPage} />

            {detailModalOpen && (
                <div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex justify-center items-center px-4 md:px-0">
                    <div className="relative bg-white rounded-lg shadow-xl pb-6 px-6 w-full max-w-4xl max-h-[70vh] overflow-y-auto top-[10%]">
                        {/* Sticky Header */}
                        <div className="sticky top-0 left-0 z-50 bg-white -mx-6 px-6 py-4 border-b">
                            <div className="flex justify-between items-center">
                                <p className="text-2xl font-bold">Character Detail</p>
                                <div className="cursor-pointer" onClick={closeDetailModal}>
                                    <svg
                                        className="fill-current text-gray-700"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                    >
                                        <path d="M14.59 3.41L11.18 6.82L14.59 10.23L13.18 11.64L9.77 8.23L6.36 11.64L4.95 10.23L8.36 6.82L4.95 3.41L6.36 2L9.77 5.41L13.18 2L14.59 3.41Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        {/* Modal Content */}
                        <div className="space-y-4 mt-4">

                            {currentStaff?.id &&
                                Object.entries(currentStaff).map(
                                    ([key, value]) =>
                                        typeof value !== 'object' && value &&
                                    // !['id', 'me'].includes(key) &&
                                    (
                                            <div key={key} className="flex justify-between items-center">
                                                <p className="text-lg font-semibold capitalize mr-5">
                                                    {key.replaceAll('_', ' ')}
                                                </p>
                                                <p className="text-lg font-sm capitalize">
                                                    {value}
                                                </p>
                                            </div>
                                        )
                                )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Characters;
