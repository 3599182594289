import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Bottombar = ({ toggleSidebar, isOpen,setActiveTab }) => {
    const User = useSelector(state => state.user);
    const [Userlogger, setUserlogger] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setUserlogger(User.isAuthenticated);
    }, [User]); // Add dependency array to avoid infinite loop

    const handleTabClickGirl = () => {
        setActiveTab('Girlfriend'); // Set the active tab
        navigate('/'); // Redirect to the root page
      };
    const handleTabClickBoy = () => {
        setActiveTab('Boyfriend'); // Set the active tab
        navigate('/'); // Redirect to the root page
      };

    return (
        <>
            <div className="bottombar bg-[#dbd5d5] fixed bottom-0 z-1000 w-full -translate-x-1/2  border-t border-black left-1/2 " style={{zIndex:"9999"}}>
                <div className="w-full">
                    <div className="grid max-w-xs grid-cols-2 gap-1 p-1 mx-auto mt-1 bg-[#99111f] rounded-lg" role="group">
                        <button type="button" onClick={handleTabClickGirl} className="px-5 py-1.5 text-xs font-medium text-white hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-red-600 rounded-lg">
                            AI GirlFriend
                        </button>
                        <button type="button" onClick={handleTabClickBoy} className="px-5 py-1.5 text-xs font-medium text-white hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-red-600 rounded-lg">
                            AI BoyFriend
                        </button>
                    </div>
                </div>
                <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
                {!isOpen ?
                    <button data-tooltip-target="tooltip-home"  onClick={toggleSidebar} type="button" className="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                    <svg className="w-6 h-6 mb-1 text-black group-hover:text-black-600 dark:group-hover:text-black-500" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
                        <span className="sr-only">Home</span>
                    </button>
                    :
                    <button data-tooltip-target="tooltip-home"  onClick={toggleSidebar} type="button" className="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                    <svg className="w-6 h-6 mb-1 text-black group-hover:text-black-600 dark:group-hover:text-black-500" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path></svg>
                        <span className="sr-only">Home</span>
                    </button>
                }
                    <div id="tooltip-home" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Home
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button data-tooltip-target="tooltip-bookmark" type="button" onClick={() => navigate(Userlogger ? "/aichat" : "/login")} className="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                        <i className="fa-solid fa-comments !text-[18px] text-black group-hover:text-black-600 dark:group-hover:text-black-500"></i>
                        <span className="sr-only">Chat</span>
                    </button>
                    <div id="tooltip-bookmark" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Chat
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button data-tooltip-target="tooltip-post" type="button" onClick={() => navigate(Userlogger ? "/ai-porn-image-generator" : "/login")} className="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                        <svg className="w-6 h-6 mb-1 text-black group-hover:text-black-600 dark:group-hover:text-black-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
                        </svg>
                        <span className="sr-only">New Generate</span>
                    </button>
                    <div id="tooltip-post" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        New Generate
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button data-tooltip-target="tooltip-search" type="button" onClick={() => navigate(Userlogger ? "/dashboard/my-gallery" : "/login")}  className="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                        <i className="fa-solid fa-images !text-[18px] text-black group-hover:text-black-600 dark:group-hover:text-black-500"></i>
                        <span className="sr-only">Gallery</span>
                    </button>
                    <div id="tooltip-search" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Gallery
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button data-tooltip-target="tooltip-settings" type="button" onClick={() => navigate(Userlogger ? "/dashboard/accountProfile" : "/login")} className="inline-flex flex-col items-center justify-center p-3 m-1 hover:bg-[#ffd1d1] group">
                        <i className="fa-solid fa-user !text-[18px] text-black group-hover:text-black-600 dark:group-hover:text-black-500"></i>
                        <span className="sr-only">Profile</span>
                    </button>
                    <div id="tooltip-settings" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Profile
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bottombar;
