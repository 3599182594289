import React, { useState, useEffect, useRef } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Footer from "../../../frontend/components/Footer";
import UserApiClient from "../../../utilities/apiClients";
import PageWrapper from "../../../utilities/Metatags";
import loaders from "../../../frontend/components/loaders";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import CircularWithValueLabel from "../CreateDreamGF/CircularWithValueLabel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import UpgradeNow from "../../components/UpgradeNow";
import { freebg } from "../../../assets";
import Masonry from "react-masonry-css";
import { Skeleton } from "@mui/material";

const Discover = () => {
  // State declarations
  const [imageData, setImageData] = useState([]);
  const [filteredImageData, setFilteredImageData] = useState([]); // For filtered images via search
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageDataLen, setImageDataLen] = useState(0);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [errorisOpen, setErrorIsOpen] = useState(false);
  const [loadmore, setloadmore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [bannerList, setBannerList] = useState({ discover: [] });
  const modalRef = useRef(null);
  const [pageLinks, setPageLinks] = useState({ next: null, prev: null });

  // Determine user subscription type
  const user_type = localStorage.getItem("subscription_type");
  const isFreeSubscription = user_type === "free";

  // Video data (for paid users, we expect an array of video objects)
  const [videoData, setVideoData] = useState([]);
  const [isLoadingsingleimage, setIsLoadingsingleimage] = useState(true);

  // For free subscription: flatten & shuffle images from filteredImageData.
  // This array will be used only for free users.
  let flatShuffledImages = [];
  if (isFreeSubscription) {
    filteredImageData.forEach((item) => {
      if (item.image_file_name && item.image_file_name.length > 0) {
        item.image_file_name.forEach((imageUrl, imgIndex) => {
          flatShuffledImages.push({
            imageUrl,
            upscale: item.upscale ? item.upscale[imgIndex] : false,
            user_name: item.user_name,
            generation_prompt: item.generation_prompt,
            Aititle: item.Aititle,
            created_at: item.created_at, // assuming created_at exists on each item
          });
        });
      }
    });
    // Shuffle using Fisher–Yates algorithm
    for (let i = flatShuffledImages.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [flatShuffledImages[i], flatShuffledImages[j]] = [
        flatShuffledImages[j],
        flatShuffledImages[i],
      ];
    }
  }

  // Fetch video data from API (for paid users)
  const getAllGeneratedVideos = async () => {
    try {
      const response = await UserApiClient.get(
        `api/video/hunyuan/?status=completed`,
        {},
        true
      );
      if (response?.data) {
        setVideoData(response.data);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  // On component mount
  useEffect(() => {
    const sub_type = localStorage.getItem("subscription_type");
    if (sub_type === "free") {
      setErrorIsOpen(true);
      setloadmore(true);
    }
    fetchData();
    fetchImageData(); // Fetch initial images
    getAllGeneratedVideos();
    window.scrollTo(0, 0);
  }, []);

  // Update filtered images when searchQuery or imageData changes
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredImageData(imageData);
    } else {
      const filtered = imageData.filter((item) =>
        item.generation_prompt.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredImageData(filtered);
    }
  }, [searchQuery, imageData]);

  // Handle image load (to remove skeleton after delay)
  const handleImageLoad = () => {
    setTimeout(() => {
      setIsLoadingsingleimage(false);
    }, 1000);
  };

  // Modal functions for fullscreen image view
  const handleImagechatClick = (baseUrl) => {
    setSelectedImage(baseUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Download functions
  const downloadImage = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Failed to fetch the image");
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `generated-image_${Date.now()}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      toast.success("Downloading...", {
        position: "top-left",
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: 2000,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
    } catch (error) {
      console.error("Failed to download the image:", error);
    }
  };

  const downloadVideo = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Failed to fetch the video");
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `generated_video_${Date.now()}.mp4`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      toast.success("Downloading...", {
        position: "top-left",
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: 2000,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
    } catch (error) {
      console.error("Failed to download the video:", error);
    }
  };

  // Fetch banners for the carousel
  const fetchData = () => {
    UserApiClient.get("api/banner/setting/", {}, false).then(async (res) => {
      if (res.success) {
        setBannerList({ discover: res.data.data.desc_banners });
      }
    });
  };

  // Fetch images from API
  const fetchImageData = async (pageLink) => {
    loaders(true, "heart");
    try {
      const pageUrl =
        typeof pageLink === "string"
          ? pageLink
          : `api/v1/get_allPublic_image/?page_size=10`;
      const res = await UserApiClient.get(pageUrl);
      if (res.success) {
        loaders(true, "heart");
        const newImages = res.data.results;
        if (!newImages || newImages.length === 0) {
          Swal.fire({
            title: "No more images to load!",
            icon: "info",
            confirmButtonColor: "#990013",
            timer: 3000,
          });
          setShowMoreBtn(false);
          loaders(true, "heart");
          return;
        }
        setImageData((prevData) => {
          const newData = [...prevData];
          newImages.forEach((newImage) => {
            const exists = newData.some(
              (image) => image.generation_id === newImage.generation_id
            );
            if (!exists) {
              newData.push(newImage);
            }
          });
          return newData;
        });
        setPageLinks({ next: res.data.next, prev: res.data.previous });
        setImageDataLen(newImages.length);
        loaders(false, "heart");
      } else {
        setError("Failed to fetch data");
        loaders(false, "heart");
      }
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };

  // --------------------------
  // Data Normalization & Sorting
  // --------------------------
  // For free subscriptions, use flatShuffledImages (only show six random images)
  const normalizedImages = isFreeSubscription
    ? flatShuffledImages.map((img, idx) => ({
        type: "image",
        url: idx < 6 ? `${img.imageUrl}?auto=format&fit=crop` : freebg,
        user_name: img.user_name,
        generation_prompt: img.generation_prompt,
        Aititle: img.Aititle,
        upscale: img.upscale,
        restricted: idx >= 6,
        createdAt: img.created_at, // assuming created_at exists
      }))
    : filteredImageData.flatMap((item) => {
        if (!item.image_file_name || item.image_file_name.length === 0)
          return [];
        return item.image_file_name.map((imageUrl, idx) => ({
          type: "image",
          url: `${imageUrl}?auto=format&fit=crop`,
          user_name: item.user_name,
          generation_prompt: item.generation_prompt,
          Aititle: item.Aititle,
          upscale: item.upscale ? item.upscale[idx] : false,
          restricted: false,
          createdAt: item.created_at, // assuming created_at exists
        }));
      });

  // For paid subscriptions, normalize videos too (assuming video objects include created_at)
  const normalizedVideos = videoData.map((video) => ({
    type: "video",
    url: video.url,
    prompt: video.prompt,
    createdAt: video.created_at, // assuming created_at exists on video
  }));

  // For free users, we only show images (no videos)
  // For paid users, combine images and videos, and sort them by creation date (most recent first)
  const combinedData = isFreeSubscription
    ? normalizedImages
    : [...normalizedImages, ...normalizedVideos].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  // --------------------------
  // Render JSX
  // --------------------------
  return (
    <>
      <ToastContainer />
      <PageWrapper
        title={"Discover All Public AI Character Creations | Eromantic AI"}
        description={
          "Discover a variety of public AI character creations on Eromantic AI. Explore unique AI-generated characters with diverse designs, personalities, and stories"
        }
        keywords={
          "AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        }
      >
        <div className="mt-20 lg:px-10 2xl:px-20 px-2 pb-20 text-black font-josefin-sans">
          {bannerList.discover.length > 0 ? (
            <Carousel
              autoPlay
              interval={3000}
              infiniteLoop
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              transitionTime={500}
              className="rounded-xl overflow-hidden"
            >
              {bannerList.discover.map((banner, index) => (
                <div key={index} onClick={() => navigate(`/${banner.name}`)}>
                  <img src={banner.url} alt={`discover Banner ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          ) : (
            <p></p>
          )}

          <div className="flex justify-start lg:justify-center xl:justify-between flex-wrap items-center">
            <div className="m-0 mo:m-2 px-3 py-2 text-black w-full mo:w-1/2 flex items-center gap-2 bg-white rounded-full">
              <div style={{ paddingLeft: "5px" }}>
                <IoSearchOutline color="black" size={23} />
              </div>
              <input
                type="text"
                placeholder="Search anything"
                className="bg-white border-0 rounded-full w-full focus:outline-none text-xl"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          {/* Masonry Grid for Images and Videos (or just images for free users) */}
          <div className="p-0 md:p-8">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {combinedData.map((item, index) => (
                <div
                  key={index}
                  className={`relative group rounded-2xl overflow-hidden break-inside-avoid min-h-[130px] ${
                    isLoadingsingleimage ? "h-60" : ""
                  }`}
                >
                  {item.type === "image" && (
                    <>
                      {isLoadingsingleimage && (
                        <div className="absolute inset-0 flex justify-center items-center bg-[#2a2a2a] rounded-2xl">
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height="100%"
                            width="100%"
                            sx={{
                              borderRadius: "12px",
                              background:
                                "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                              backgroundSize: "200% 100%",
                            }}
                          />
                          <div className="absolute top-2 left-3 right-2 flex justify-between items-center">
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                              sx={{ background: "black" }}
                            />
                          </div>
                        </div>
                      )}
                      <img
                        loading="lazy"
                        className={`w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out group-hover:scale-105 group-hover:shadow-lg cursor-pointer ${
                          item.restricted ? "blur-sm pointer-events-none" : ""
                        }`}
                        src={item.restricted ? freebg : item.url}
                        alt={`gallery-item-${index}`}
                        onLoad={handleImageLoad}
                        onClick={() =>
                          !item.restricted && handleImagechatClick(item.url)
                        }
                      />
                      <div className="absolute top-0 left-0 right-0 rounded-2xl invisible group-hover:visible">
                        {item.upscale ? (
                          <div className="flex items-center justify-between h-12 px-2 py-1 bg-black bg-opacity-70 text-white rounded-t-2xl text-xs font-semibold transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300">
                            <Tooltip
                              title="Download"
                              slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                      { margin: "0px" },
                                  },
                                },
                              }}
                            >
                              <button
                                onClick={() => downloadImage(item.url)}
                                className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                              >
                                <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                              </button>
                            </Tooltip>
                            <div className="flex">
                              <i className="fa-solid text-yellow-500 fa-star"></i>
                              <p className="ml-1">UPSCALED</p>
                            </div>  
                          </div>
                        ) : (
                          <div className="absolute top-1 right-0 left-0 flex justify-between items-center px-4">
                            <Tooltip
                              title="Download"
                              slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                      { margin: "0px" },
                                  },
                                },
                              }}
                            >
                              <button
                                onClick={() => downloadImage(item.url)}
                                className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                              >
                                <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                              </button>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div className="absolute bottom-1 left-1 right-1 bg-black bg-opacity-50 transition-opacity duration-300 p-2 text-white border border-gray-400 rounded-[10px] invisible group-hover:visible">
                        <h2 className="text-red-200 text-xs sm:text-sm md:text-md lg:text-xl text-wrap rounded-lg py-0 mb-2">
                          @{item.user_name}
                        </h2>
                        <div className="flex items-center justify-between mb-1 gap-3">
                          <p className="text-xs sm:text-sm md:text-md lg:text-md text-start h-auto">
                            {item.generation_prompt
                              .split(" ")
                              .slice(0, 10)
                              .join(" ")}
                            {item.generation_prompt.split(" ").length > 10
                              ? "..."
                              : ""}
                          </p>
                          {item.Aititle && (
                            <div className="flex flex-col items-start">
                              <h2 className="text-xs sm:text-sm text-red-400">
                                MODEL
                              </h2>
                              <p className="text-white text-sm sm:text-base">
                                {item.Aititle}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {item.type === "video" && !isFreeSubscription && (
                    <>
                      <video
                        src={`${item.url}#t=0.1`}
                        muted
                        type="video/mp4"
                        autoPlay
                        preload="metadata"
                        loop
                        playsInline
                        className="w-full h-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out group-hover:scale-105 group-hover:shadow-lg cursor-pointer"
                      />
                      <div className="absolute top-0 left-0 right-0 rounded-2xl invisible group-hover:visible">
                        <div className="absolute top-1 right-0 left-0 flex justify-between items-center px-4">
                          <Tooltip
                            title="Download"
                            slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    { margin: "0px" },
                                },
                              },
                            }}
                          >
                            <button
                              onClick={() => downloadVideo(item.url)}
                              className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                            >
                              <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                            </button>
                          </Tooltip>
                        </div>
                        {item.prompt && (
                          <div className="absolute bottom-1 left-1 right-1 bg-black bg-opacity-50 transition-opacity duration-300 p-2 text-white border border-gray-400 rounded-[10px] invisible group-hover:visible">
                            <p className="text-xs sm:text-sm md:text-md lg:text-md text-start">
                              {item.prompt}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </Masonry>
          </div>
          {imageDataLen === 0 ? (
            <div className="flex-wrap lg:flex items-center justify-between p-10 rounded-lg shadow-lg bg-[#d5d5d5]">
              <div className="flex-wrap lg:flex gap-4">
                <div className="logo flex items-center justify-center">
                  <i className="fa-solid fa-heart-circle-plus text-red-600 text-4xl" />
                </div>
                <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                  <h2 className="text-gray-900 text-2xl font-semibold">
                    Create Your Personalized Characters
                  </h2>
                  <p className="text-gray-800 text-sm">
                    Explore the power of AI to design unique images and
                    characters tailored to your preferences.
                  </p>
                </div>
              </div>
              <div className="text-center lg:text-right">
                <button
                  onClick={() => navigate("/ai-porn-image-generator")}
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                >
                  Start Creating
                </button>
              </div>
            </div>
          ) : (
            <>
              {!loadmore && showMoreBtn && pageLinks.next && (
                <div className="flex items-center w-full justify-center">
                  <button
                    className="bg-red-800 w-[35%] sm:w-[20%] lg:w-[16%] text-white font-medium rounded-2xl py-2 px-4"
                    onClick={() => {
                      fetchImageData(pageLinks.next);
                    }}
                  >
                    <i className="fa-solid fa-angles-down mr-1"></i> Load More
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </PageWrapper>
      <Footer />

      {/* Fullscreen Modal for image view */}
      {isModalOpen && selectedImage && (
        <div
          ref={modalRef}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
        >
          <div className="relative w-[35rem] max-h-full p-4">
            <button
              onClick={closeModal}
              className="absolute top-8 right-8 text-white text-md w-8 h-8 bg-[#808080] p-[5px] font-bold rounded-full"
            >
              X
            </button>
            <img
              loading="lazy"
              className="max-w-full max-h-screen object-contain"
              src={selectedImage}
              alt="Fullscreen View"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(Discover);
