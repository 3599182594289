import React, { useState, cloneElement, useLayoutEffect, useRef, useEffect } from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../../frontend/components/Sidebar';
import Bottombar from '../../frontend/components/Bottombar';
import { useLocation } from 'react-router-dom';
import ScrolltoTop from '../../frontend/components/ScrolltoTop';
import UpgradeNow from '../components/UpgradeNow';

function LayoutFrontend({ children }) {
    const [errorisOpen, setErrorIsOpen] = useState(false);

    const myRef = useRef()


    const [isOpen, setIsOpen] = useState(false);
    const [showTrial, setShowTrial] = useState(true);

    const [activeTab, setActiveTab] = useState('Girlfriend');

    const toggleSidebar = () => {
        setIsOpen(prev => !prev);
    };

    const handleActiveTab = (data) => {
        setActiveTab(data);
    };

    const location = useLocation();
    const isAidreamGFchat = location.pathname.includes('AidreamGFchat');
    const isNewchat = location.pathname.includes('aichat');
    const isBlog = location.pathname.includes('singleBlog');
    useEffect(() => {
        setTimeout(() => {
            const user_type = localStorage.getItem("subscription_type")
            window.scrollTo(0, 0); // Scroll to top whenever the URL changes
            if (user_type === "free") {
                setErrorIsOpen(true)
            }
        }, 3000)
    }, [location]);

    const errorCloseModal = () => {
        setErrorIsOpen(false); // Close the modal
    };


    useLayoutEffect(() => {
        if (myRef.current) {
            myRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling
        }
    }, [myRef, activeTab]);

    const closeTrial = () => {
        setShowTrial(false);
    };

    return (
        <>
            <div ref={myRef}>
                <ScrolltoTop />
                <header>
                    <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} setActiveTab={handleActiveTab} closeTrial={showTrial} />
                </header>
                <div className='backgroundStyle'>
                    <div className="main-container h-[100vh] float-start w-full">
                        {/* Conditionally render the DashboardHeader */}
                        {!(isAidreamGFchat || isNewchat) && (
                            <DashboardHeader toggleSidebar={toggleSidebar} isOpen={isOpen} activeTab={activeTab} setActiveTab={handleActiveTab} closeTrial={closeTrial} />
                        )}

                        <div className={`ml-[70px] ${isNewchat ? '' : "h-full"} ${(isAidreamGFchat || isNewchat) ? 'mt-0' : (isBlog ? 'mt-[70px] ml-[60px]' : location.pathname === '/' ? 'mt-[125px]' : 'mt-[90px]')}`}>
                            {React.Children.map(children, child =>
                                cloneElement(child, { activeTab, handleActiveTab })
                            )}
                        </div>
                        <Bottombar toggleSidebar={toggleSidebar} isOpen={isOpen} setActiveTab={handleActiveTab} />
                    </div>
                </div>
            </div>
            {errorisOpen && (
                <UpgradeNow errorCloseModal={errorCloseModal} />
            )}
        </>
    );
}

export default LayoutFrontend;
