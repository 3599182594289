import React, { useState } from "react";
import UserApiClient from "../../../utilities/apiClients.js";
import { cloth_removal, goldE, info } from "../../../assets";
import { toast, ToastContainer } from "react-toastify";
import { IoSearchOutline } from "react-icons/io5";
import {
  bdsm,
  blowjob,
  boobs,
  boys_positions,
  cumshot,
  futanari,
  misc,
  options,
  penis,
  position,
  pussy,
  suggestions_video,
} from "../../../assets/data";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import UpgradeNow from "../../components/UpgradeNow.js";

const VideoPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedPrivate, setIsCheckedPrivate] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(true);
  const [svgselected, setsvgSelected] = useState("fourth");
  const [text, setText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCharacter, setSelectedCharacter] = useState("1 Girl");
  const [selectedFeature, setSelectedFeature] = useState("Hair Color");
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]); // To store selected suggestions
  const [isHovered, setIsHovered] = useState(false);
  const [errorisOpen, setErrorIsOpen] = useState(false);

  const errorCloseModal = () => setErrorIsOpen(false);

  const features = [
    { name: "Hair Color" },
    { name: "Hair Cut" },
    { name: "Wearing" },
    { name: "Body Type" },
    // { name: "Nationality" },
    { name: "Environment" },
    // { name: 'Style' },
    { name: "Effects" },
  ];

  const [hover, setHover] = useState({
    model: "",
  });
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckboxChangePrivate = () => {
    setIsCheckedPrivate(!isCheckedPrivate);
  };

  const handlesvgClick = (value) => {
    if (svgselected === value) {
      setsvgSelected("");
    } else {
      setsvgSelected(value);
    }
  };

  const imagestwo = [
    {
      src: "https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img,w_250/https://www.pnrao.com/wp-content/uploads/2023/06/dummy-user-female.jpg",
      title: "Extensions Coming Soon",
      trigerWord: "",
      Lora: "",
    },
    // { src: Futanari, title: 'Futanari', trigerWord: 'Futanari', Lora: '' },
  ];

  const imagesfive = [
    {
      src: "https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img,w_250/https://www.pnrao.com/wp-content/uploads/2023/06/dummy-user-female.jpg",
      title: "Extensions Coming Soon",
      trigerWord: "",
      Lora: "",
    },
  ];

  const imageseleven = [
    // {
    //   src: "https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img,w_250/https://www.pnrao.com/wp-content/uploads/2023/06/dummy-user-female.jpg",
    //   title: "Extensions Coming Soon",
    //   trigerWord: "",
    //   Lora: "",
    // },
    {
      src: cloth_removal,
      title: "Cloth Removal",
      trigerWord: "cloth_removal",
      Lora: "",
    },
  ];
  const imagesboythree = [
    {
      src: "https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img,w_250/https://www.pnrao.com/wp-content/uploads/2023/06/dummy-user-female.jpg",
      title: "Extensions Coming Soon",
      trigerWord: "",
      Lora: "",
    },
  ];
  const [selectedFeatures, setSelectedFeatures] = useState(options);

  const filteredBoyone = boys_positions.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredBoyTwo = penis.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredBoyThree = imagesboythree.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImages = boobs.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImagesTwo = imagestwo.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredImagesThree = cumshot.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesFour = pussy.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesFive = imagesfive.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesSeven = bdsm.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesEight = position.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesNine = futanari.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagesTen = blowjob.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImageseleven = imageseleven.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredImagestwelev = misc.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [nextButton, setNextButton] = useState(false);
  const choosefeature = (featureName, featureType) => {
    setSelectedFeatures((prev) => {
      const currentFeatures = prev[selectedCharacter]?.[featureType] || {};

      // Check if the feature is already selected
      if (currentFeatures === featureName) {
        const updatedCharacter = { ...prev[selectedCharacter] };
        setNextButton(false);
        delete updatedCharacter[featureType]; // Remove the feature type
        return {
          ...prev,
          [selectedCharacter]: updatedCharacter,
        };
      }
      return {
        ...prev,
        [selectedCharacter]: {
          ...prev[selectedCharacter],
          [featureType]: featureName,
        },
      };
    });
    if (currentFeatureIndex + 1 < features.length) {
      setNextButton(true);
    } else {
      setNextButton(false);
    }
  };
  const next = () => {
    const nextIndex = currentFeatureIndex + 1;
    if (nextIndex < features.length) {
      setSelectedFeature(features[nextIndex].name); // Set the next feature as selected
      setCurrentFeatureIndex(nextIndex); // Update current feature index
      setNextButton(false);
    } else {
      // Optionally, handle when all features have been selected
      console.log("All features have been selected");
    }
  };
  const handleChangeadvance = (e) => {
    setText(e.target.value);
  };
  const handleSuggestionClick = (line) => {
    // If the suggestion is already selected, remove it
    if (selectedSuggestions.includes(line)) {
      setSelectedSuggestions(
        selectedSuggestions.filter((item) => item !== line)
      );
      setText(text.replace(line, "")); // Remove from textarea
    } else {
      // Add the new suggestion to the list and textarea
      setSelectedSuggestions([...selectedSuggestions, line]);
      setText(text + " " + line); // Append to textarea
    }
  };

  const handleSelect = (triggerWord) => {
    if (selectedIndexes.includes(triggerWord)) {
      // Deselect the item if it's already selected
      setSelectedIndexes(
        selectedIndexes.filter((item) => item !== triggerWord)
      );
    } else {
      if (selectedIndexes.length < 1) {
        setSelectedIndexes([...selectedIndexes, triggerWord]);
      } else {
        const isMobile = window.innerWidth <= 768;
        toast.error("You can Select Maximum 1 extension at a time.", {
          position: isMobile ? "top-right" : "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  console.log(selectedIndexes, "SEleeeeeeeeee");

  const navigate = useNavigate();

  const generateVideo = async () => {
    const form_data = {};
    form_data["extensions"] = selectedIndexes.toString();
    form_data["upscale"] = isChecked;
    form_data["make_private"] = isCheckedPrivate;
    // form_data["generation_id"] = `ER_GenVid_${newDate.getDate()}_${newDate.getMonth()}_${newDate.getFullYear()}_${newDate.getTime()}`;

    if (!isAdvanced) {
      form_data["prompt"] = text;
    } else {
      Object.keys(options[selectedCharacter]).forEach((feature) => {
        const featureValue = selectedFeatures[selectedCharacter]?.[feature];
        form_data[feature.replaceAll(" ", "_").toLowerCase()] = featureValue
          ? typeof featureValue === "object"
            ? featureValue.name
            : featureValue
          : "Not Selected";
      });
      form_data["sex"] = selectedCharacter.includes("irl") ? "Women" : "Men";
    }

    try {
      const response = await UserApiClient.post(
        "api/video/hunyuan/",
        form_data,
        true
      );
      if (response.success) {
        await Swal.fire({
          icon: "success",
          text: "Your AI-generated video is currently being processed in the background! Thank you for your patience. Please note that as this is AI-generated based on your prompt, extensions, and selected features, the results may vary and could be unexpected.",
          timer: 4000,
        });
        console.log(response.data);
        navigate(`/dashboard/my-gallery?view=Videos&status=pending`, {
          state: { videoData: response.data },
        });
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="container">
        <div className="bg-[#afa4a4] rounded-lg">
          <div className="p-6">
            {/* Buttons to toggle content */}
            {!isAdvanced ? (
              <button
                onClick={() => setIsAdvanced(true)}
                className={`px-6 py-2 rounded-lg focus:outline-none transition duration-300 ${
                  !isAdvanced
                    ? "bg-white text-black"
                    : "bg-black text-[#ffb5b5]"
                }`}
              >
                Back to Normal Mode
              </button>
            ) : (
              <>
                <span className="text-md text-gray-700">
                  Advanced mode is designed for users skilled in crafting
                  effective prompts. To make the most of this feature, we
                  recommend browsing through the showcased section to find
                  examples that resonate with you and replicating those prompts.
                  Ensure you retain all other settings, including extensions, to
                  achieve your desired outcomes seamlessly.
                </span>
                <div className="flex mt-2 items-center space-x-4">
                  <button
                    onClick={() => setIsAdvanced(false)}
                    className={`px-6 py-2 rounded-lg focus:outline-none transition duration-300 ${
                      !isAdvanced
                        ? "bg-black text-white"
                        : "bg-[#a90d0d73] text-black"
                    }`}
                  >
                    Try Advanced Prompting{" "}
                    <i className="ml-2 fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </>
            )}
          </div>

          {isAdvanced ? (
            <>
              <div className="flex justify-center items-center gap-5 pt-5 md:pt-5 align-center">
                <p className="font-semibold text-2xl xl:text-3xl">
                  Select Character
                </p>
                <div className="relative">
                  {hover.character && (
                    <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                      Choose your character and bring their story to life.
                      Whether it's a bold boy or a graceful girl, the
                      possibilities are endless!
                    </span>
                  )}
                  <img
                    loading="lazy"
                    src={info}
                    alt="infoBlack"
                    className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                    onMouseEnter={() => setHover({ ...hover, character: true })}
                    onMouseLeave={() =>
                      setHover({ ...hover, character: false })
                    }
                  />
                </div>
              </div>
              <div className="flex justify-center">
                {options["Characters"].map((item, index) => (
                  <div
                    key={index}
                    className={`p-2 rounded-xl items-center flex flex-col justify-center space-y-4 m-4 ${
                      selectedCharacter === item.name
                        ? "bg-black"
                        : "bg-[#d1d1d1]"
                    }`}
                    onClick={() => {
                      setSelectedCharacter(item.name);
                      setSelectedFeature("Hair Color");
                    }}
                  >
                    {/* Image of the character */}
                    <img
                      loading="lazy"
                      src={item.src}
                      alt={item.name}
                      className="w-60 h-60 object-cover rounded-lg"
                    />

                    {/* Character Name */}
                    <p
                      className={`font-semibold hover:text-red-600 capitalize hover:cursor-pointer text-2xl ${
                        selectedCharacter === item.name
                          ? "text-white"
                          : "text-black"
                      }`}
                    >
                      {item.name}
                    </p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}

          <div className="flex flex-wrap p-6">
            {/* Left Section: Select Features */}
            {isAdvanced ? (
              <div className="w-full md:w-2/4 md:pr-4">
                {selectedCharacter && (
                  <div className="bg-[#83808052] shadow-xl rounded-lg p-2 mo:px-4">
                    <div className="flex justify-between items-center mb-6">
                      <p className="font-semibold text-2xl p-5 text-gray-800">
                        Select Features
                      </p>
                      <div className="relative">
                        {hover.feature && (
                          <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                            Unleash your creativity! Customize your character
                            with features like Hair Color, Body Type, and
                            Effects to bring your vision to life.
                          </span>
                        )}
                        <img
                          loading="lazy"
                          src={info}
                          alt="infoBlack"
                          className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                          onMouseEnter={() =>
                            setHover({ ...hover, feature: true })
                          }
                          onMouseLeave={() =>
                            setHover({ ...hover, feature: false })
                          }
                        />
                      </div>
                    </div>

                    <div className="bg-[#bbaeaf] p-4 rounded-lg shadow-sm flex flex-wrap gap-1 sm:gap-4">
                      {features.map((item, index) => (
                        <p
                          key={index}
                          className={`font-semibold text-md py-2 px-3 sm:px-6 rounded-lg cursor-pointer transition-transform duration-200 transform ${
                            selectedFeature === item.name
                              ? "bg-gray-800 text-white"
                              : "hover:bg-gray-300"
                          }`}
                          onClick={() => {
                            setSelectedFeature(item.name);
                            setCurrentFeatureIndex(index); // Set the current feature index on click
                          }}
                        >
                          {item.name}
                        </p>
                      ))}
                    </div>

                    {selectedFeature && (
                      <div className="mt-6 bg-[#b9b9b97a] p-4 md:p-6 rounded-lg shadow-sm ">
                        <div
                          className="p-4 md:p-6 h-[15rem] mo:h-full rounded-lg  flex justify-center flex-wrap gap-2 overflow-scroll"
                          style={{ scrollbarWidth: "none" }}
                        >
                          {options[selectedCharacter][selectedFeature].map(
                            (item, index) => (
                              <div
                                key={index}
                                className={`flex justify-center items-center h-10 border-2 border-gray-400 hover:border-gray-600 hover:scale-105 rounded-full p-2 cursor-pointer transition-all duration-300 ease-in-out transform ${
                                  selectedFeatures[selectedCharacter]?.[
                                    selectedFeature
                                  ] === item.name
                                    ? "bg-[#202936] text-white"
                                    : "bg-white"
                                }`}
                                onClick={() => {
                                  choosefeature(item.name, selectedFeature);
                                }}
                              >
                                <p
                                  className="font-semibold text-[14px] mo:text-[16px] text-center px-4"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {item.name}
                                </p>
                              </div>
                            )
                          )}

                          {["Hair Color", "Wearing", "Environment"].includes(
                            selectedFeature
                          ) && (
                            <div className="flex items-center justify-between ">
                              <div className="">
                                {/* <label
                                      htmlFor="custom"
                                      className="text-sm font-medium text-gray-700 capitalize me-1"
                                    >
                                      Enter Custom {selectedFeature} Here
                                    </label> */}
                                <input
                                  id="custom"
                                  type="text"
                                  placeholder={`Enter custom ${selectedFeature.toLowerCase()} here`}
                                  value={
                                    typeof selectedFeatures[
                                      selectedCharacter
                                    ]?.[selectedFeature] === "string"
                                      ? selectedFeatures[selectedCharacter][
                                          selectedFeature
                                        ]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setNextButton(true);
                                    setSelectedFeatures((prev) => ({
                                      ...prev,
                                      [selectedCharacter]: {
                                        ...prev[selectedCharacter],
                                        [selectedFeature]: e.target.value,
                                      },
                                    }));
                                  }}
                                  className="border border-gray-300 rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 transition-all shadow-sm capitalize"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Next button */}
                        {nextButton === true && (
                          <div className="flex justify-end">
                            <button
                              className="bg-red-500 hover:bg-red-600 text-white px-4 py-1 rounded-lg shadow-md transition-all duration-200 focus:outline-none focus:ring-4 focus:ring-red-300"
                              onClick={next}
                            >
                              <i className="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {selectedCharacter && (
                      <div className="bg-[#b9b9b97a] p-1 mo:p-4 rounded-lg shadow-sm mt-6">
                        <h3 className="font-semibold text-xl text-center text-gray-800">
                          Selected Features
                        </h3>
                        <div className="flex flex-wrap justify-center gap-2 mo:gap-4">
                          {Object.keys(options[selectedCharacter]).map(
                            (feature, index) => (
                              <div
                                key={index}
                                className="flex flex-col w-auto sm:w-[150px] bg-white rounded-lg shadow-md p-2 mo:p-4 border-l-4 border-gray-400 transition-transform duration-300 ease-in-out transform hover:scale-105"
                              >
                                <span className="font-semibold text-[14px] mo:text-[16px] text-gray-700">
                                  {feature}
                                </span>
                                <span className="text-sm text-gray-500 capitalize">
                                  {selectedFeatures[selectedCharacter]?.[
                                    feature
                                  ]
                                    ? typeof selectedFeatures[
                                        selectedCharacter
                                      ][feature] === "object"
                                      ? selectedFeatures[selectedCharacter][
                                          feature
                                        ].name
                                      : selectedFeatures[selectedCharacter][
                                          feature
                                        ]
                                    : "Not Selected"}{" "}
                                  {/* Placeholder for deselected features */}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full md:w-2/4 md:pr-4">
                <div className="bg-[#83808052] shadow-xl rounded-lg p-6">
                  <p className="font-semibold text-3xl pb-5 text-gray-800">
                    Write Your Custom Prompt Here
                  </p>
                  {/* Textarea */}
                  <textarea
                    value={text}
                    onChange={handleChangeadvance}
                    rows="4"
                    className="w-full bg-[#b9b9b97a] text-black p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 placeholder-[#5e5e5e]"
                    placeholder="Write something..."
                  ></textarea>

                  {/* Suggestions Box */}
                  <div className="mt-4 p-4 bg-[#b9b9b97a] rounded-lg shadow-md">
                    <h3 className="font-semibold text-gray-700">
                      Suggestions:
                    </h3>
                    <ul className="list-disc pl-5 space-y-2">
                      {suggestions_video.map((suggestion) => (
                        <li
                          key={suggestion.id}
                          className={`cursor-pointer p-2 rounded-lg transition-colors ${
                            selectedSuggestions.includes(suggestion.line)
                              ? "bg-red-200 text-red-800"
                              : "hover:bg-gray-200"
                          }`}
                          onClick={() => handleSuggestionClick(suggestion.line)}
                        >
                          {suggestion.line}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {/* Right Section: Extensions */}
            <div className="w-full md:w-2/4 md:pl-4 mt-8 md:mt-0">
              <div className="bg-[#83808052] shadow-xl rounded-lg p-2 mo:px-4">
                <div className="flex justify-between items-center mb-6">
                  <p className="font-semibold text-2xl p-5 text-gray-800">
                    Extensions{" "}
                    <span className="mt-0 text-base text-gray-600">
                      (Addon 0.5 Token)
                    </span>
                  </p>
                  <div className="relative">
                    {hover.extension && (
                      <span className="absolute w-60 -left-32 -translate-x-1/2  bg-[#8a8a8a] text-white text-sm p-2 rounded shadow-lg z-10">
                        (Realistic Extensions Only work with Realistic & Anime
                        Extensions Only work with Anime) Transform your
                        creations with a diverse range of features, from elegant
                        poses to dynamic expressions. Highlight captivating
                        details, explore imaginative themes, and bring your
                        vision to life with stunning realism and creativity.
                      </span>
                    )}
                    <img
                      loading="lazy"
                      src={info}
                      alt="infoBlack"
                      className="h-4 md:h-6 cursor-pointer transition-transform duration-200 hover:scale-110"
                      onMouseEnter={() =>
                        setHover({ ...hover, extension: true })
                      }
                      onMouseLeave={() =>
                        setHover({ ...hover, extension: false })
                      }
                    />
                  </div>
                </div>
                <div className="px-5 flex w-[85%] mo:w-[60%] items-center border rounded-full ml-6 bg-gray-100">
                  <IoSearchOutline color="black" size={23} />
                  <input
                    type="text"
                    placeholder="Search Extension"
                    id="searchInput"
                    className="focus:border-0 focus:outline-none active:border-0 active:outline-none !ring-0 w-full bg-transparent text-lg text-black p-2 border-0 rounded-full ml-2"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                  />
                </div>

                {!isAdvanced ? (
                  <>
                    <div className="w-full flex justify-center mt-2">
                      {options["Characters"].map((item, index) => (
                        <div
                          key={index}
                          className={`px-4 py-2 items-center flex flex-col justify-center rounded-lg mx-1 ${
                            selectedCharacter === item.name
                              ? "bg-black"
                              : "bg-[#d1d1d1]"
                          }`}
                          onClick={() => {
                            setSelectedCharacter(item.name);
                            setSelectedFeature("Hair Color");
                          }}
                        >
                          {/* Character Name */}
                          <p
                            className={`hover:text-red-600 capitalize hover:cursor-pointer text-lg  ${
                              selectedCharacter === item.name
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            {item.name === "1 Girl" ? "Girls" : "Boys"}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    height: "32.7rem",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    scrollbarColor: "#99000b #f5b0b069",
                    borderRadius: "0.75rem",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                  className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 mt-6 px-4"
                >
                  {selectedCharacter === "1 Girl" ? (
                    <>
                      {/* Boots section */}
                      <div className="text-center mb-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Boobs
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImages.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Misc */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Misc
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagestwelev.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Clothing */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Clothing
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImageseleven.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Assa section */}
                      {/* <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Ass
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagesTwo.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div> */}

                      {/* Cum section */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Cum
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagesThree.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Pussy section */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Pussy
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagesFour.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Feet section */}
                      {/* <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Feet
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagesFive.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div> */}

                      {/* BDSM section */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          BDSM
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagesSeven.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Penetration section */}
                      <div className="text-center my-6">
                        <div className="bg-gray-200 rounded-full w-32 my-4 p-2 font-semibold text-lg text-gray-600">
                          Penetration
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagesEight.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Futanari section */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Futanari
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagesNine.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Blow section */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Blowjob
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredImagesTen.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* BodyBuilder section */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Position
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredBoyone.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />

                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Penis section */}
                      <div className="text-center mb-6">
                        <div className="bg-gray-200 rounded-full w-32 my-4 p-2 font-semibold text-lg text-gray-600">
                          Penis
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredBoyTwo.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            } ${
                              item.title === "Extensions Coming Soon"
                                ? "pointer-events-none"
                                : ""
                            } `} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg `} // Make image semi-transparent if type doesn't match modaltype
                            />
                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Bondage section */}
                      <div className="text-center my-6 px-2">
                        <div className="bg-gray-200 rounded-full w-24 my-4 p-2 font-semibold text-md text-gray-600">
                          Bondage
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-center gap-6 py-4">
                        {filteredBoyThree.map((item, index) => (
                          <div
                            key={index}
                            className={`relative w-[86px] h-[86px] sm:w-[132px] sm:h-[144px] xl:w-[132px] xl:h-[144px] cursor-pointer rounded-lg overflow-hidden transition-transform duration-300 ease-in-out transform ${
                              selectedIndexes.includes(item.trigerWord)
                                ? "border-4 border-red-600"
                                : ""
                            }`} // Make the div unclickable if type doesn't match modaltype
                            onClick={() => handleSelect(item.trigerWord)}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className={`w-full h-full object-cover cursor-pointer rounded-lg`} // Make image semi-transparent if type doesn't match modaltype
                            />
                            <div
                              className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2 text-sm sm:text-md xl:text-md sm:text-md rounded-b-lg ${
                                selectedIndexes.includes(item.trigerWord)
                                  ? "bg-red-600"
                                  : ""
                              }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-wrap items-start justify-between pt-20 gap-2 md:gap-0">
          <div className="flex flex-col gap-5 lg:gap-12 text-black font-josefin-sans">
            <div className="flex relative  gap-5 md:mb-3 lg:mb-0">
              <p className="text-base capitalize md:text-[15px] lg:text-[20px] xl:text-[25px] font-semibold text-gray-800">
                Do you want to Upscale Videos{" "}
                <p className="flex md:text-[8px] lg:text-[12px] xl:text-[16px] mt-2 items-center">
                  (Addon 1-Token
                  {"  "}
                  <img
                    loading="lazy"
                    className="w-5 h-5 ms-1"
                    src={goldE}
                    alt="goldE"
                  />
                  )
                </p>
              </p>

              {/* Stylish toggle button */}
              <button
                onClick={handleCheckboxChange}
                className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${
                  isChecked ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                }`}
              >
                <span
                  className={`absolute left-1 top-1 transition-all duration-300 transform ${
                    isChecked
                      ? "translate-x-8 bg-white"
                      : "translate-x-0 bg-white"
                  }`}
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                ></span>
              </button>

              {/* Info Tooltip */}
              <div
                className=""
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  loading="lazy"
                  src={info}
                  alt="info"
                  className="h-4 md:h-6 mt-1 cursor-pointer transition-transform duration-200 hover:scale-110"
                />
                {isHovered && (
                  <span className="absolute bg-[#8a8a8a] text-white text-sm top-7 right-0 p-2 shadow-lg rounded max-w-xs w-auto z-10">
                    Elevate your videos with AI-powered upscaling. Increase
                    resolution for sharper, more detailed visuals, perfect for
                    larger displays.
                  </span>
                )}
              </div>
            </div>

            <div className="flex items-center gap-5 md:mb-3 lg:mb-0">
              <p className="text-base md:text-xl lg:text-2xl xl:text-3xl  font-semibold text-gray-800">
                Make Private
              </p>
              <button
                onClick={handleCheckboxChangePrivate}
                className={`relative inline-flex items-center h-[2rem] w-[4rem] rounded-full transition-all duration-300 ${
                  isCheckedPrivate ? "bg-[#ab1e1eb5]" : "bg-gray-300"
                }`}
              >
                {/* OFF text */}
                <span
                  className={`absolute z-10 left-2 text-black transition-all duration-300 ${
                    isCheckedPrivate ? "opacity-0" : "opacity-100"
                  }`}
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                ></span>

                {/* ON text */}
                <span
                  className={`absolute z-10 right-1 text-black font-bold transition-all duration-300 ${
                    isCheckedPrivate ? "opacity-100" : "opacity-0"
                  }`}
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                ></span>

                <span
                  className={`absolute left-1 top-1 transition-all duration-300 transform ${
                    isCheckedPrivate
                      ? "translate-x-8 bg-white"
                      : "translate-x-0 bg-white"
                  }`}
                  style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button
            onClick={() => generateVideo()}
            className=" w-full mo:w-2/3 h-[80px] mt-5 rounded-xl text-xl lg:text-3xl bg-gradient-to-r from-[#99111f] via-[#d43e44] to-[#ff5c6c] py-4 text-center flex items-center gap-5 justify-center relative overflow-hidden group shadow-xl hover:shadow-2xl transition-all duration-500 ease-out"
          >
            <span className="relative z-10 text-white font-bold">
              Generate {7.5 + (isChecked ? 1 : 0) + (selectedIndexes.length===1 ? 0.5 : 0)}
            </span>
            {/* Glowing Background Effect */}
            <span className="absolute inset-0 bg-gradient-to-r from-[#e63d3d] to-[#ff5c6c] opacity-0 group-hover:opacity-80 animate-glow-background"></span>

            {/* Glowing Icon Animation */}
            <img
              loading="lazy"
              className="w-12 transition-transform duration-300 group-hover:scale-[1.3] group-hover:rotate-0 group-hover:glow-icon"
              src={goldE}
              alt="goldE"
            />

            {/* Glowing Border and Pulse Effect */}
            <span className="absolute inset-0 rounded-xl border-4 border-transparent group-hover:border-[#99111f] opacity-0 group-hover:opacity-100 animate-glow-border"></span>
          </button>
        </div>
      </div>

      {errorisOpen && <UpgradeNow errorCloseModal={errorCloseModal} />}
    </>
  );
};

export default VideoPage;
