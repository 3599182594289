import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// A utility function to set meta tags
const updateMetaTag = (tagName, content, attr = "name") => {
  const metaTag = document.querySelector(`meta[${attr}="${tagName}"]`);
  if (metaTag) {
    metaTag.setAttribute("content", content);
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.setAttribute(attr, tagName);
    newMetaTag.setAttribute("content", content);
    document.head.appendChild(newMetaTag);
  }
};

// A utility function to update canonical URL
const updateCanonicalLink = (href) => {
  const linkCanonical = document.querySelector('link[rel="canonical"]');
  if (linkCanonical) {
    linkCanonical.setAttribute("href", href);
  } else {
    const linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "canonical");
    linkTag.setAttribute("href", href);
    document.head.appendChild(linkTag);
  }
};

// MetaTags component to manage SEO-related tags
const MetaTags = ({ title, description, canonicalUrl, keywords }) => {
  const location = useLocation();

  useEffect(() => {
    // Update the title dynamically
    if (title) {
      document.title = title;
    }

    // Update meta description dynamically
    updateMetaTag("description", description);

    // Update meta keywords dynamically
    updateMetaTag("keywords", keywords);

    // Update canonical URL dynamically
    updateCanonicalLink(canonicalUrl || window.location.href);

  }, [title, description, canonicalUrl, keywords, location]);

  return null; // This component doesn't render anything
};

const PageWrapper = ({
  title,
  description,
  canonicalUrl,
  children,
  keywords,
}) => {
  return (
    <div>
      <MetaTags
        title={title}
        description={description}
        canonicalUrl={canonicalUrl}
        keywords={keywords}
      />
      {children} {/* Render the page content */}
    </div>
  );
};

export default PageWrapper;
