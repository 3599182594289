import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import UserApiClient from "../../utilities/apiClients";
import { BaseApiUrl } from "../../utilities/enviroment";
import DiscoverImagesBox from "./DiscoverImagesBox";
import ScrolltoTop from "../../frontend/components/ScrolltoTop";
import CircularWithValueLabel from "../dashboardPages/CreateDreamGF/CircularWithValueLabel";

const ViewGeneratedImage = () => {

    const query = new URLSearchParams(useLocation().search);
    const { id } = useParams();
    const imgIndex = query.get("imgIndex");
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    const [generatedImage, setGeneratedImage] = useState("");
    const [generatedUser, setGeneratedUser] = useState("");
    const [likesStatus, setLikesStatus] = useState(false);
    const [currentLoadingImageId, setCurrentLoadingImageId] = useState(null);

    const BASE_URL = BaseApiUrl;

    useEffect(() => {
        const fetchImageData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}api/v1/get_generated_image/${id}/`);
                setGeneratedImage(response.data.image_file_name[imgIndex]);
                setGeneratedUser(response.data);
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }, 10);
            } catch (error) {
                Swal.fire(
                    error.response?.data?.msg || 'Our Servers are busy!',
                    'Please wait a moment and try again.',
                    'warning',
                );
            }
        };
        fetchImageData();
    }, [BASE_URL, id, imgIndex]);

    const toggleLike = async (generation_id,generatedImage) => {
        const u_id = localStorage.getItem("u_id");
        const payload = { generation_id,generatedImage, u_id };

        try {
            const authToken = localStorage.getItem("token");
            const response = await axios.post(`${BASE_URL}api/v1/public/image-like/`, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response.status === 200) {
                setLikesStatus(response.data.message);
            }
        } catch (error) {
            console.error("Error liking image:", error);
        }
    };

    const upscaleImage = async (generation_id, image_file_name) => {
        setCurrentLoadingImageId(image_file_name);
        setLoading(true);

        try {
            const response = await UserApiClient.post("api/v1/image/upscale/", {
                generation_id,
                image_file_name,
            }, true, false, false);

            if (response.success) {
                Swal.fire("Image upscaled successfully!", "", "success");
                // console.log(response.data)
                setGeneratedImage(response.data.images)
                setGeneratedUser((prevUser) => {
                    const updatedUpscale = [...prevUser.upscale];
                    updatedUpscale[imgIndex] = true; // Mark the image as upscaled
                    return { ...prevUser, upscale: updatedUpscale };
                });
            } else {
                Swal.fire("Failed to upscale the image. Try again.", "", "error");
            }
        } catch (error) {
            Swal.fire("Error occurred while upscaling the image.", "", "error");
        } finally {
            setLoading(false);
            setCurrentLoadingImageId(null);
        }
    };

    const downloadImage = async () => {
        // setLoading(true);
        try {
            const response = await fetch(generatedImage, { cache: "no-cache" });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.download = `generated-image_${Date.now()}.png`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            Swal.fire("Failed to download the image. Try again.", "", "warning");
        } finally {
            // setLoading(false);
        }
    };

    const generation_id = generatedUser.generation_id

    const deleteImage = async () => {
        try {
            const res = await UserApiClient.delete("api/v1/image/delete/", { img_url: generatedImage, generation_id });
            if (res.success) {
                Swal.fire("Success!", "Image deleted successfully.", "success").then(() => {
                    navigate("/dashboard/my-gallery"); // Redirect to dashboard
                });
            }
        } catch (error) {
            // Handle any error
            console.error("Error deleting image:", error);
            Swal.fire("Failed to delete the image.", "", "error");

        }
    };

    const isImageLoading = generatedImage === currentLoadingImageId;

    return (
        <>
            <ScrolltoTop />
            <div className="flex items-center justify-center px-4 md:px-20">
                <div className="max-w-6xl w-full bg-[#cfcfcf40] rounded-xl shadow-2xl  p-8">
                    <div className="grid md:grid-cols-2 gap-6">
                        {/* Image Section */}
                        <div className="relative rounded-xl flex justify-center items-center">
                            <div className="rounded-xl overflow-hidden">
                                {isImageLoading && (
                                    <div className="absolute inset-0 flex items-center justify-center z-10">
                                        {/* <div className="loader border-t-4 border-purple-500 rounded-full w-12 h-12"></div> */}
                                        <CircularWithValueLabel isLoadingAIResponse={isImageLoading} loadingText="loading" percentageShow={true} />
                                    </div>
                                )}
                                <img
                                    src={generatedImage}
                                    loading="lazy"
                                    alt="Generated Artwork"
                                    className={`w-full max-h-[70vh] rounded-xl object-contain transition-all duration-300 ${loading ? "blur-md opacity-50" : ""
                                        }`}
                                />
                            </div>
                        </div>

                        {/* Details Section */}
                        <div className="flex flex-col gap-6">
                            <p className="text-[#99000B] font-semibold text-base md:text-lg xl:text-xl">
                                By @{generatedUser?.user_name || "Unknown"}
                            </p>

                            <div className="flex flex-wrap gap-4">
                                {likesStatus === "Like added" ? (
                                    <button
                                        className="px-4 py-2 rounded-lg shadow-md bg-red-500 text-white"
                                        onClick={() => toggleLike(generatedUser.generation_id,generatedImage)}
                                    >
                                        <i className="fas fa-heart"></i> Liked
                                    </button>
                                ) : (
                                    <button
                                        className="px-4 py-2 rounded-lg shadow-md bg-gray-200 text-gray-600"
                                        onClick={() => toggleLike(generatedUser.generation_id,generatedImage)}
                                    >
                                        <i className="far fa-heart"></i> Like
                                    </button>
                                )}

                                {generatedUser?.upscale && Array.isArray(generatedUser.upscale) && imgIndex < generatedUser.upscale.length ? (
                                    generatedUser.upscale[imgIndex] === true ? (
                                        <button
                                            className="px-4 py-2 rounded-lg shadow-md bg-indigo-500 text-white hover:bg-indigo-600"
                                            disabled
                                        >
                                            Upscaled
                                        </button>
                                    ) : (
                                        <button
                                            className="px-4 py-2 rounded-lg shadow-md bg-indigo-500 text-white hover:bg-indigo-600"
                                            onClick={() => upscaleImage(generatedUser.generation_id, generatedImage)}
                                        // disabled={isImageLoading}
                                        >
                                            Upscale
                                        </button>
                                    )
                                ) : null}

                                <button
                                    className="px-4 py-2 rounded-lg shadow-md bg-gray-800 text-white hover:bg-gray-700"
                                    onClick={downloadImage}
                                >
                                    Download
                                </button>
                                <button
                                    className="px-4 py-2 rounded-lg shadow-md bg-red-600 text-white hover:bg-red-700"
                                    onClick={deleteImage}
                                >
                                    <i className="fas fa-trash"></i> Delete
                                </button>
                            </div>

                            <div className="flex flex-col gap-4 overflow-y-auto h-[500px]" style={{ scrollBehavior: "smooth", scrollbarWidth: "thin" }}>
                                <div>
                                    <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">PROMPT:</p>
                                    <p className="text-base md:text-lg xl:text-xl capitalize">{generatedUser?.generation_prompt || "N/A"}</p>
                                </div>
                                <div>
                                    <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">CREATED AT:</p>
                                    <p className="text-base md:text-lg xl:text-xl capitalize">
                                        {generatedUser?.created_at?.split("T")[0] || "Date not available"}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">AI TYPE:</p>
                                    <p className="text-base md:text-lg xl:text-xl capitalize">{generatedUser?.make_ai_listen || "N/A"}</p>
                                </div>
                                <div>
                                    <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">NATIONALITY:</p>
                                    <p className="text-base md:text-lg xl:text-xl capitalize">{generatedUser?.nationality || "N/A"}</p>
                                </div>
                                <div>
                                    <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">EXTENSIONS:</p>
                                    <p className="text-base md:text-lg xl:text-xl capitalize">{generatedUser?.extensions || "N/A"}</p>
                                </div>

                                <div>
                                    <p className="text-[#99000B] font-semibold text-sm md:text-base xl:text-lg">UPSCALE</p>
                                    <p className="text-base md:text-lg xl:text-xl capitalize">
                                        {generatedUser !== "UNKNOWN" &&
                                            Array.isArray(generatedUser?.upscale) &&
                                            imgIndex !== null &&
                                            imgIndex < generatedUser.upscale.length ? (
                                            generatedUser.upscale[imgIndex] === true ? "Upscaled" : "Not Upscaled"
                                        ) : (
                                            "Upscale info unavailable"
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 mb-40">
                <DiscoverImagesBox />
            </div>
            {/* <Footer/> */}
        </>
    );
};

export default ViewGeneratedImage;
