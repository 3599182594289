import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import UserApiClient from "../../utilities/apiClients";
import CircularWithValueLabel from "../dashboardPages/CreateDreamGF/CircularWithValueLabel";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Masonry from "react-masonry-css";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast, ToastContainer } from "react-toastify";
import { Skeleton } from "@mui/material";

const GenerationVideosBox = React.memo(({ updateView }) => {
  const ref = useRef();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const activeTab = parseInt(searchParams.get("view")) || 1;
  const [videoData, setVideoData] = useState([]);
  const [statusState, setStatusState] = useState("completed");
  const videoDataPending = location.state?.videoData;
  const navigate = useNavigate();
  const [isLoadingsinglevideo, setIsLoadingsinglevideo] = useState(true);

  // Use onLoadedData event for video loading instead of onLoad
  const handleVideoLoad = () => {
    setTimeout(() => {
      setIsLoadingsinglevideo(false);
    }, 1000);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const statusInfo = {
    1: "completed",
    2: "pending",
    3: "failed",
  };

  const getAllGeneratedVideos = async () => {
    try {
      const response = await UserApiClient.get(
        `api/video/hunyuan/?status=${statusState}`,
        {},
        true
      );
      if (response?.data) {
        setVideoData(response.data);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const checkStatus = async () => {
    // if (!videoDataPending?.message?.prompt_id) return;

    try {
      const response = await UserApiClient.get(
        `api/video/video-status/${videoDataPending.message.prompt_id}/`,
        {},
        true
      );
      if (response.success) {
        handleTabClick(1, true);
      } else {
        handleTabClick(3, true);
      }
    } catch (error) {
      console.error("Error checking video status:", error);
    }
  };

  // Handle tab clicks, updating both local state and the URL parameter via updateView
  const handleTabClick = (tabNumber, change = false) => {
    if (statusState !== statusInfo[tabNumber] || change) {
      const newStatus = statusInfo[tabNumber];
      setStatusState(newStatus);
      updateView("Videos", newStatus);
    }
  };

  // Update statusState when searchParams change
  useEffect(() => {
    const status = searchParams.get("status") || "completed";
    setStatusState(status);
  }, [searchParams]);

  // Fetch videos whenever the statusState changes
  useEffect(() => {
    getAllGeneratedVideos();
  }, [statusState]);

  // Check video status if there's pending video data
  useEffect(() => {
    checkStatus();
  }, []);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  const handleDelete = async (id) => {
    try {
      const response = await UserApiClient.delete(
        `api/video/hunyuan/${id}/`,
        {},
        true
      );
      console.log(response);
      if (response.success) {
        setVideoData(videoData.filter((video) => video.prompt_id !== id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadVideo = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch the video");
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `generated_video_${Date.now()}.mp4`;

      // Perform the download action
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);

      // Show success toast only after download is triggered
      toast.success("Downloading...", {
        position: "top-left",
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        autoClose: 2000,
        pauseOnHover: false,
        draggable: false,
        theme: "colored",
      });
    } catch (error) {
      console.error("Failed to download the video:", error);
    }
  };

  return (
    <div ref={ref} className="p-5">
      <ToastContainer />
      <div className="flex flex-wrap justify-center lg:justify-between items-center w-full mb-4">
        <div className="flex flex-wrap space-x-4">
          {Object.entries(statusInfo).map(([key, status]) => (
            <button
              key={key}
              onClick={() => handleTabClick(parseInt(key))}
              className={`px-4 py-2 m-2 font-semibold text-lg rounded-lg transition-colors duration-300 ${
                statusState === status
                  ? "bg-black text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {videoData.length > 0 ? (
          videoData.map((info, index) => (
            <div key={index} className="w-full relative group overflow-hidden">
              {info.status === "completed" && info.url ? (
                <div className="w-full relative overflow-hidden rounded-2xl">
                  {isLoadingsinglevideo && (
                    <div className="absolute inset-0 flex justify-center items-center bg-[#2a2a2a] rounded-2xl">
                      {/* Corrected Skeleton with Shimmer Animation */}
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="100%"
                        width="100%"
                        sx={{
                          borderRadius: "12px",
                          background:
                            "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                          backgroundSize: "200% 100%",
                        }}
                      />
                    </div>
                  )}
                  <video
                    className="h-auto w-full rounded-2xl object-cover object-center p-2 transition-transform duration-300 ease-in-out group-hover:scale-105 group-hover:shadow-lg cursor-pointer"
                    src={`${info.url}#t=0.1`}
                    muted
                    type="video/mp4"
                    autoPlay
                    preload="metadata"
                    loop
                    playsInline
                    onLoadedData={handleVideoLoad}
                    // onClick={() =>
                    //   navigate(
                    //     `/dashboard/generatedVideo/${info.prompt_id}?id=${info.id}`
                    //   )
                    // }
                  />
                </div>
              ) : (
                <div className="relative w-full h-auto">
                  <video
                    className="w-full h-auto rounded-lg shadow-lg"
                    src=""
                    controls
                  ></video>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <CircularWithValueLabel
                      isLoadingAIResponse={true}
                      loadingText="Loading"
                      percentageShow={false}
                    />
                  </div>
                </div>
              )}

              {/* Hover Prompt - Scales with Parent */}
              <div className="absolute rounded-b-2xl bottom-0 left-1 right-1 bg-black bg-opacity-70 opacity-0 p-4 cursor-pointer text-white transition-all duration-200 ease-in-out group-hover:opacity-100 group-hover:scale-105">
                <p
                  className="text-sm capitalize truncate sm:text-sm md:text-md transition-all duration-200 cursor-pointer"
                  title={
                    info.prompt ||
                    `1 ${info.sex || ""} ${info.hair_color || ""} ${
                      info.hair_cut || ""
                    } ${info.extensions || ""}`
                  }
                >
                  {info.prompt ||
                    `1 ${info.sex || ""} ${info.hair_color || ""} ${
                      info.hair_cut || ""
                    } ${info.extensions || ""}`}
                </p>
              </div>

              {info.upscale ? (
                <div className="absolute top-2 right-2 left-2 opacity-0 transition-opacity duration-200 group-hover:opacity-100 ">
                  <div className="absolute -top-2 -right-2 -left-2 flex items-center justify-center h-14 px-2 py-1 bg-black bg-opacity-70 text-white rounded-t-2xl text-xs font-semibold transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300">
                    <i className="fa-solid text-yellow-500 fa-star"></i>
                    <p className="ml-1">UPSCALED</p>
                  </div>
                  <div className="flex justify-between items-center relative z-50">
                    <Tooltip
                      title="Delete"
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                              {
                                margin: "0px",
                              },
                          },
                        },
                      }}
                    >
                      <button
                        onClick={() => handleDelete(info.prompt_id)}
                        className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                      >
                        <i className="fa-solid fa-trash-can text-white"></i>
                      </button>
                    </Tooltip>
                    <Tooltip
                      title="Download"
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                              {
                                margin: "0px",
                              },
                          },
                        },
                      }}
                    >
                      <button
                        onClick={() => downloadVideo(info.url)}
                        className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                      >
                        <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className="absolute top-2 right-2 left-2 opacity-0 transition-opacity duration-200 group-hover:opacity-100 flex justify-between items-center">
                  <div className="flex gap-1">
                    <Tooltip
                      title="Delete"
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                              {
                                margin: "0px",
                              },
                          },
                        },
                      }}
                    >
                      <button
                        onClick={() => handleDelete(info.prompt_id)}
                        className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                      >
                        <i className="fa-solid fa-trash-can text-white"></i>
                      </button>
                    </Tooltip>
                  </div>

                  <Tooltip
                    title="Download"
                    slotProps={{
                      popper: {
                        sx: {
                          [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                            {
                              margin: "0px",
                            },
                        },
                      },
                    }}
                  >
                    <button
                      onClick={() => downloadVideo(info.url)}
                      className="flex items-center justify-center bg-red-700 bg-opacity-90 h-10 w-10 rounded-full p-2 border-2 duration-300 border-red-600 hover:border-red-500"
                    >
                      <i className="fa-solid text-white fa-lg fa-cloud-arrow-down"></i>
                    </button>
                  </Tooltip>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600 mt-4">No videos available.</p>
        )}
      </Masonry>
    </div>
  );
});

export default GenerationVideosBox;
