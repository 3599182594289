import React, { useState, useRef, useEffect } from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaUserLarge } from 'react-icons/fa6';
import UserApiClient from '../../utilities/apiClients';
import { useDispatch } from 'react-redux';
import { loginSuccess, logout } from '../../redux/actions';
import { BaseApiUrl } from '../../utilities/enviroment';

const Header = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [User, setUser] = useState({});
    const popupRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch user data from the API
        const fetchUserData = async () => {
            UserApiClient.post('api/v1/user/', {}, true).then(async res => {
                if (res.success) {
                    setUser(res.data);
                    dispatch(loginSuccess(res.data));
                } else {
                    dispatch(logout());
                }
            })

        };

        fetchUserData();
    }, []);


    const handleLogout = async () => {
        // await UserApiClient.post('api/logout', {}, true).then(async res => {
        //     if (res.success) {
                dispatch(logout());
                // Clear token from local storage
                localStorage.removeItem('authToken');
                localStorage.removeItem("hasVisited");
                navigate('/admin');
        //     }
        // })
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsPopupVisible(false);
        }
    };

    useEffect(() => {
        if (isPopupVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPopupVisible]);

    return (
        <>
            <div className='bg-white flex justify-between items-center pt-8 pb-3 md:ps-48 lg:ps-72 w-full font-josefin-sans fixed top-0 z-50'>
                {/* <div className='bg-white ms-7 text-lg px-2 py-1 border-2 border-zinc-300 rounded-lg flex justify-between items-center lg:w-[500px]'>
                    <span className='flex items-center'>
                        <IoSearchOutline color='#9CA3C1' size={23} className='me-3' />
                        <input type="text" className='focus:outline-none md:w-40 lg:w-80' placeholder='Search your page...' />
                    </span>
                    <button className='rounded-tr-lg rounded-br-lg text bg-red-800 hover:bg-red-700 text-white py-2 px-4'>Submit</button>
                </div> */}

                <h1 className='ms-14 text-2xl font-bold '>Welcome to Dashboard</h1>
                <img loading ='lazy'
                    onClick={() => setIsPopupVisible(true)}
                    src={BaseApiUrl + User.profile_pic || ''}
                    alt="userHeader"
                    className='me-7 hover:cursor-pointer h-[60px] w-[60px] rounded-full'
                />
            </div>

            {isPopupVisible && (
                <div className="absolute right-0 z-50 flex justify-end">
                    <div ref={popupRef} className="bg-white shadow-[0px_1px_12px_2px_rgba(188,186,184.25)] rounded-2xl w-64 mt-2 text-center">
                        <div className='flex gap-5 py-5 justify-center items-center border-b-2 border-stone-300'>
                            <div>
                                <img loading ='lazy' src={BaseApiUrl + User.profile_pic || ''} alt="userProfileHeader" className='h-[50px] w-[50px] rounded-full' />
                            </div>
                            <div className='text-start'>
                                <p className='text-[17px] font-semibold'>{User.email || 'Guest User'}</p>
                                <p className='text-sm font-medium text-zinc-600'>{User.is_superuser ? "Admin" : "Staff"}</p>
                            </div>
                        </div>
                        <div className='ps-7 text-zinc-600'>
                            <NavLink to={'/admin/userProfile'} onClick={() => setIsPopupVisible(false)}>
                                <div className='flex pt-2 justify-start gap-5 items-center'>
                                    <FaUserLarge size={20} />
                                    <p className="text-lg font-medium">Profile</p>
                                </div>
                            </NavLink>
                            <NavLink to={'/admin/generalPage'} onClick={() => setIsPopupVisible(false)}>
                                <div className='flex pt-2 justify-start gap-5 items-center'>
                                    <IoSettingsSharp size={20} />
                                    <p className="text-lg font-medium">Settings</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className='px-7'>
                            <button
                                onClick={handleLogout}
                                className="px-4 my-5 w-full py-2 hover:bg-red-600 bg-red-800 font-semibold text-white rounded-lg">
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;

