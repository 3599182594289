import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';  // Video.js styles
import 'videojs-vjsdownload-react'; // ✅ Correct
import 'videojs-landscape-fullscreen'

const VideoPlayer = ({ videoUrl,className }) => {
    const videoRef = useRef(null);  // Create a reference for the video element
    const playerRef = useRef(null);  // Create a reference for the Video.js player instance 

    useEffect(() => {
        // Initialize Video.js player only once
        const player = videojs(videoRef.current, {
            controls: true,        // Enable controls (play, pause, etc.)
            autoplay: false,       // Do not autoplay the video
            preload: 'auto',       // Preload video for smoother playback
            fluid: true,           // Make the video responsive
            liveui: false,         // Disable the live UI (this removes the "Live" label)
            techOrder: ['html5'],  // Ensure we're using HTML5 video for regular playback
            html5: {
                nativeControlsForTouch: false,  // Disable native touch controls to keep Video.js controls
                preload: 'auto',               // Preload the video to avoid lag
            },
            plugins: {
                vjsdownload: {
                  beforeElement: "playbackRateMenuButton",
                  textControl: "Download Video",
                  name: "downloadButton",
                },
              },
        });

        playerRef.current = player;

        // Clean up the Video.js player when the component unmounts
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
            }
        };
    }, []);
    

    useEffect(() => {
        // Update the video source if the `videoUrl` prop changes
        if (playerRef.current && videoUrl) {
            playerRef.current.src({ type: 'video/mp4', src: videoUrl });
            playerRef.current.load();
            playerRef.current.landscapeFullscreen({
                fullscreen: {
                    enterOnRotate: true,
                    exitOnRotate: true,
                    alwaysInLandscapeMode: true,
                    iOS: true,
                }
            });
        }
    }, [videoUrl]);

    return (
        <div data-vjs-player>
            <video ref={videoRef} className={`video-js vjs-default-skin ${className}`} />
        </div>
    );
};

export default VideoPlayer;
