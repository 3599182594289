import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { PlanTrialLink } from '../../utilities/enviroment';

const UpgradeNow = ({ errorCloseModal }) => {

  const img = 'https://dqjz1vyvvt4rj.cloudfront.net/banner/upgrade_now.png'

  const navigate = useNavigate()
  const location = useLocation()
  const close = () => {
    errorCloseModal()
    if (location.pathname.includes("aichat")) {
      navigate('/')
      return
    }
  }
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center z-50 backdrop-blur-lg">
      <div className="modal-dialog modal-lg bg-white  rounded-t-lg overflow-hidden shadow-xl max-w-[16rem] mo:max-w-2xl w-full">
        {/* Modal Content */}
        <div className="modal-wrap flex-wrap mo:flex relative">
          {/* Image Container - 50% Width */}
          <div className="">
            {/* Desktop Image */}
            <img
              src={img}
              alt="Modal Img"
              className="login-img w-full h-full object-cover"
            />
          </div>

          {/* Content Container - 50% Width */}
          <div className="modal-content px-1 py-[1px] mo:px-1.5 mo:py-0.5 absolute grid justify-center top-0 right-0 bg-white rounded-bl-lg">
            {/* Modal Header */}
            <div className="modal-header flex justify-between items-start">
              <button
                type="button"
                className="btn-close text-sm mo:text-xl text-[#990013] "
                onClick={close}
              >
                <i className="fa-regular fa-circle-xmark"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=' bg-white flex items-center justify-between p-2 max-w-[16rem] mo:max-w-2xl w-full rounded-b-lg'>
        <p className='text-[8px] mo:text-[10px] text-[#99000B] p-2  w-[85%]'>
          <strong>*Disclaimer:*</strong>  Your 3-day free trial grants you access to all Eromantic AI features, including image generation, AI companion creation,
          and intimate chats. Upon trial completion, you will be automatically enrolled in our Bronze Plan and billed $19.99 unless you cancel before
          the trial's end. You may cancel at any time during the 3-day trial without penalty. After the trial, continued use of Eromantic AI is subject
          to our <Link to="/terms-of-service" className='underline'>Terms of Service</Link> and <Link to='/privacy-policy' className='underline'>Privacy Policy</Link>. Please review these documents carefully before starting your trial. This service is intended
          for adults 18+ only. Images generated may contain explicit content. Use responsibly.
        </p>
        <button className='!text-[8px] mo:!text-xs border p-1 rounded-2xl bg-[#99000B] w-[15%] text-white py-2 px-3' onClick={() => { window.location.href = PlanTrialLink }} >Free Trial</button>
      </div>
    </div>
  )
}

export default UpgradeNow
