function Skeleton() {
    return (
      <div className="relative w-full h-80 xl:h-[500px] rounded-xl overflow-hidden bg-[#333333] animate-pulse">
        {/* Image Placeholder */}
        <div className="w-full h-full bg-[#2a2a2a] relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-[#2a2a2a] via-[#222222] to-[#2a2a2a] animate-[shimmer_1.5s_infinite]"></div>
        </div>
        
        {/* Overlay Content */}
        <div className="absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-700">
          <div className="grid justify-end pt-4 rounded-lg">
            <button className="text-white font-medium rounded-2xl px-3 py-2">
              <div className="w-10 h-10 bg-[#222222] rounded-full relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-[#222222] via-[#1a1a1a] to-[#222222] animate-[shimmer_1.5s_infinite]"></div>
              </div>
            </button>
            <button className="text-white font-medium rounded-2xl px-3 py-2">
              <div className="w-10 h-10 bg-[#222222] rounded-full relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-[#222222] via-[#1a1a1a] to-[#222222] animate-[shimmer_1.5s_infinite]"></div>
              </div>
            </button>
          </div>
  
          {/* Name and Age Placeholder */}
          <div className="absolute bottom-0 left-0 right-0 bg-[#111111] bg-opacity-90 text-white p-2 rounded-b-lg">
            <div className="flex justify-between flex-wrap py-1">
              <span className="w-24 h-5 bg-[#2a2a2a] rounded-md relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-[#2a2a2a] via-[#1a1a1a] to-[#2a2a2a] animate-[shimmer_1.5s_infinite]"></div>
              </span>
              <span className="w-12 h-5 bg-[#2a2a2a] rounded-md relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-[#2a2a2a] via-[#1a1a1a] to-[#2a2a2a] animate-[shimmer_1.5s_infinite]"></div>
              </span>
            </div>
            <p className="w-full h-4 bg-[#2a2a2a] rounded-md mt-2 relative overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-[#2a2a2a] via-[#1a1a1a] to-[#2a2a2a] animate-[shimmer_1.5s_infinite]"></div>
            </p>
          </div>
        </div>
      </div>
    );
  }
  
  export default Skeleton;