import React, { useState } from "react";
import { advanceImg, recycle, starImg, videoImg } from "../../../assets";
import Simplified from "./Simplified";
import AdvancePromopt from "./AdvancePromopt";
import VideoPage from "./VideoPage";
import ReplicatePose from "./ReplicatePose";
import Footer from "../../../frontend/components/Footer";
import PageWrapper from '../../../utilities/Metatags'
import { useSearchParams } from "react-router-dom";


const LandingPageFrontend = () => {
  // const [activeComponent, setActiveComponent] = useState("Simplified");

  const [searchParams, setSearchParams] = useSearchParams();
  const activeComponent = searchParams.get("view") || "Simplified";

  const updateView = (view) => {
    setSearchParams({ view }); // Updates the URL with `?view=VideoPage` or `?view=Simplified`
  };
  return (
    <>
 <PageWrapper
        title={"AI Porn Image Generator | AI Hentai, Anime & Realistic Images"}
        description={
          "From facesitting porn to AI hentai, 3D anime, and realistic adult images, Eromantic AI lets you create it all. Explore this powerful AI porn image generator now"
        }
        keywords={
          "AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        }
      >

      <div className="mt-20 px-5 xl:px-20 pb-20 pt-10 text-black font-josefin-sans">
        <div>
          <p className="text-xl md:text-2xl py-2 text-black">
            Generate Image & Video
          </p>
          <div className="flex flex-col md:flex-row rounded-tr-xl rounded-br-xl rounded-tl-xl rounded-bl-xl justify-between lg:text-xl bg-red-600">
            <button
              className={`flex-1 flex items-center justify-center gap-2 md:gap-5 px-3 md:px-5 xl:px-10 py-3 rounded-tl-xl md:rounded-bl-xl rounded-tr-xl md:rounded-tr-none  ${
                activeComponent === "Simplified"
                  ? "bg-red-800"
                  : "bg-red-600 hover:bg-red-800"
              }`}
              onClick={() => updateView("Simplified")}
              >
              <img
                loading="lazy"
                src={starImg}
                alt="Star Icon"
                className="w-6 md:w-8"
              />
              <p className="text-white text-sm md:text-base xl:text-lg">
                Image
              </p>
            </button>

            {/* <button
                            className={`flex-1 flex items-center justify-center gap-2 md:gap-5 px-3 md:px-5 xl:px-10 py-3 ${activeComponent === 'AdvancePromopt' ? 'bg-red-800' : 'bg-red-600 hover:bg-red-800'}`}
                            onClick={() => setActiveComponent('AdvancePromopt')}
                        >
                            <img loading ='lazy' src={advanceImg} alt="Advance Icon" className="h-8" />
                            <p className="text-white text-sm md:text-base xl:text-lg">Advance Prompt</p>
                        </button> */}

            <button
              className={`flex-1 flex items-center justify-center gap-2 md:gap-5 px-3 md:px-5 xl:px-10 py-3 relative  rounded-xl md:rounded-tl-none md:rounded-bl-none ${
                activeComponent === "VideoPage"
                  ? "bg-red-800"
                  : "bg-red-600 hover:bg-red-800"
              }`}
              onClick={()=>updateView("VideoPage")}
              // onClick={window.location.hostname.includes('test1') || window.location.hostname.includes('localhost') ? () => updateView("VideoPage") : () => {}}
              >
              <img
                loading="lazy"
                src={videoImg}
                alt="Video Icon"
                className="w-6 md:w-8"
              />
              <p className="text-white text-sm md:text-base xl:text-lg">
                Video
              </p>
              {/* <span className="absolute top-2 right-2 bg-[#ffe3e3ab] text-black text-xs px-2 py-1 rounded-full">
                Coming Soon 🎉
              </span> */}
            </button>

            {/* <button
                            className={`flex-1 flex items-center justify-center gap-2 md:gap-5 px-3 md:px-5 xl:px-10 py-3 md:rounded-tr-xl md:rounded-br-xl ${activeComponent === 'ReplicatePose' ? 'bg-red-800' : 'bg-red-600 hover:bg-red-800'}`}
                            onClick={() => setActiveComponent('ReplicatePose')}
                        >
                            <img loading ='lazy' src={recycle} alt="Recycle Icon" className="w-6 md:w-8" />
                            <p className="text-white text-sm md:text-base xl:text-lg">Replicate Pose</p>
                        </button> */}
          </div>
        </div>

        {activeComponent === "Simplified" ? (
          <Simplified />
        ) : activeComponent === "AdvancePromopt" ? (
          <AdvancePromopt />
        ) : activeComponent === "VideoPage" ? (
          <VideoPage />
        ) : (
          <ReplicatePose />
        )}
      </div>
      </PageWrapper>
      <Footer />

    </>
  );
};

export default LandingPageFrontend;
