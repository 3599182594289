import React from "react";
import DiscoverImagesBox from "../../components/DiscoverImagesBox";
import PageWrapper from '../../../utilities/Metatags'

const Gallery = () => {
  return (
    <>
  <PageWrapper
        title={"My Gallery | Explore Your AI Creations | Eromantic AI"}
        description={
          "View all your AI-generated characters in one place. Explore your personalized gallery and get ready to chat with your creations in the future!"
        }
        keywords={
          "AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        }
      >
      <div className="mt-20 lg:px-20 px-2 pb-20 bg-black pt-10 text-white font-josefin-sans">
        <h1 className="text-3xl font-semibold text-center">Gallery</h1>
        <DiscoverImagesBox />
      </div>
      </PageWrapper>
    </>
  );
};

export default Gallery;
