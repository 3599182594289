import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PagenameSearchBox from '../../components/PagenameSearchBox';
import UserApiClient from '../../../utilities/apiClients';
import StaticCharacterCard from '../../components/StaticCharacterCard';


const StaticChar = () => {
    const navigate = useNavigate();
    const [char, setChar] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchStaticCharacters = async () => {
            await UserApiClient.get(`api//`, {}, true).then(async res => {
                if (res.success) {
                    setChar(res.data)
                }
            })
        };

        fetchStaticCharacters();
    }, []);

    const handleCharacter = () => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            console.error('No auth token found. Please log in.');
            return;
        }
        navigate('/admin/createChar');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredChar = char.filter(char =>
        char.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='h-full bg-white px-7 pb-20 font-josefin-sans'>
            <PagenameSearchBox
                title='Static Character'
                placename='Search Character'
                btnname='CREATE NEW CHARACTER'
                btnfunction={handleCharacter}
                onSearchChange={handleSearchChange} // Ensure this is passed correctly
            />
            <div className='flex flex-wrap justify-start gap-8 pt-8'>
                {[
                    {
                        id: 1,
                        name: "John Doe",
                        gender: "Male",
                        age: 30,
                        ethnicity: "Caucasian",
                        style: "Casual",
                        eyes: "Blue",
                        hair_style: "Short",
                        hair_color: "Blonde",
                        body_type: "Athletic",
                        breast_size: "N/A",
                        butt_size: "Medium",
                        personality: "Outgoing",
                        voice: "Deep",
                        occupation: "Detective",
                        hobbies: "Reading, Running",
                        relationship: "Single",
                        region: "USA",
                        summary: "A sharp-minded detective known for solving complex cases.",
                        img_url: "https://randomuser.me/api/portraits/men/45.jpg",
                        img_url2: "https://randomuser.me/api/portraits/men/46.jpg",
                        vid_url: "https://www.w3schools.com/html/mov_bbb.mp4",
                        likes: 100,
                        created_at: "2024-03-25",
                        published: true
                    },
                    {
                        id: 2,
                        name: "Jane Smith",
                        gender: "Female",
                        age: 28,
                        ethnicity: "Asian",
                        style: "Formal",
                        eyes: "Brown",
                        hair_style: "Long",
                        hair_color: "Black",
                        body_type: "Slim",
                        breast_size: "Medium",
                        butt_size: "Small",
                        personality: "Calm",
                        voice: "Soft",
                        occupation: "Doctor",
                        hobbies: "Painting, Music",
                        relationship: "Married",
                        region: "UK",
                        summary: "A compassionate doctor dedicated to helping people.",
                        img_url: "https://randomuser.me/api/portraits/women/45.jpg",
                        img_url2: "https://randomuser.me/api/portraits/women/46.jpg",
                        vid_url: "https://www.w3schools.com/html/movie.mp4",
                        likes: 250,
                        created_at: "2024-02-10",
                        published: false
                    }
                ].map((char) => (
                    <StaticCharacterCard key={char.id} {...char} />
                ))}
            </div>

        </div>
    );
};

export default StaticChar;
