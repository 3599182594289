import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
// import FrontendHeader from "../../dashboard/components/DashboardHeader";
import { useNavigate } from "react-router-dom";
import {
  PlanBronzeLink,
  PlanGoldLink,
  PlanSilverLink,
  AddOn8Link,
  AddOn29Link,
  AddOn44Link,
  AddOn89Link,
  AddOn179Link,
  AddOn269Link,
} from "../../utilities/enviroment";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import PageWrapper from "../../utilities/Metatags";
import UserApiClient from "../../utilities/apiClients";

const PricePlan = () => {
  const [activeTabani, setActiveTabani] = useState(1);
  const [tokenData, setTokenData] = useState('');
  const user = useSelector((state) => state.user);
  // const [active, setActive] = useState("monthly");
  const navigate = useNavigate();

  function handleSubscribe(e) {
    Swal.fire({
      icon: "warning",
      title: "Attention!!!",
      html: `Make sure you add the email you used to sign-up for Eromantic-Ai while payment, Otherwise your account will not update with the plan.`,
      cancelButtonAriaLabel: false,
      confirmButtonText: "Subscribe now!",
      confirmButtonColor: "#ff3232",
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const btnValue = e.target.value;
        if (btnValue === "bronze") {
          window.location.href = PlanBronzeLink;
        } else if (btnValue === "silver") {
          window.location.href = PlanSilverLink;
        } else if (btnValue === "gold") {
          window.location.href = PlanGoldLink;
        } else {
          navigate("/");
        }
      }
    });
  }

  // Handle tab click and update the URL
  const handleTabClick = (tab) => {
    setActiveTabani(tab);

    if (tab === 1) {
      // Remove 'token' from the URL when 'Subscriptions' is clicked
      const newUrl = window.location.href.replace(/([?&])token=[^&]*/, "");
      window.history.pushState({}, "", newUrl);
    } else if (tab === 2) {
      // Add 'token' to the URL when 'Token' is clicked
      const newUrl = window.location.href.includes("?")
        ? window.location.href + "&token=true"
        : window.location.href + "?token=true";
      window.history.pushState({}, "", newUrl);
    }
  };

  useEffect(() => {
    // On initial load, check if 'token' is in the URL to set the active tab
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("token")) {
      setActiveTabani(2);
    }
  }, []);

  const tokenOptions = [
    { tokens: 100, price: 8.99, link: AddOn8Link },
    { tokens: 350, price: 29.99, link: AddOn29Link },
    { tokens: 550, price: 44.99, link: AddOn44Link },
    { tokens: 1200, price: 89.99, link: AddOn89Link },
    { tokens: 2500, price: 179.99, link: AddOn179Link },
    { tokens: 5200, price: 269.99, link: AddOn269Link },
  ];

  const handleSelect = (option) => {
    const subscription = localStorage.getItem("subscription_type");

    if (user.isAuthenticated && subscription !== "free" && subscription !== "free trial" && tokenData.subscription_type !== "free" && tokenData.subscription_type !== "free trial" ) {
      Swal.fire({
        icon: "info",
        title: "Proceed to Payment",
        html: `You have selected <b>${option.tokens} tokens</b> for <b>$${option.price}</b>. <p>Do you want to proceed?</p>`,
        showCancelButton: true,
        confirmButtonText: "Yes, Proceed!",
        confirmButtonColor: "#ff3232",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = option.link;
          // navigate(option.link); // Redirect to payment page
        }
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Access for Paid Users",
        html: `This feature is available only to users with a paid subscription. To explore our subscription plans, please visit our Pricing page.`,
        showCancelButton: false,
        confirmButtonText: "Okay!",
        confirmButtonColor: "#ff3232",
        cancelButtonText: "Cancel",
      });
      return;
    }
  };

  useEffect(() => {
    if (user.isAuthenticated) {
      UserApiClient.get("api/v1/get_token/", {}, true).then(
        async (response) => {
          if (response.success) {
            setTokenData(response.data);
          }
        }
      );
    }
  }, [user.isAuthenticated]);

  const errorpopup = () => {
    Swal.fire({
      icon: "warning",
      title: "Subscription Required!",
      html: "You don't have a valid subscription to purchase tokens.",
      cancelButtonAriaLabel: false,
      confirmButtonText: "Subscribe now!",
      confirmButtonColor: "#ff3232",
      showCloseButton: true,
    })
  }

  return (
    <>
      <PageWrapper
        title={"Subscribe Now & Save 70% | Eromantic AI Subscription Plans"}
        description={
          "Choose the best subscription plan and get 70% off! Unlock premium features and powerful AI tools at an unbeatable price with Eromantic AI’s special offer."
        }
        keywords={
          "AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        }
      >
        <div className="flex flex-col">
          {/* <FrontendHeader /> */}
          <div className="flex justify-center space-x-4 mt-14">
            <button
              onClick={() => handleTabClick(1)}
              className={`px-4 py-2 w-[30%] md:w-[25%] font-semibold text-lg rounded-tl-lg rounded-bl-lg transition-colors duration-300 ${activeTabani === 1
                  ? "bg-[#991b1b] text-white"
                  : "bg-gray-200 text-gray-600"
                }`}
            >
              Subscriptions
            </button>
            <button
              onClick={tokenData.subscription_type === "free" || tokenData.subscription_type === "free trial" ? errorpopup : () => handleTabClick(2)}
              style={{ margin: "0" }}
              className={`px-4 py-2 w-[30%] md:w-[25%] font-semibold text-lg rounded-tr-lg rounded-br-lg transition-colors duration-300 ${activeTabani === 2
                  ? "bg-[#991b1b] text-white"
                  : "bg-gray-200 text-gray-600"
                }`}
            >
              Token
            </button>

          </div>
          {activeTabani === 1 && (
            <div className="flex-1 text-white text-center mt-[40px] py-10 font-josefin-sans px-5 lg:px-2">
              <p className="text-2xl sm:text-3xl lg:text-4xl font-extrabold">
                Choose Your Plan
              </p>
              <p className="text-base sm:text-lg lg:text-xl text-gray-300">
                Find a plan that works best for you
              </p>

              <div className="mt-10">
                <div className="flex flex-wrap justify-center">
                  {/* {/ Monthly Plan /} */}
                  <div className="relative bg-gradient-to-b from-[#3A3A3A] to-[#606060] border-4 border-[#CD7F32] text-white px-4 py-8 rounded-3xl shadow-lg w-full sm:w-1/2 lg:w-[32%] flex flex-col items-center hover:scale-105 transition-transform">
                    <p className="absolute top-2 right-2 text-sm sm:text-base font-extrabold inline-block px-4 py-1 bg-gradient-to-r from-red-500 to-gray-500 text-white rounded-3xl border-2 border-transparent animate-border overflow-hidden">
                      <span className="absolute inset-0 bg-gradient-to-r from-pink-500 via-yellow-500 to-red-500 blur-md opacity-75 animate-gradient"></span>
                      <span className="relative z-10">Offer 40% OFF</span>
                    </p>
                    <h2 className="text-2xl sm:text-3xl font-bold text-[#CD7F32] mt-5">
                      Bronze Plan
                    </h2>
                    <p className="text-lg sm:text-xl mt-2">Pay Monthly</p>
                    <p className="text-sm sm:text-md font-extrabold mt-4 text-gray-400 line-through">
                      $19.99/ month
                    </p>

                    <p className="flex text-3xl sm:text-4xl font-extrabold mt-4">
                      $11.99
                    </p>
                    <p className="text-[12px] sm:text-base text-gray-300 mt-2">
                      40% off for the first month, then $19.99 per month
                    </p>
                    <ul className="mt-6 mb-2 text-left text-gray-200 space-y-2 text-[12px] sm:text-base">
                      {/* <li classname="capitalize">✔ Messages: 1 Tokens per message sent/received</li>
                                        <li classname="capitalize">✔ Girlfriend/Boyfriend Creation: 5 Tokens</li>
                                        <li classname="capitalize">✔ Image Generation: 1 Tokens</li>
                                        <li classname="capitalize">✔ Video Generation: 5 Tokens</li>
                                        <li classname="capitalize">✔ Upscale Image: 1 Token</li>
                                        <li classname="capitalize">✔ Voice Call: 2 Tokens/Minute</li>
                                        <li classname="capitalize">✔ Voice Message: 0.5 Tokens</li> */}

                      <li classname="capitalize">
                        ✔ 100 tokens per month for FREE{" "}
                      </li>
                      <li classname="capitalize">
                        ✔ Create your perfect AI girlfriend/boyfriend{" "}
                      </li>
                      <li classname="capitalize">
                        ✔ Chat with your virtual lover{" "}
                      </li>
                      <li classname="capitalize">
                        ✔ Generate the spiciest images you can dream up{" "}
                      </li>
                      <li classname="capitalize">
                        ✔ Bring your AI companion to life with personalized
                        videos{" "}
                      </li>
                      <li classname="capitalize">
                        ✔ Give your images more fidelity with our Upscale
                        feature{" "}
                      </li>
                      <li classname="capitalize">
                        ✔ Call your AI and discover the endless possibilities of
                        conversation{" "}
                      </li>
                      <li classname="capitalize">
                        ✔ Hear their voice come alive as you chat{" "}
                      </li>
                      <li classname="capitalize">
                        ✔ Tailor your conversations to create a truly unique
                        connection
                      </li>
                      <li classname="capitalize">
                        ✔ Edit your messages to say exactly what's on your mind
                        and guide your AI companion to respond in ways you'll
                        love.
                      </li>
                    </ul>
                    {/* Flex grow for button alignment */}
                    <div className="flex-grow"></div>
                    <button
                      type="button"
                      value={"bronze"}
                      onClick={handleSubscribe}
                      className="mt-4 w-full py-4 px-8 bg-[#FF4C4C] rounded-full text-white hover:bg-[#FF3232] transition-all duration-200"
                    >
                      Subscribe <p>$11.99/month</p>
                    </button>
                  </div>

                  {/* {/ 3 Month Plan /} */}
                  <div className="relative bg-gradient-to-b from-[#3A3A3A] to-[#606060] border-4 border-[#C0C0C0] text-white mx-2 px-4 py-8 rounded-3xl shadow-lg w-full sm:w-1/2 lg:w-[32%] flex flex-col items-center hover:scale-105 transition-transform">
                    <p className="absolute top-2 right-2 text-sm sm:text-base font-extrabold inline-block px-4 py-1 bg-gradient-to-r from-red-500 to-gray-500 text-white rounded-3xl border-2 border-transparent animate-border overflow-hidden">
                      <span className="absolute inset-0 bg-gradient-to-r from-pink-500 via-yellow-500 to-red-500 blur-md opacity-75 animate-gradient"></span>
                      <span className="relative z-10">Offer 55% OFF</span>
                    </p>
                    <h2 className="text-2xl sm:text-3xl font-bold text-[#C0C0C0] mt-5">
                      Silver Plan
                    </h2>
                    <p className="text-lg sm:text-xl mt-2">
                      Billed Every 3 Months
                    </p>
                    <p className="text-sm sm:text-md font-extrabold mt-4 text-gray-400 line-through">
                      $54.99 / 3 month
                    </p>
                    <p className="text-3xl sm:text-4xl font-extrabold mt-4">
                      $7.99/month
                    </p>
                    <p className="text-[12px] sm:text-base text-gray-300 mt-2">
                      55% off per month, billed as $23.99, then $54.99 per
                      3-month period
                    </p>
                    <ul className="mt-6 mb-2 text-left text-gray-200 space-y-2 text-[12px] sm:text-base">
                      {/* <li classname="capitalize">✔ Messages: 1 Tokens per message sent/received</li>
                                        <li classname="capitalize">✔ Girlfriend/Boyfriend Creation: 5 Tokens</li>
                                        <li classname="capitalize">✔ Image Generation: 1 Tokens</li>
                                        <li classname="capitalize">✔ Video Generation: 5 Tokens</li>
                                        <li classname="capitalize">✔ Upscale Image: 1 Token</li>
                                        <li classname="capitalize">✔ Voice Call: 2 Tokens/Minute</li>
                                        <li classname="capitalize">✔ Voice Message: 0.5 Tokens</li>
                                        <li classname="capitalize">✔ Chat Customization: 0.2 Tokens</li> */}

                      <li classname="capitalize">
                        ✔ 150 tokens per month for FREE
                      </li>
                      <li classname="capitalize">
                        ✔ Create your perfect AI girlfriend/boyfriend
                      </li>
                      <li classname="capitalize">
                        ✔ Chat with your virtual lover
                      </li>
                      <li classname="capitalize">
                        ✔ Generate the spiciest images you can dream up
                      </li>
                      <li classname="capitalize">
                        ✔ Bring your AI companion to life with personalized
                        videos
                      </li>
                      <li classname="capitalize">
                        ✔ Give your images more fidelity with our Upscale
                        feature
                      </li>
                      <li classname="capitalize">
                        ✔ Call your AI and discover the endless possibilities of
                        conversation
                      </li>
                      <li classname="capitalize">
                        ✔ Hear their voice come alive as you chat
                      </li>
                      <li classname="capitalize">
                        ✔ Tailor your conversations to create a truly unique
                        connection
                      </li>
                      <li classname="capitalize">
                        ✔ Edit your messages to say exactly what's on your mind
                        and guide your AI companion to respond in ways you'll
                        love.
                      </li>
                    </ul>
                    <button
                      type="button"
                      value={"silver"}
                      onClick={handleSubscribe}
                      className="w-full py-4 px-7 bg-[#FF4C4C] rounded-full text-white hover:bg-[#FF3232] transition-all duration-200 mt-auto"
                    >
                      Subscribe <p>$23.99/3 months</p>
                    </button>
                  </div>

                  {/* {/ Yearly Plan /} */}
                  <div className="relative bg-gradient-to-b from-[#3A3A3A] to-[#606060] border-4 border-[#FFD700] text-white px-4 py-8 rounded-3xl shadow-lg w-full sm:w-1/2 lg:w-[32%] flex flex-col items-center hover:scale-105 transition-transform">
                    <p className="absolute top-2 right-2 text-sm sm:text-base font-extrabold inline-block px-4 py-1 bg-gradient-to-r from-red-500 to-gray-500 text-white rounded-3xl border-2 border-transparent animate-border overflow-hidden">
                      <span className="absolute inset-0 bg-gradient-to-r from-pink-500 via-yellow-500 to-red-500 blur-md opacity-75 animate-gradient"></span>
                      <span className="relative z-10">Offer 70% OFF</span>
                    </p>
                    <h2 className="text-2xl sm:text-3xl font-bold text-[#FFD700] mt-5">
                      Gold Plan
                    </h2>
                    <p className="text-lg sm:text-xl mt-2">Pay Annually</p>
                    <p className="text-sm sm:text-md font-extrabold mt-4 text-gray-400 line-through">
                      $199.99 / Annual
                    </p>
                    <p className="text-3xl sm:text-4xl font-extrabold mt-4">
                      $4.99/month
                    </p>
                    <p className="text-[12px] sm:text-base text-gray-300 mt-2">
                      70% off for the first year, billed as $59.99, then $199.99
                      annually
                    </p>
                    <ul className="mt-6 mb-2 text-left text-gray-200 space-y-2 text-[12px] sm:text-base">
                      {/* <li classname="capitalize">✔ Messages: Unlimited</li>
                                        <li classname="capitalize">✔ Girlfriend/Boyfriend Creation: 5 Tokens</li>
                                        <li classname="capitalize">✔ Image Generation: 1 Tokens</li>
                                        <li classname="capitalize">✔ Video Generation: 5 Tokens</li>
                                        <li classname="capitalize">✔ Upscale Image: 1 Token</li>
                                        <li classname="capitalize">✔ Voice Call: 2 Tokens/Minute</li>
                                        <li classname="capitalize">✔ Voice Message: 0.5 Tokens</li>
                                        <li classname="capitalize">✔ Chat Customization: 0.2 Tokens</li>
                                        <li classname="capitalize">✔ Message Edit: 0.1 Tokens</li> */}

                      <li classname="capitalize">
                        ✔ 200 tokens per month for FREE
                      </li>
                      <li classname="capitalize">
                        ✔ Create your perfect AI girlfriend/boyfriend
                      </li>
                      <li classname="capitalize">
                        ✔ Chat with your virtual lover
                      </li>
                      <li classname="capitalize">
                        ✔ Generate the spiciest images you can dream up
                      </li>
                      <li classname="capitalize">
                        ✔ Bring your AI companion to life with personalized
                        videos
                      </li>
                      <li classname="capitalize">
                        ✔ Give your images more fidelity with our Upscale
                        feature
                      </li>
                      <li classname="capitalize">
                        ✔ Call your AI and discover the endless possibilities of
                        conversation
                      </li>
                      <li classname="capitalize">
                        ✔ Hear their voice come alive as you chat
                      </li>
                      <li classname="capitalize">
                        ✔ Tailor your conversations to create a truly unique
                        connection
                      </li>
                      <li classname="capitalize">
                        ✔ Edit your messages to say exactly what's on your mind
                        and guide your AI companion to respond in ways you'll
                        love.
                      </li>
                    </ul>
                    <button
                      type="button"
                      value={"gold"}
                      onClick={handleSubscribe}
                      className="w-full mt-auto py-4 px-7 bg-[#FF4C4C] rounded-full text-white hover:bg-[#FF3232] transition-all duration-200"
                    >
                      Subscribe <p>$59.99/year</p>
                    </button>
                  </div>
                </div>
                {/* <p className="px-3 lg:px-7 xl:px-40 2xl:px-48 text-start text-sm sm:text-base lg:text-lg mt-10 text-gray-700">
                            <span className="font-semibold">* Important Note:</span>The 'Replicate Face or Pose from an Uploaded Image' feature is currently in its experimental stage. Access to this feature is available to users who complete purchases via Epoch or Cryptocurrency, as CCBill prohibits image uploads on the platform.
                            If you wish to generate an image of a real person, please note that we <span className="font-bold">require</span> the person's ID document and consent for verification, in compliance with relevant laws and regulations.
                            Thank you for your understanding.
                        </p> */}
              </div>
            </div>
          )}
          {activeTabani === 2 && (
            <>
              <div className="flex flex-col">
                {/* <FrontendHeader /> */}
                <div className="flex-1 text-white text-center mt-[40px] py-10 font-josefin-sans px-5">
                  <p className="text-2xl sm:text-3xl lg:text-4xl font-extrabold">
                    Add-On Tokens
                  </p>
                  <p className="text-base sm:text-lg lg:text-xl text-gray-300">
                    Choose the add-on token package that suits your needs
                  </p>

                  <div className="mt-10 mx-[20%]">
                    {/* {/ Centered container with 20% left & right margin /} */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                      {tokenOptions.map((option, index) => (
                        <div
                          key={index}
                          className="relative group p-6 rounded-3xl shadow-lg overflow-hidden border-4 border-[#C0C0C0] bg-gradient-to-br from-[#292929] via-[#3a3a3a] to-[#606060] transition-transform transform hover:scale-105 hover:shadow-2xl"
                        >
                          {/* {/ Glow Effect /} */}
                          <div className="absolute inset-0 opacity-0 group-hover:opacity-25 blur-xl transition-all"></div>

                          {/* {/ Inner Content /} */}
                          <div className="relative z-10 flex flex-col items-center">
                            <h3 className="text-2xl sm:text-3xl font-bold text-white">
                              {option.tokens} Tokens
                            </h3>
                            <p className="text-lg sm:text-xl text-gray-300 mt-2">
                              ${option.price}
                            </p>
                            <p className="text-[12px] sm:text-base text-gray-400 mt-1">
                              Perfect for {option.tokens / 100}x interactions
                            </p>
                            <button
                              className="mt-5 py-3 px-6 bg-white text-[#FF4C4C] rounded-full font-semibold hover:bg-[#FF3232] hover:text-white transition-all duration-300 shadow-md hover:shadow-lg"
                              onClick={() => handleSelect(option)}
                            >
                              Buy Now
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <p className="px-3 lg:px-7 xl:px-40 2xl:px-48 text-start !text-sm sm:text-base lg:text-lg mt-10 text-gray-700 capitalize">
                    <span className="font-semibold ">* Note:</span> Add-on
                    tokens do not expire and can be used for any services or
                    features.
                  </p>
                </div>
              </div>
            </>
          )}
          <Footer />
        </div>
      </PageWrapper>
    </>
  );
};

export default PricePlan;
