import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Dote,
  Logo,
  affiliateMember,
  dashBoard,
  addTemplate,
  blogs,
  faq,
  notification,
  users,
  withdraw,
  sitemap,
  adminstaff,
  homebanner,
  adminsitemap,
  settings,
  adminInvoice,
  characters
} from "../../assets";
import { useSelector } from "react-redux";


export default function Sidebar() {
  const [openMenu, setOpenMenu] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const user = useSelector(state => state.user.user);
  const permissions = user.permissions ? user.permissions : {}

  const menuItems = [
    {
      icons: <img loading='lazy' src={dashBoard} alt="dashboard" />,
      label: "Dashboard",
      path: "/admin/dashboard",
      is_active: permissions.dashboard,
    },
    // {
    //   icons: <img loading='lazy' src={users} alt="users" />,
    //   label: "Users",
    //   path: "/admin/allUser",
    //   is_active: permissions.user,
    // },
    {
      icons: <img loading='lazy' src={users} alt="users" />,
      label: "Users",
      is_active: permissions.user,
      submenu: [
        {
          icons: <img loading='lazy' src={Dote} alt="" />,
          menu: "User List",
          subpath: "/admin/allUser",
        },
        {
          icons: <img loading='lazy' src={Dote} alt="" />,
          menu: "User Type",
          subpath: "/admin/allUserType",
        },
        {
          icons: <img loading='lazy' src={Dote} alt="" />,
          menu: "User Trash",
          subpath: "/admin/allUserTrash",
        },
      ],
    },
    {
      icons: <img loading='lazy' src={blogs} alt="blog" />,
      label: "Blogs",
      is_active: permissions.blog,
      submenu: [
        {
          icons: <img loading='lazy' src={Dote} alt="" />,
          menu: "Blogs List",
          subpath: "/admin/blogList",
        },
        {
          icons: <img loading='lazy' src={Dote} alt="" />,
          menu: "Category",
          subpath: "/admin/category",
        },
      ],
    },
    {
      icons: <img loading='lazy' src={adminstaff} alt="Affiliated" />,
      label: "Staff",
      path: "/admin/staff",
      is_active: user.is_superuser,
    },
    {
      icons: <img loading='lazy' src={affiliateMember} alt="Affiliated" />,
      label: "Affiliates",
      path: "/admin/affiliated",
      is_active: permissions.affiliate_member,
    },
    {
      icons: <img loading='lazy' src={characters} alt="Characters" />,
      label: "Characters",
      path: "/admin/characters",
      is_active: permissions.characters,
    },
    {
      icons: <img loading='lazy' src={homebanner} alt="Affiliated" />,
      label: "Banners",
      path: "/admin/banners",
      is_active: permissions.home_banner,
    },
    {
      icons: <img loading='lazy' src={notification} alt="Notification" />,
      label: "Notifications",
      path: "/admin/notification",
      is_active: permissions.notification,
    },
    {
      icons: <img loading='lazy' src={withdraw} alt="Template" />,
      label: "Withdrawals",
      path: "/admin/withdrawals",
      is_active: permissions.withdrawal,
    },
    {
      icons: <img loading='lazy' src={adminInvoice} alt="Invoices" />,
      label: "Invoices",
      path: "/admin/invoice",
      is_active: permissions.invoice,
    },
    {
      icons: <img loading='lazy' src={addTemplate} alt="Template" />,
      label: "Templates",
      path: "/admin/template",
      is_active: permissions.add_template,
    },
    {
      icons: <img loading='lazy' src={settings} alt="sitemaps" />,
      label: "Settings",
      path: "/admin/sitemaps",
      is_active: permissions.sitemap,
    },
    {
      icons: <img loading='lazy' src={faq} alt="setting" />,
      label: "FAQ's",
      path: "/admin/faqs",
      is_active: permissions.faq,
    },
  ];

  const toggleMenu = (index) => {
    setOpenMenu(openMenu === index ? null : index);
  };

  const toggleSidebar = () => {
    if (window.innerWidth >= 768) {
    } else {
      setSidebarOpen(!sidebarOpen);
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  };

  window.addEventListener("resize", handleResize);

  // return () => window.removeEventListener('resize', handleResize);

  return (
    <div>
      <button
        onClick={toggleSidebar}
        className={`${sidebarOpen ? "top-12 left-48" : "top-10 left-1"} fixed py-2  z-[100] px-2 text-black rounded-md md:hidden`}
      >
        {sidebarOpen ? (
          <i className="fa-solid fa-xmark text-black text-2xl hidden opacity-0"></i>
        ) : (
          <i className="fa-solid fa-bars text-black text-2xl"></i>
        )}
      </button>
      <nav
        className={`fixed top-0 left-0 h-full z-50 border border-r-gray-300 flex flex-col text-black bg-white duration-300 md:w-48 lg:w-80 ${sidebarOpen ? "block border-r-bl " : "hidden"
          }`}
      >
        <div className="flex-1 overflow-y-auto" style={{scrollbarWidth:"none",scrollBehavior:"smooth"}}>
          <ul className="md:px-3 lg:px-10">
            <div className="flex items-center pt-9 pb-5 bg-white sticky top-0 left-0 overflow-hidden">
              <img loading='lazy' src={Logo} alt="logo" className="max-h-14 w-full" />
              <button
                onClick={toggleSidebar}
                className={` py-2  z-[100] px-2 text-black rounded-md md:hidden mt-[10px]`}
              >
                {sidebarOpen ? (
                  <i className="fa-solid fa-xmark text-black text-2xl"></i>
                ) : (
                  <i className="fa-solid fa-bars text-black text-2xl"></i>
                )}
              </button>
            </div>
            {menuItems.map((item, index) => (
              item.is_active &&
              <li key={index}>
                {item.submenu ? (
                  <button
                    onClick={() => toggleMenu(index)}
                    type="button"
                    className={`flex items-center w-full px-3 py-2 my-2 lg:text-lg font-medium rounded-md duration-300 group hover:bg-gray-300`}
                  >
                    <span className="w-8 ">{item.icons}</span>
                    <span className="flex-1 ms-3 text-left">{item.label}</span>
                    <svg
                      className={`w-3 h-3 transition-transform duration-300 ${openMenu === index ? "rotate-180" : ""
                        }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>
                ) : (
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      `flex items-center w-full px-3 py-2 my-2 lg:text-xl font-medium rounded-md duration-300 group hover:bg-gray-300 ${isActive ? "bg-gray-300" : ""
                      }`
                    }
                  >
                    <span className="w-8">{item.icons}</span>
                    <span
                      onClick={toggleSidebar}
                      className="flex-1 ms-3 text-left "
                    >
                      {item.label}
                    </span>
                  </NavLink>
                )}
                {openMenu === index && item.submenu && (
                  <ul className="py-2 space-y-2 ps-5">
                    {item.submenu.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        onClick={toggleSidebar}
                        className="list-disc text-gray-600 flex gap-3 items-center"
                      >
                        <NavLink
                          to={subItem.subpath}
                          className={({ isActive }) =>
                            `flex items-center justify-start w-full px-2 py-2 rounded-md gap-3 text-gray-500 lg:text-md font-normal ${isActive ? "text-gray-950 bg-gray-300" : ""
                            }`
                          }
                        >
                          {subItem.icons}
                          {subItem.menu}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
}
