import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PageWrapper from '../../../utilities/Metatags'
import {
  redhair, blackhair, brownhair, pinkhair, greyhair, 
  blonde, Relationshipicon, Location, age, eyes, 
  hair, occupation, manbody, personality, 
} from "../../../assets";
import '../../../SwitchStyles.css'; // Adjust the path if necessary

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loaders from "../../../frontend/components/loaders";
import Tooltip from "@mui/material/Tooltip";
import { BaseApiUrl } from "../../../utilities/enviroment";
import Footer from "../../../frontend/components/Footer";
import UserApiClient from "../../../utilities/apiClients";
import UpgradeNow from "../../components/UpgradeNow";
import { colors, hobbies_boys, images0_boys, images10_boys, images110_boys, images11_boys, images12, images120_boys, images1_boys, images20_boys, images2_boys, images30_boys, images3_boys, images40_boys, images4_boys, images50_boys, images5_boys, images80_boys, images8_boys, images90_boys, images9_boys } from "../../../assets/data";

const BASE_URL = BaseApiUrl + "assistant_api/";

const AIBoyfriend = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [gfData, setGFdata] = useState("");
  const [style, setStyle] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [finalInputValue, setFinalInputValue] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsData, setOptionsData] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [pageSequence, setPageSequence] = useState([]);
  const [firstmsg, setFirstmsg] = useState();
  const [completedata, setCompletedata] = useState("");
  const [generatedimg, setGeneratedimg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const audioRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(null); // Track which audio is being played
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [errorisOpen, setErrorIsOpen] = useState(false);
  const errorCloseModal = () => setErrorIsOpen(false);
  const errorOpenModal = () => setErrorIsOpen(true);

  // A list of names to choose from
  const namesList = [
    "Bob",
    "Charlie",
    "Frank",
    "Ivan",
    "Jack",
    "Leo",
    "Oscar",
    "Paul",
    "Quincy",
    "Sam",
    "Victor",
    "Xander",
    "Zane",
    "Ethan",
    "Mason",
    "Liam",
    "Noah",
    "James",
    "Lucas",
    "Daniel",
  ];

  const getRandomNames = () => {
    // Shuffle the namesList and pick the first 8
    const shuffled = [...namesList].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, 8);
  };

  useEffect(() => {
    // Get random suggestions when the component is mounted
    setSuggestions(getRandomNames());
  }, []);

  const handleImageClickview = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "popup-overlay") {
      setIsPopupOpen(false);
    }
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const navigate = useNavigate();


  useEffect(() => {
    setSelectedOptions([]);
  }, [currentPage]);

  useEffect(() => {
    if (selectedOptions.length === 5) {
      handleNextoptions();
    }
  }, [selectedOptions]);

  const handleOptionSelect = (value) => {
    setSelectedOptions((prevState) => {
      // If the value is already selected, remove it; otherwise, add it
      if (prevState.includes(value)) {
        return prevState.filter((option) => option !== value);
      } else if (prevState.length < 5) {
        return [...prevState, value];
      }
      return prevState; // Don't add more than 3
    });
  };

  const handleBack = () => {
    if (selectedIndex === null) {
      if (currentPage === 1) {
        navigate(-1);
      } else {
        setCurrentPage(currentPage - 1);
      }
    } else {
      setSelectedIndex(null);
    }
  };

  const handleNext = (item, selectedColor) => {
    const { title, tab } = item;
    if (currentPage === 1) {
      setStyle(title);
    }
    let newData = { ...gfData, [`gfData${currentPage}`]: { title, tab } };

    if (currentPage >= 1 && currentPage <= 4) {
      setGFdata(newData);
      if (currentPage < 11) {
        setCurrentPage(currentPage + 1);
      } else {
        navigate("/final");
      }
    } else if (currentPage === 5) {
      if (selectedColor === "") {
        toast.error("Select Hair Color First", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        newData = {
          ...newData,
          [`gfData${currentPage}`]: { title, tab, color: selectedColor },
        };
        setGFdata(newData);
        setCurrentPage(currentPage + 1);
      }
    } else if (currentPage >= 6 && currentPage <= 10) {
      // Update state for pages 6 through 8
      let newData = { ...gfData, [`gfData${currentPage}`]: { title, tab } };
      setGFdata(newData);
      if (currentPage < 13) {
        setCurrentPage(currentPage + 1);
      } else {
        navigate("/final");
      }
    }
  };

  const pageNames = {
    11: "Hobbies",
  };

  const getPageName = (pageNumber) =>
    pageNames[pageNumber] || `Page ${pageNumber}`;
  const handleNextoptions = () => {
    let nextPage = currentPage;
    if (currentPage >= 11 && currentPage <= 11) {
      if (Object.values(selectedOptions).every((option) => option !== null)) {
        if (currentPage <= 11) {
          nextPage = currentPage + 1;
        } else {
          navigate("/final");
          return;
        }
      }
    }
    setPageSequence((prevSequence) => {
      const updatedSequence = [...prevSequence, currentPage, selectedOptions];
      const groupByPage = (sequence) => {
        const grouped = {};
        for (let i = 0; i < sequence.length; i += 2) {
          const page = sequence[i];
          const options = sequence[i + 1];
          if (!grouped[page]) {
            grouped[page] = [];
          }
          grouped[page].push(options);
        }
        return grouped;
      };
      const formatGroupedData = (groupedData) => {
        return Object.keys(groupedData)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .map((page) => ({
            page: getPageName(parseInt(page)),
            options: groupedData[page],
          }));
      };
      const groupedData = groupByPage(updatedSequence);
      const formattedData = formatGroupedData(groupedData);

      setOptionsData(formattedData);
      return updatedSequence;
    });

    setCurrentPage(nextPage);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const u_id = localStorage.getItem("u_id");

  const Generategf = async () => {
    const subscription = localStorage.getItem('subscription_type');
    if (subscription === "free") {
      errorOpenModal();
      return;
    }
    if (!inputValue || inputValue.trim() === "") {
      toast.error("Please provide a Name", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    const payload = {
      gfData: gfData,
      optionsData: optionsData,
      GFNmae: inputValue,
      Summary: finalInputValue,
    };

    try {
      loaders(true, "heart_time_req");
      const authToken = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}bot_create/`, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 201) {
        loaders(false, "heart_time_req");
        setFirstmsg(response.data.response_message);
        setCurrentPage(currentPage + 1);
        setGeneratedimg(response.data.imgname);
        setFinalInputValue(response.data);
        setCreatedAt(response.data.chat_time);
      }
    } catch (error) {
      loaders(false, "heart_time_req");
      Swal.fire({
        title: "Error",
        text: error.response.msg || "Our Servers are busy! Please Try Again",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ok",
      })
      // toast.error(errorMessage, {
      //   position: "top-left",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      console.error("Error logging in:", error);
    }
  };


  useEffect(() => {
    NewGenerate();
  }, []);

  const NewGenerate = async () => {
    // setLoading(true);
    // setError(null);
    try {
      const response = await axios.get(`${BASE_URL}bot_fetch/?user_id=${u_id}`);
      if (response.status === 200) {
        setCompletedata(response.data);
      } else {
        // setError('Unexpected response from the server.');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // setError('Failed to fetch data. Please try again.');
    } finally {
      // setLoading(false);
    }
  };
  const handleAudioEnded = (index) => {
    // Reset activeIndex when the audio has completely played
    if (activeIndex === index) {
      setActiveIndex(null); // Or setActiveIndex([]) if it's an array
    }
  };

  const fetchAudioStream = async (voiceId, index) => {
    const text =
      "Here's a playful peek just for you, baby . I hope you enjoy it as much as I enjoy teasing you. ![Here I am] Let me know if there's more you want to see! "; // Replace with your text
    const apiKey = "sk_fd230ee022b2e4b901f2c72cb37ac59964573c9325d74248"; // Replace with your API key
    const voiceSettings = {
      stability: 0.5,
      similarity_boost: 0.75,
    };
    try {
      setIsLoading(true);
      setActiveIndex(index); // Track active audio being fetched

      const response = await fetch(
        `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "xi-api-key": apiKey,
          },
          body: JSON.stringify({
            text,
            voice_settings: voiceSettings,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch audio stream: ${response.statusText}`);
      }

      // Convert the response to a Blob and create an audio URL
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);

      // Set the audio source and play
      if (audioRefs.current[index]) {
        audioRefs.current[index].src = audioUrl;
        audioRefs.current[index].play();
      }
    } catch (error) {
      console.error("Error fetching or playing audio:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const itemsToDisplay = finalInputValue
    ? [
      {
        label: "Relationship:",
        value: finalInputValue?.character_info?.Relationship || "",
        src: Relationshipicon,
      },
      {
        label: "Age:",
        value: finalInputValue.character_info.age || "",
        src: age,
      },
      {
        label: "Personality:",
        value: finalInputValue.character_info.Personality || "",
        src: personality,
      },
      {
        label: "Occupation:",
        value: finalInputValue.character_info.Occupation || "",
        src: occupation,
      },
      {
        label: "Hair Style and Color:",
        value: `${finalInputValue.character_info.hair_Style || ""} ${finalInputValue.character_info.hair_color || ""
          }`,
        src: hair,
      },
      {
        label: "Eyes:",
        value: finalInputValue.character_info.Eyes || "",
        src: eyes,
      },
      {
        label: "Ethnicity:",
        value: finalInputValue.character_info.Ethnicity || "",
        src: Location,
      },
      // {
      //   label: "Region:",
      //   value: finalInputValue.character_info.region || "",
      //   src: country,
      // },
      {
        label: "Body Type:",
        value: finalInputValue.character_info.Body_Type || "",
        src: manbody,
      },
    ]
    : [];

      const handleToggle = async (shareid) => {
        setIsChecked(prevStatus => !prevStatus);
        setIsChecked(!isChecked);
                try {
                const res = await UserApiClient.post('assistant_api/character/public/', { 'assistant_id': shareid, 'status': !isChecked }, true);
                if (res.success) {
                    const toastMessage = isChecked ? "Private Character" : "Character Published";
                    const toastStyle = isChecked
                    ? { backgroundColor: '#FF6347', color: '#fff' }
                    : { backgroundColor: '#32CD32', color: '#fff' }; 
                    toast.success(toastMessage, {
                        position: "top-left", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true,
                        progress: undefined, theme: "colored", style: toastStyle,
                    });
                } else {
                    const errorMessage = res.data.error || 'An unexpected error occurred.';
                    Swal.fire({
                        title: 'Error!', text: errorMessage, icon: 'error', confirmButtonText: 'OK',
                    });
                }
            } catch (err) {
                Swal.fire({ title: 'Error!', text: 'An unexpected error occurred. Please try again.', icon: 'error', confirmButtonText: 'OK', });
            }
      };

  return (
    <>
       <PageWrapper
        title={"Create Your AI Boyfriend & Start Chatting | Eromantic AI"}
        description={
          "Generate your AI Boyfriend now! Customize realistic or anime characters with Eromantic AI. Endless options to design and chat with your AI Boyfriend."
        }
        keywords={
          "AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        }
      >
      <ToastContainer />
        {currentPage === 1 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>1 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Select Style</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                  {images0_boys.map((item, index) => (
                    <div
                      key={index}
                      className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer  overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                        ? "bg-red-600 border-4 dreamclr"
                        : "border border-transparent"
                        }`}
                      onClick={() => handleNext(item)}
                    >
                      <img
                        loading="lazy"
                        src={item.src}
                        alt={item.title}
                        className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                      />
                      <div className="flex justify-center mt-5">
                        <button
                          className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute  px-[6px] mo:px-5 py-1 bottom-4 "
                          onClick={() => handleNext(item)}
                        >
                          {item.title}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 2 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>2 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Select Ethnicity</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                  {style === "Realistic"
                    ? images1_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center mt-5">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))
                    : images10_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center mt-5">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 3 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>3 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Select Age</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                  {style === "Realistic"
                    ? images2_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center mt-5">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))
                    : images20_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center mt-5">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 4 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>4 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Select Eyes</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                  {style === "Realistic"
                    ? images3_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="md:w-72 md:h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center mt-5">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))
                    : images30_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="md:w-72 md:h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center mt-5">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 5 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>5 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Select hair Style & Color*</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="text-center">
                  {/* <p className='text-[35px] py-2'> Choose hair Color*</p> */}
                  <div className="flex flex-wrap justify-center items-center gap-4">
                    {colors.map(({ name, color }) => (
                      <div key={name} className="flex flex-col items-center justify-center space-y-2">
                        <div
                          className={`relative w-[6rem] h-[6rem] rounded-full inline-flex items-center justify-center text-xl font-bold border-2 transition-all duration-300 ease-in-out transform hover:scale-105 
                      ${selectedColor === color
                              ? "border-red-600 shadow-lg shadow-red-500/50"
                              : "border-gray-300 hover:border-gray-500"
                            }
                  `}
                          onClick={() => handleColorClick(color)}
                        >
                          <img
                            loading="lazy"
                            src={name}
                            alt={name}
                            className="w-[6rem] h-[6rem] rounded-full object-cover shadow-md transition-all duration-300 ease-in-out"
                          />
                        </div>
                        <span className="text-center capitalize text-sm font-medium text-gray-700 hover:text-gray-900 transition-all duration-200">
                          {color}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-12 my-5">
                  {style === "Realistic"
                    ? images4_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item, selectedColor)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item, selectedColor)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))
                    : images40_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item, selectedColor)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item, selectedColor)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 6 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>6 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span>Choose Body Type</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                  {style === "Realistic"
                    ? images5_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center mt-5">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))
                    : images50_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`relative w-40 mo:w-72  h-52 mo:h-80 xl:w-[300px] xl:h-[400px] lg:mt-7 cursor-pointer overflow-hidden transform transition-transform duration-300 border-0 hover:border-[4px] hover:border-[#ff707a] rounded-[16px] ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border border-transparent"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        <img
                          loading="lazy"
                          src={item.src}
                          alt={item.title}
                          className="w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover"
                        />
                        <div className="flex justify-center mt-5">
                          <button
                            className="dreamclr text-white !text-[12px] md:!text-[14px]  font-medium rounded-2xl mo:rounded-full absolute px-[6px] mo:px-5 py-1 bottom-4 "
                            onClick={() => handleNext(item)}
                          >
                            {item.title}
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
        {currentPage === 7 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>7 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Choose Personality</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div>
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center gap-2 lg:gap-5 mt-5">
                    {style === "Realistic"
                      ? images8_boys.map((item, index) => (
                        <div
                          key={index}
                          className={`flex justify-center items-center relative text-center cursor-pointer rounded-full overflow-hidden transform border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index
                            ? "bg-red-600 border-4 dreamclr"
                            : "border "
                            }`}
                          onClick={() => handleNext(item)}
                        >
                          {/* <img loading ='lazy'
                                                  src={item.src}
                                                  alt={item.title}
                                                  className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                              /> */}
                          <Tooltip title={item.title} placement="top">
                            <button
                              className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                              onClick={() => handleNext(item)}
                            >
                              {item.title.slice(0, 13)}...
                            </button>
                          </Tooltip>
                        </div>
                      ))
                      : images80_boys.map((item, index) => (
                        <div
                          key={index}
                          className={`flex justify-center items-center relative text-center cursor-pointer rounded-full overflow-hidden transform  border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index
                            ? "bg-red-600 border-4 dreamclr"
                            : "border "
                            }`}
                          onClick={() => handleNext(item)}
                        >
                          {/* <img loading ='lazy'
                                            src={item.src}
                                            alt={item.title}
                                            className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                        /> */}
                          <Tooltip title={item.title} placement="top">
                            <button
                              className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                              onClick={() => handleNext(item)}
                            >
                              {item.title.slice(0, 13)}...
                            </button>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 8 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>8 /</span>
                    <span className="text-red-600">
                      13
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Choose Voice</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="flex flex-wrap justify-center gap-2 lg:gap-5 xl:gap-2 mt-5">
                  {style === "Realistic"
                    ? images9_boys.map((item, index) => (
                      <div
                        key={index}
                        className="audio-card flex flex-col justify-center items-center p-4 border rounded-lg hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300"
                      >
                        {isLoading && activeIndex === index ? (
                          // Show loader when fetching audio
                          <div className="loaderaud-container">
                            <div className="loaderaud"></div>
                          </div>
                        ) : activeIndex === index && !isLoading ? (
                          // Show audio wave UI when audio is playing
                          <div className="audio-wave-ui">
                            {/* Audio wave animation */}
                            <div className="wave-container">
                              <div className="wave"></div>
                              <div className="wave"></div>
                              <div className="wave"></div>
                              <div className="wave"></div>
                              <div className="wave"></div>
                            </div>
                          </div>
                        ) : (
                          // Show play button if not loading
                          <button
                            className="play-button flex items-center bg-gray-700 text-white px-4 py-2 rounded-full"
                            onClick={() => fetchAudioStream(item.id, index)}
                            disabled={isLoading && activeIndex === index}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className="w-8 h-8 mr-1 rounded-xl object-cover"
                            />
                            Play
                          </button>
                        )}

                        {/* Show audio progress bar once the audio is loaded */}
                        <audio
                          ref={(el) => (audioRefs.current[index] = el)}
                          className={`audio-player mt-4 w-full ${activeIndex === index && !isLoading
                            ? "hidden"
                            : "hidden"
                            }`}
                          controls
                          onEnded={() => handleAudioEnded(index)}
                        />
                        <button
                          onClick={() => handleNext(item)}
                          className="dreamclr mt-4 ml-2 text-white  flex items-center justify-center w-60 font-medium rounded-full px-5 py-2"
                        >
                          Voice-{index + 1} ({item.title})
                        </button>
                      </div>
                    ))
                    : images90_boys.map((item, index) => (
                      <div
                        key={index}
                        className="audio-card flex flex-col justify-center items-center p-4 border rounded-lg hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300"
                      >
                        {isLoading && activeIndex === index ? (
                          // Show loader when fetching audio
                          <div className="loaderaud-container">
                            <div className="loaderaud"></div>
                          </div>
                        ) : activeIndex === index && !isLoading ? (
                          // Show audio wave UI when audio is playing
                          <div className="audio-wave-ui">
                            {/* Audio wave animation */}
                            <div className="wave-container">
                              <div className="wave"></div>
                              <div className="wave"></div>
                              <div className="wave"></div>
                              <div className="wave"></div>
                              <div className="wave"></div>
                            </div>
                          </div>
                        ) : (
                          // Show play button if not loading
                          <button
                            className="play-button flex items-center bg-gray-700 text-white px-4 py-2 rounded-full"
                            onClick={() => fetchAudioStream(item.id, index)}
                            disabled={isLoading && activeIndex === index}
                          >
                            <img
                              loading="lazy"
                              src={item.src}
                              alt={item.title}
                              className="w-8 h-8 mr-1 rounded-xl object-cover"
                            />
                            Play
                          </button>
                        )}

                        {/* Show audio progress bar once the audio is loaded */}
                        <audio
                          ref={(el) => (audioRefs.current[index] = el)}
                          className={`audio-player mt-4 w-full ${activeIndex === index && !isLoading
                            ? "hidden"
                            : "hidden"
                            }`}
                          controls
                          onEnded={() => handleAudioEnded(index)}
                        />
                        <button
                          onClick={() => handleNext(item)}
                          className="dreamclr mt-4 ml-2 text-white flex items-center w-60 font-medium rounded-full px-5 py-2"
                        >
                          Voice-{index + 1} ({item.title})
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 9 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>9 /</span>
                    <span className="text-red-600">
                      13
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Choose Occupation</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center gap-2 lg:gap-5 mt-5">
                  {style === "Realistic"
                    ? images11_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-center items-center relative  text-center cursor-pointer rounded-full overflow-hidden transform border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        {/* <img loading ='lazy'
                                      src={item.src}
                                      alt={item.title}
                                      className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                  /> */}
                        <Tooltip title={item.title} placement="top">
                          <button
                            className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                            onClick={() => handleNext(item)}
                          >
                            {item.title.slice(0, 13)}...
                          </button>
                        </Tooltip>
                      </div>
                    ))
                    : images110_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-center items-center relative  text-center cursor-pointer rounded-full overflow-hidden transform  border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        {/* <img loading ='lazy'
                                      src={item.src}
                                      alt={item.title}
                                      className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                  /> */}
                        <Tooltip title={item.title} placement="top">
                          <button
                            className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                            onClick={() => handleNext(item)}
                          >
                            {item.title.slice(0, 13)}...
                          </button>
                        </Tooltip>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 10 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>10 /</span>
                    <span className="text-red-600">
                      13
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span>Choose Relationship</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center gap-2 lg:gap-5 mt-5">
                  {style === "Realistic"
                    ? images12.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-center items-center relative  text-center cursor-pointer rounded-full overflow-hidden transform border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        {/* <img loading ='lazy'
                                      src={item.src}
                                      alt={item.title}
                                      className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                  /> */}
                        <Tooltip title={item.title} placement="top">
                          <button
                            className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                            onClick={() => handleNext(item)}
                          >
                            {item.title.slice(0, 13)}...
                          </button>
                        </Tooltip>
                      </div>
                    ))
                    : images120_boys.map((item, index) => (
                      <div
                        key={index}
                        className={`flex justify-center items-center relative  text-center cursor-pointer rounded-full overflow-hidden transform border-0 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 ${selectedIndex === index
                          ? "bg-red-600 border-4 dreamclr"
                          : "border"
                          }`}
                        onClick={() => handleNext(item)}
                      >
                        {/* <img loading ='lazy'
                                      src={item.src}
                                      alt={item.title}
                                      className='w-72 h-80 xl:w-[300px] xl:h-[400px] rounded-xl object-cover'
                                  /> */}
                        <Tooltip title={item.title} placement="top">
                          <button
                            className="dreamclr text-white font-medium w-72 lg:w-full rounded-full px-4 py-2 h-full"
                            onClick={() => handleNext(item)}
                          >
                            {item.title.slice(0, 13)}...
                          </button>
                        </Tooltip>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 11 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[1rem] mo:p-8 mb-16 mo:mb-0  mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>{selectedOptions.length} /</span>
                    <span className="text-red-600">
                      5
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span>Choose Hobbies (Choose 5 options)</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div>
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 px-4">
                    {hobbies_boys.map((option) => (
                      <div
                        key={option}
                        className={`m-2 justify-center flex items-center gap-2 py-4 px-8 border-2 bg-[#6161614A] 
                          ${selectedOptions.includes(option)
                            ? "bg-[#FF00133D] border-red-700 text-red-500"
                            : ""
                          } 
                          border-zinc-600 text-white text-lg rounded-[10px] hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300`}
                        onClick={() => handleOptionSelect(option)}
                      >
                        <p className="text-center text-[12px] mo:text-[14px] md:text-[16px] text-wrap">{option}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 12 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>14 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Name Your Boyfriend</span>
                  </div>
                  <button
                    className="bg-gray-700 text-white !text-[12px] md:!text-[14px] py-0 px-[10px] md:px-4 md:py-1 rounded"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </div>

                <div className="">
                  <div className="bg-gray-700 rounded-xl mt-2 px-5 py-3 text-black text-2xl font-light w-auto h-38">
                    <input
                      type="text"
                      className="focus:outline-none border-0 text-white bg-gray-700 w-full"
                      name="gfname"
                      placeholder="Handsome"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <h2 className="my-5 ml-4">Name Suggestions:</h2>
                    <ul className="flex flex-wrap ml-10">
                      {suggestions.map((name, index) => (
                        <li
                          key={index}
                          onClick={() => setInputValue(name)}
                          className="border text-center rounded-lg w-[7rem] p-2 m-1 hover:shadow-[0px_10px_25px_rgba(255,0,0,0.6)] transition-shadow duration-300 cursor-pointer"
                        >
                          {name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="w-80 bg-red-700 text-white px-5 py-2 rounded mt-[100px]"
                      onClick={Generategf}
                    >
                      Bring My Boyfriend To Life
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage === 13 && (
          <>
            <div className="flex justify-center">
              <div className="bg-[#54545496]  mo:w-[75%] p-[0.7rem] mb-16 mo:mb-0 mo:p-10 mt-2 rounded-2xl">
                <div className="bg-gray-500 flex flex-wrap items-center justify-between rounded-xl mb-[50px] px-5 py-2 text-black text-2xl font-light w-full h-38">
                  <div className="text-xl xl:text-[25px] text-white font-medium text-center">
                    <span>15 /</span>
                    <span className="text-red-600">
                      15
                      <span className="inline-block w-3 h-3 bg-red-600 rounded-full mx-4 mb-[2px] border-2 border-red-600 shadow-md shadow-red-800"></span>
                    </span>
                    <span> Summary</span>
                  </div>
                  {/* <button className='bg-gray-700 text-white px-4 py-1 rounded' onClick={handleBack}>
                              Back
                          </button> */}
                </div>

                <div className="">
                  <div className="w-50 flex justify-center">
                    <h1 className="text-2xl font-semibold mb-6 text-center xl:text-left px-4 py-2 bg-gradient-to-r from-[#99000b] to-[#ff6666] text-white rounded-lg shadow-lg">
                      Character Profile
                    </h1>
                  </div>

                  <div className="rounded flex flex-col items-center justify-center">
                    <div
                      className="flex flex-col gap-5 sm:gap-16 sm:flex-row overflow-scroll"
                      style={{ scrollbarWidth: "none" }}
                    >
                      <div>
                        <img
                          loading="lazy"
                          src={generatedimg}
                          alt="Generated Img"
                          className="w-full h-full cursor-pointer sm:h-[90%] md:h-[90%] lg:w-[90%] xl:w-[90%] lg:h-[90%] xl:h-[90%] 2xl:w-[90%] 2xl:h-[90%] rounded-xl"
                          style={{ objectFit: "cover" }}
                          onClick={handleImageClickview}
                        />
                      </div>
                      <div className="flex flex-col gap-8">
                        <div className="flex justify-between">
                          <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                            {finalInputValue.character_info.name}
                          </h2>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 mb-3">
                          {itemsToDisplay.map(({ label, value, src }, index) => (
                            <div key={index} className="flex items-start gap-2">
                              <img
                                loading="lazy"
                                src={src}
                                className="icon w-6 md:w-8 mt-1"
                                alt="icon"
                              />
                              <div className="flex flex-col">
                                <span className="text-muted font-bold text-base mo:text-md md:text-lg">
                                  {label}
                                </span>
                                <span className="uppercase text-[12px] mo:text-sm md:text-md">
                                  {value}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                            <div>
                              <div class="btn-container">
                                  <label class="switch btn-color-mode-switch">
                                      <input type="checkbox" name="color_mode" id="color_mode" value="1" checked={isChecked} onChange={() => handleToggle(finalInputValue.assistant)}/>
                                      <label for="color_mode" data-on="Public" data-off="Private" class="btn-color-mode-switch-inner"></label>
                                  </label>
                              </div>
                            </div>

                        <div className="flex items-start">
                          <span className="icon mt-1 mr-2 icon-summary"></span>
                          <div className="flex flex-col">
                            <span className="font-semibold text-muted text-base mo:text-md md:text-lg lg:text-xl">
                              About:
                            </span>
                            <span className="text-[12px] mo:text-base md:text-md lg:text-lg">
                              {finalInputValue.Summary}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      className="bg-red-800 text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%] mt-10"
                      onClick={() => navigate(`/aichat/${finalInputValue.assistant}`)}
                    >
                      Chat Now
                    </button>
                  </div>
                </div>
              </div>
              {isPopupOpen && (
                <div
                  id="popup-overlay"
                  className="fixed  inset-0 bg-black mt-15 bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto"
                  onClick={handleOutsideClick}
                >
                  <div className="relative bg-gray-300 w-full mo:w-[28%] rounded-lg border-2 border-[#7d2424b3]">
                    <button
                      className="absolute w-10 top-1 right-2 bg-red-600 text-white rounded-full"
                      onClick={handleClosePopup}
                    >
                      X
                    </button>

                    <img
                      src={generatedimg ? generatedimg : ""}
                      alt="Full View"
                      className="w-full h-auto rounded-lg"
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}

      </PageWrapper>
      <Footer />

      {errorisOpen && (
        <UpgradeNow errorCloseModal={errorCloseModal} />
      )}
    </>
  );
};

export default AIBoyfriend;
