import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../../SwitchStyles.css'; // Adjust the path if necessary
import {
  Relationshipicon,
  Location,
  age,
  brest,
  country,
  eyes,
  hair,
  occupation,
  manbody,
  personality,
  Bodyicon,
  Bodyback,
} from "../../assets";
import loaders from "../../frontend/components/loaders";
import Swal from "sweetalert2";
import PageWrapper from '../../utilities/Metatags'
import { BaseApiUrl } from "../../utilities/enviroment";
import Footer from "../../frontend/components/Footer";
import UserApiClient from "../../utilities/apiClients";
import UpgradeNow from "../components/UpgradeNow";
import { Skeleton } from "@mui/material";

function MyCreation() {
  const navigate = useNavigate();
  const [imgdata, setImgdata] = useState([]); // Default to empty array
  const [girlAnimedata, setGirlAnimedata] = useState([]); // Default to empty array
  const [girlRealmedata, setGirlRealmedata] = useState([]); // Default to empty array
  const [girlRealmedataPublic, setGirlRealmedataPublic] = useState([]); // Default to empty array
  const [boyRealmedataPublic, setBoyRealmedataPublic] = useState([]); // Default to empty array
  const [girlAnimedataPublic, setGirlAnimedataPublic] = useState([]); // Default to empty array

  const [boyRealmedata, setBoyRealmedata] = useState([]); // Default to empty array
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null);
  const [deletedRealGItems, setDeletedRealGItems] = useState([-1]);
  const [deletedAnimeItems, setDeletedAnimeItems] = useState([-1]);
  const [deletedRealBItems, setDeletedRealBItems] = useState([-1]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [modalData, setModalData] = useState(null); // Data for the modal
  const [modalImg, setModalImg] = useState(null); // Image for modal
  const [liked, setLiked] = useState(false); // Likes count for modal
  const [likesCount, setLikesCount] = useState(0);
  const [publicStatus, setpublicStatus] = useState(false);
  const [publicStatussend, setpublicStatussend] = useState('');
  const [selectedCategory, setSelectedCategory] = useState("Girls");
  const [selectedCreations, setSelectedCreations] = useState("PublicCreations");
  const [botdetail, setBotdetail] = useState(null); // Likes count for modal
  const [isChecked, setIsChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(null); // State to track hover

  const BASE_URL = BaseApiUrl + "assistant_api/";

  const NewGenerate = async () => {
    loaders(true, "heart");
    setError(null);
    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}bot_fetch/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        const resdata = response.data.data;
        setGirlRealmedata(
          resdata.filter(
            (item) => item.Butt_Size !== "" && item.style === "Realistic"
          )
        );
        setBoyRealmedata(
          resdata.filter(
            (item) => item.Butt_Size === "" && item.style === "Realistic"
          )
        );
        setGirlAnimedata(resdata.filter((item) => item.style === "Anime"));
      } else {
        setError("Unexpected response from the server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("No creations available.");
    } finally {
      loaders(false, "heart");
    }
  };

  const PublicGenerate = async () => {
    loaders(true, "heart");
    setError(null);
    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}get-public/character/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        const resdata = response.data.characters;
        setGirlRealmedataPublic(
          resdata.filter(
            (item) => item.Butt_Size !== "" && item.style === "Realistic"
          )
        );
        setBoyRealmedataPublic(
          resdata.filter(
            (item) => item.Butt_Size === "" && item.style === "Realistic"
          )
        );
        setGirlAnimedataPublic(resdata.filter((item) => item.style === "Anime"));
      } else {
        setError("Unexpected response from the server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("No creations available.");
    } finally {
      loaders(false, "heart");
    }
  };

  useEffect(() => {
    NewGenerate();
    PublicGenerate();
  }, []);

  const handleAddProfileClick = () => {
    navigate("/ai-girlfriend-generator"); // Replace '/add-profile' with your desired route
  };

  const openModal = async (assistant_id, item) => {
    setBotdetail(item);
    if (!assistant_id) {
      console.error("assistant_id is required");
      return;
    }

    let selectedImage = null;
    const imgdataList = [imgdata]; // Add more lists here if necessary, like imgdataanime, imgboydata

    for (let list of imgdataList) {
      selectedImage = list.find((item) => item.assistant_id === assistant_id);
      if (selectedImage) break;
    }

    if (selectedImage) {
      setModalImg(selectedImage.Image); // Set the image for the modal
    }

    const payload = { assistant_id };
    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}fetch_static_data/`, {
        params: payload,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }
      const data = response.data;
      setModalData(data);
      setpublicStatus(data.public);
      setpublicStatussend(data.public);
      setLikesCount(data.likes); // Set the likes count
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const toggleLike = async (assistant_id) => {
    const u_id = localStorage.getItem("u_id");
    const payload = { assistant_id: assistant_id, u_id: u_id };

    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}like_static/`, payload, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        setLiked(!liked);

        setLikesCount(response.data.likes);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [isLoadingsingleimage, setIsLoadingsingleimage] = useState(true);

  // Handler for when the image finishes loading
  const handleImageLoad = () => {
    setIsLoadingsingleimage(false);
  };

  const itemsToDisplay = botdetail
    ? (botdetail.Butt_Size === "" || botdetail.Butt_Size === "NA") &&
      (botdetail.Breast_Size === "" || botdetail.Breast_Size === "NA")
      ? [
        {
          label: "Relationship:",
          value: botdetail.Relationship,
          src: Relationshipicon,
        },
        { label: "Age:", value: botdetail.age, src: age },
        {
          label: "Occupation:",
          value: botdetail.Occupation,
          src: occupation,
        },
        {
          label: "Personality:",
          value: botdetail.Personality,
          src: personality,
        },
        {
          label: "Hair Style and Color:",
          value: `${botdetail.hair_Style} ${botdetail.hair_color}`,
          src: hair,
        },
        { label: "Eyes:", value: botdetail.Eyes, src: eyes },
        { label: "Ethnicity:", value: botdetail.Ethnicity, src: Location },
        { label: "Region:", value: botdetail.region, src: country },
        { label: "Body Type:", value: botdetail.Body_Type, src: manbody },
      ]
      : [
        {
          label: "Relationship:",
          value: botdetail.Relationship,
          src: Relationshipicon,
        },
        { label: "Age:", value: botdetail.age, src: age },
        {
          label: "Occupation:",
          value: botdetail.Occupation,
          src: occupation,
        },
        {
          label: "Personality:",
          value: botdetail.Personality,
          src: personality,
        },
        {
          label: "Hair Style and Color:",
          value: `${botdetail.hair_Style} ${botdetail.hair_color}`,
          src: hair,
        },
        { label: "Eyes:", value: botdetail.Eyes, src: eyes },
        { label: "Ethnicity:", value: botdetail.Ethnicity, src: Location },
        { label: "Region:", value: botdetail.region, src: country },
        { label: "Body Type:", value: botdetail.Body_Type, src: Bodyicon },
        { label: "Breast Size:", value: botdetail.Breast_Size, src: brest },
        { label: "Butt Size:", value: botdetail.Butt_Size, src: Bodyback },
      ]
    : null; //

  const generatepage = (id) => {
    navigate(`/dashboard/generate/${id}`);
  };

  const deletegeneration = (assistant_id, index, character) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        UserApiClient.delete(
          "assistant_api/bot_delete/",
          { assistant_id },
          true,
          true,
          false
        )
          .then(async (res) => {
            if (res.data) {
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              });
              if (character === "GReal") {
                setDeletedRealGItems((prevDeletedItems) =>
                  prevDeletedItems.includes(index)
                    ? prevDeletedItems
                    : [...prevDeletedItems, index]
                );
              } else if (character === "Anime") {
                setDeletedAnimeItems((prevDeletedItems) =>
                  prevDeletedItems.includes(index)
                    ? prevDeletedItems
                    : [...prevDeletedItems, index]
                );
              } else {
                setDeletedRealBItems((prevDeletedItems) =>
                  prevDeletedItems.includes(index)
                    ? prevDeletedItems
                    : [...prevDeletedItems, index]
                );
              }
            } else {
              setError("");
              Swal.fire(
                "Our Servers are busy!",
                "Please wait a moment and try again.",
                "warning",
                3000
              );
            }
          })
          .catch((error) => {
            setError("");
            Swal.fire(
              "Our Servers are busy!",
              "Please wait a moment and try again.",
              "error",
              3000
            );
          });
      }
    });
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleCreationsChange = (category) => {
    setSelectedCreations(category);
  };

  const [isErrorModal, setIsErrorModal] = useState(false)
  const user_type = localStorage.getItem("subscription_type")

  const newCharAdded = async (shareid) => {
    if (user_type !== "free") {
      try {
        const res = await UserApiClient.post('assistant_api/get-public/character/', { 'assistant_id': shareid }, true, true);
        if (res.success) {
          navigate(`/aichat`)
        }
      } catch (err) {
        Swal.fire({ title: 'Error!', text: 'An unexpected error occurred. Please try again.', icon: 'error', confirmButtonText: 'OK', });
      }
    }
    else {
      setIsErrorModal(true)
    }
  };


  const handleToggle = async (shareid, item) => {
    setpublicStatus(prevStatus => !prevStatus);
    setIsChecked(!isChecked);
    try {
      const res = await UserApiClient.post('assistant_api/character/public/', { 'assistant_id': shareid, 'status': publicStatus == true ? false : true }, true);
      if (res.success) {
        console.log("Public")
        openModal(shareid, item);
        PublicGenerate();
      } else {
        const errorMessage = res.data.error || 'An unexpected error occurred.';
        Swal.fire({
          title: 'Error!', text: errorMessage, icon: 'error', confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      Swal.fire({ title: 'Error!', text: 'An unexpected error occurred. Please try again.', icon: 'error', confirmButtonText: 'OK', });
    }
  };

  return (
    <>
      <PageWrapper
        title={"Eromantic.ai- Create Your AI Gf/Bf and Start Chatting"}
        description={
          "Your dream companion awaits! Create your AI Girlfriend, shape her look, personality, and bring her to life in one click."
        }
        keywords={
          "AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        }
      >
        <div className="min-h-screen p-8">
          <div className="flex items-center flex-wrap gap-5 justify-center md:justify-between rounded-lg w-full px-5 py-1 mt-2 mb-10">
            <div>
              {/* <h1 className="text-4xl font-bold text-center m-1">My Creations</h1> */}
              <div className="flex p-1 bg-[#676464] rounded-lg">
                <button
                  onClick={() => handleCreationsChange("myCreations")}
                  className={`px-6 py-1 text-white rounded-lg ${selectedCreations === "myCreations" ? "bg-[#990013]" : ""
                    }`}
                >
                  <i className="fa-solid fa-venus mr-2"></i>My Creations
                </button>
                <button
                  onClick={() => handleCreationsChange("PublicCreations")}
                  className={`px-6 py-1 text-white rounded-lg ${selectedCreations === "PublicCreations" ? "bg-[#990013]" : ""
                    }`}
                >
                  <i className="fa-solid fa-wand-magic-sparkles mr-2"></i>Public Creations
                </button>
              </div>
            </div>
            <div className="flex p-1 bg-[#676464] rounded-lg">
              <button
                onClick={() => handleCategoryChange("Girls")}
                className={`px-6 py-1 text-white rounded-lg ${selectedCategory === "Girls" ? "bg-[#990013]" : ""
                  }`}
              >
                <i className="fa-solid fa-venus mr-2"></i>Girls
              </button>
              <button
                onClick={() => handleCategoryChange("Anime")}
                className={`px-6 py-1 text-white rounded-lg ${selectedCategory === "Anime" ? "bg-[#990013]" : ""
                  }`}
              >
                <i className="fa-solid fa-wand-magic-sparkles mr-2"></i>Anime
              </button>
              <button
                onClick={() => handleCategoryChange("Boys")}
                className={`px-6 py-1 text-white rounded-lg ${selectedCategory === "Boys" ? "bg-[#990013]" : ""
                  }`}
              >
                <i className="fa-solid fa-mars mr-2"></i>Boys
              </button>
            </div>
          </div>

          {/* Loading and Error Handling */}
          {loading && <p className="text-center text-xl">Loading...</p>}
          {error === "No creations available." ? (
            <div className="flex-wrap lg:flex items-center justify-between p-10 rounded-lg shadow-lg bg-[#d5d5d5] my-10">
              <div className="flex-wrap lg:flex gap-4">
                <div className="logo flex items-center justify-center">
                  <i className="fa-solid fa-heart-circle-plus text-red-600 text-4xl" />
                </div>
                <div className="flex flex-col items-center text-center lg:text-left lg:items-start gap-2">
                  <h2 className="text-gray-900 text-2xl font-semibold">
                    Create Your Personalized Characters
                  </h2>
                  <p className="text-gray-800 text-sm">
                    Explore the power of AI to design unique images and characters
                    tailored to your preferences.
                  </p>
                </div>
              </div>
              <div className="text-center lg:text-right">
                <button
                  onClick={() => navigate("/ai-porn-image-generator")}
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                >
                  Start Creating
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* Rendering Image Data */}
          {selectedCreations === "myCreations" ?
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              <div className="relative group">
                <div
                  onClick={handleAddProfileClick}
                  className="h-80 w-full xl:h-[500px] rounded-xl border-2 border-dashed border-gray-300 flex justify-center items-center bg-[#FD818A] transition-all duration-300 ease-in-out"
                >
                  <button className="!text-[50px] xss:text-[100px] font-bold text-white hover:text-[#99000B]">
                    +
                  </button>
                  <div className="absolute bottom-20 xl:bottom-40 left-0 right-0 text-white p-2 rounded-b-lg">
                    <div className="flex justify-center py-1">
                      <span className="text-lg lg:text-lg font-medium text-center">
                        Create New Companion
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {selectedCategory === "Girls" ? (
                <>
                  {girlRealmedata.length > 0
                    ? girlRealmedata.map((item, index) => (
                      <div key={index} className="relative group" onClick={() => openModal(item.assistant, item)}>
                        {/* Image with hover effects */}
                        {isLoadingsingleimage && (
                          <div className="h-80 w-full xl:h-[500px] absolute rounded-2xl p-2 flex bg-[#2a2a2a] justify-center items-center">
                            <div className="relative w-full h-full">
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height="100%"
                                width="100%"
                                sx={{
                                  borderRadius: "12px",
                                  background: "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                                  backgroundSize: "200% 100%",
                                }}
                              />
                              <div className="absolute inset-0 flex flex-col justify-between">
                                <div className="grid justify-end">
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="overflow-hidden rounded-xl relative"
                          onClick={() => openModal(item.assistant_id, item)}
                          onMouseEnter={() => {
                            setIsHovered(index);

                            // Only try to load video if it exists
                            if (item.img_url2) {
                              const videoElement = document.getElementById(`hover-video-${index}`);
                              if (videoElement) {
                                videoElement.load();
                                videoElement.play().catch(e => console.log("Video play error:", e));
                              }
                            }
                          }}
                          onMouseLeave={() => setIsHovered(null)}
                        >
                          {/* Base image - always present */}
                          <img
                            loading="lazy"
                            src={item.Image}
                            alt={`Img of ${item.name}`}
                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealGItems.includes(index) ? "hidden" : ""
                              } ${isHovered === index && item.img_url2
                                ? 'opacity-0 transition-opacity duration-1000 ease-in-out'
                                : isHovered === index
                                  ? 'scale-105 shadow-lg transition-transform duration-1000 ease-in-out'
                                  : 'transition-transform duration-1000 ease-in-out'
                              }`}
                            onClick={() => openModal(item.assistant_id, item)}
                            onLoad={handleImageLoad}
                            style={item.img_url2 ? {
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              zIndex: 1
                            } : {}}
                          />

                          {/* Video element - only rendered if item has video URL */}
                          {item.img_url2 && (
                            <video
                              id={`hover-video-${index}`}
                              src={item.img_url2}
                              alt={`Video of ${item.name}`}
                              className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealGItems.includes(index) ? "hidden" : ""
                                }`}
                              autoPlay={isHovered === index}
                              loop
                              muted
                              playsInline
                              style={{
                                opacity: isHovered === index ? 1 : 0,
                                transition: 'opacity 1000ms ease-in-out',
                                position: 'relative',
                                zIndex: 0
                              }}
                            />
                          )}

                          {/* Overlay with text - same as your original */}
                          <div
                            className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100 ${deletedRealGItems.includes(index) ? "hidden" : ""
                              }`}
                            style={{ zIndex: 2 }}
                          >
                            {/* Button in top-right corner */}
                            <div className="grid justify-end">
                              <button
                                onClick={() => navigate(`/aichat`)}
                                className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake"
                              >
                                <img
                                  loading="lazy"
                                  src="/assets/img/Group-icon.png"
                                  alt="Icon"
                                  className="w-10 h-10"
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  generatepage(item.assistant_id);
                                }}
                                className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                              >
                                <img
                                  loading="lazy"
                                  src="/assets/img/Group-icon-msg.png"
                                  alt="Icon"
                                  className="w-10 h-10"
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deletegeneration(item.assistant_id, index, "GReal");
                                }}
                                className="flex items-center justify-center bg-[#ff002c] bg-opacity-90 h-10 w-10 rounded-full mx-3 border-2 duration-300 border-red-600 hover:border-red-500 hover:animate-shake"
                              >
                                <i className="fa-regular fa-trash-can fa-lg text-white"></i>
                              </button>
                            </div>

                            {/* Bottom text container */}
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                              <div className="flex justify-between py-1">
                                <span className="text-lg lg:text-lg font-medium">
                                  {item.name}
                                </span>
                                <span className="text-lg lg:text-lg font-medium">
                                  {item.age}
                                </span>
                              </div>
                              <p className="text-sm lg:text-base truncate">
                                {(item.Summary || "")}
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))
                    : !loading && <p className="text-center text-xl"></p>}
                </>
              ) : selectedCategory === "Anime" ? (
                <>
                  {girlAnimedata.length > 0
                    ? girlAnimedata.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => openModal(item.assistant, item)}
                        className={`relative group ${deletedAnimeItems.includes(index) ? "hidden" : ""
                          }`}
                      >
                        {isLoadingsingleimage && (
                          <div className="h-80 w-full xl:h-[500px] absolute rounded-2xl p-2 flex bg-[#2a2a2a] justify-center items-center">
                            <div className="relative w-full h-full">
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height="100%"
                                width="100%"
                                sx={{
                                  borderRadius: "12px",
                                  background: "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                                  backgroundSize: "200% 100%",
                                }}
                              />
                              <div className="absolute inset-0 flex flex-col justify-between">
                                <div className="grid justify-end">
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        )}
                        {/* Image with hover effects */}
                        <div className="overflow-hidden rounded-xl relative"
                          onClick={() => openModal(item.assistant_id, item)}
                          onMouseEnter={() => {
                            setIsHovered(index);

                            // Only try to load video if it exists
                            if (item.img_url2) {
                              const videoElement = document.getElementById(`hover-video-${index}`);
                              if (videoElement) {
                                videoElement.load();
                                videoElement.play().catch(e => console.log("Video play error:", e));
                              }
                            }
                          }}
                          onMouseLeave={() => setIsHovered(null)}
                        >
                          {/* Base image - always present */}
                          <img
                            loading="lazy"
                            src={item.Image}
                            alt={`Img of ${item.name}`}
                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedAnimeItems.includes(index) ? "hidden" : ""
                              } ${isHovered === index && item.img_url2
                                ? 'opacity-0 transition-opacity duration-1000 ease-in-out'
                                : isHovered === index
                                  ? 'scale-105 shadow-lg transition-transform duration-1000 ease-in-out'
                                  : 'transition-transform duration-1000 ease-in-out'
                              }`}
                            onClick={() => openModal(item.assistant_id, item)}
                            onLoad={handleImageLoad}
                            style={item.img_url2 ? {
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              zIndex: 1
                            } : {}}
                          />

                          {/* Video element - only rendered if item has video URL */}
                          {item.img_url2 && (
                            <video
                              id={`hover-video-${index}`}
                              src={item.img_url2}
                              alt={`Video of ${item.name}`}
                              className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedAnimeItems.includes(index) ? "hidden" : ""
                                }`}
                              autoPlay={isHovered === index}
                              loop
                              muted
                              playsInline
                              style={{
                                opacity: isHovered === index ? 1 : 0,
                                transition: 'opacity 1000ms ease-in-out',
                                position: 'relative',
                                zIndex: 0
                              }}
                            />
                          )}
                          {/* Overlay with text */}
                          <div
                            className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100`}
                          >
                            {/* Button in top-right corner */}
                            <div className="grid justify-end">
                              <button
                                onClick={() => navigate(`/aichat`)}
                                className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake"
                              >
                                <img
                                  loading="lazy"
                                  src="/assets/img/Group-icon.png"
                                  alt="Icon"
                                  className="w-10 h-10"
                                />
                              </button>
                              <button
                                onClick={(e) => { e.stopPropagation(); generatepage(item.assistant_id) }}
                                className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                              >
                                <img
                                  loading="lazy"
                                  src="/assets/img/Group-icon-msg.png"
                                  alt="Icon"
                                  className="w-10 h-10"
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deletegeneration(
                                    item.assistant_id,
                                    index,
                                    "Anime"
                                  );
                                }}
                                className="flex items-center justify-center bg-[#ff002c] bg-opacity-90 h-10 w-10 rounded-full mx-3 border-2 duration-300 border-red-600 hover:border-red-500 hover:animate-shake"
                              >
                                <i className="fa-regular fa-trash-can fa-lg text-white"></i>
                              </button>
                            </div>

                            {/* Bottom text container */}
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                              <div className="flex justify-between py-1">
                                <span className="text-lg lg:text-lg font-medium">
                                  {item.name}
                                </span>
                                <span className="text-lg lg:text-lg font-medium">
                                  {item.age}
                                </span>
                              </div>
                              <p className="text-sm lg:text-base truncate">
                                {(item.Summary || "")}
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))
                    : !loading && <p className="text-center text-xl"></p>}
                </>
              ) : (
                <>
                  {boyRealmedata.length > 0
                    ? boyRealmedata.map((item, index) => (
                      <div key={index} className="relative group" onClick={() => openModal(item.assistant, item)}>
                        {/* Image with hover effects */}
                        {isLoadingsingleimage && (
                          <div className="h-80 w-full xl:h-[500px] absolute rounded-2xl p-2 flex bg-[#2a2a2a] justify-center items-center">
                            <div className="relative w-full h-full">
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height="100%"
                                width="100%"
                                sx={{
                                  borderRadius: "12px",
                                  background: "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                                  backgroundSize: "200% 100%",
                                }}
                              />
                              <div className="absolute inset-0 flex flex-col justify-between">
                                <div className="grid justify-end">
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="overflow-hidden rounded-xl relative"
                          onClick={() => openModal(item.assistant_id, item)}
                          onMouseEnter={() => {
                            setIsHovered(index);

                            // Only try to load video if it exists
                            if (item.img_url2) {
                              const videoElement = document.getElementById(`hover-video-${index}`);
                              if (videoElement) {
                                videoElement.load();
                                videoElement.play().catch(e => console.log("Video play error:", e));
                              }
                            }
                          }}
                          onMouseLeave={() => setIsHovered(null)}
                        >
                          {/* Base image - always present */}
                          <img
                            loading="lazy"
                            src={item.Image}
                            alt={`Img of ${item.name}`}
                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealBItems.includes(index) ? "hidden" : ""
                              } ${isHovered === index && item.img_url2
                                ? 'opacity-0 transition-opacity duration-1000 ease-in-out'
                                : isHovered === index
                                  ? 'scale-105 shadow-lg transition-transform duration-1000 ease-in-out'
                                  : 'transition-transform duration-1000 ease-in-out'
                              }`}
                            onClick={() => openModal(item.assistant_id, item)}
                            onLoad={handleImageLoad}
                            style={item.img_url2 ? {
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              zIndex: 1
                            } : {}}
                          />

                          {/* Video element - only rendered if item has video URL */}
                          {item.img_url2 && (
                            <video
                              id={`hover-video-${index}`}
                              src={item.img_url2}
                              alt={`Video of ${item.name}`}
                              className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealBItems.includes(index) ? "hidden" : ""
                                }`}
                              autoPlay={isHovered === index}
                              loop
                              muted
                              playsInline
                              style={{
                                opacity: isHovered === index ? 1 : 0,
                                transition: 'opacity 1000ms ease-in-out',
                                position: 'relative',
                                zIndex: 0
                              }}
                            />
                          )}
                          {/* Overlay with text */}
                          <div
                            className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100 ${deletedRealBItems.includes(index) ? "hidden" : ""
                              }`}
                          >
                            {/* Button in top-right corner */}
                            <div className="grid justify-end">
                              <button
                                onClick={() => navigate(`/aichat`)}
                                className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake"
                              >
                                <img
                                  loading="lazy"
                                  src="/assets/img/Group-icon.png"
                                  alt="Icon"
                                  className="w-10 h-10"
                                />
                              </button>
                              <button
                                onClick={(e) => { e.stopPropagation(); generatepage(item.assistant_id) }}
                                className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                              >
                                <img
                                  loading="lazy"
                                  src="/assets/img/Group-icon-msg.png"
                                  alt="Icon"
                                  className="w-10 h-10"
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deletegeneration(
                                    item.assistant_id,
                                    index,
                                    "BReal"
                                  );
                                }}
                                className="flex items-center justify-center bg-[#ff002c] bg-opacity-90 h-10 w-10 rounded-full mx-3 border-2 duration-300 border-red-600 hover:border-red-500 hover:animate-shake"
                              >
                                <i className="fa-regular fa-trash-can fa-lg text-white"></i>
                              </button>
                            </div>

                            {/* Bottom text container */}
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                              <div className="flex justify-between py-1">
                                <span className="text-lg lg:text-lg font-medium">
                                  {item.name}
                                </span>
                                <span className="text-lg lg:text-lg font-medium">
                                  {item.age}
                                </span>
                              </div>
                              <p className="text-sm lg:text-base truncate">
                                {(item.Summary || "")}
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))
                    : !loading && <p className="text-center text-xl"></p>}
                </>
              )}
            </div>
            :
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {selectedCategory === "Girls" ? (
                <>
                  {girlRealmedataPublic.length > 0
                    ? girlRealmedataPublic.map((item, index) => (
                      <div key={index} className="relative group" onClick={() => openModal(item.assistant, item)}>
                        {/* Skeleton Loader */}
                        {isLoadingsingleimage && (
                          <div className="h-80 w-full xl:h-[500px] absolute rounded-2xl p-2 flex bg-[#2a2a2a] justify-center items-center">
                            <div className="relative w-full h-full">
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height="100%"
                                width="100%"
                                sx={{
                                  borderRadius: "12px",
                                  background: "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                                  backgroundSize: "200% 100%",
                                }}
                              />
                              <div className="absolute inset-0 flex flex-col justify-between">
                                <div className="grid justify-end">
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        )}
                        {/* Actual Image and Content */}
                        <div className="overflow-hidden rounded-xl relative"
                          onClick={() => openModal(item.assistant_id, item)}
                          onMouseEnter={() => {
                            setIsHovered(index);

                            // Only try to load video if it exists
                            if (item.img_url2) {
                              const videoElement = document.getElementById(`hover-video-${index}`);
                              if (videoElement) {
                                videoElement.load();
                                videoElement.play().catch(e => console.log("Video play error:", e));
                              }
                            }
                          }}
                          onMouseLeave={() => setIsHovered(null)}
                        >
                          {/* Base image - always present */}
                          <img
                            loading="lazy"
                            src={item.img_url}
                            alt={`Img of ${item.name}`}
                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealGItems.includes(index) ? "hidden" : ""
                              } ${isHovered === index && item.img_url2
                                ? 'opacity-0 transition-opacity duration-1000 ease-in-out'
                                : isHovered === index
                                  ? 'scale-105 shadow-lg transition-transform duration-1000 ease-in-out'
                                  : 'transition-transform duration-1000 ease-in-out'
                              }`}
                            onClick={() => openModal(item.assistant_id, item)}
                            onLoad={handleImageLoad}
                            style={item.img_url2 ? {
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              zIndex: 1
                            } : {}}
                          />

                          {/* Video element - only rendered if item has video URL */}
                          {item.img_url2 && (
                            <video
                              id={`hover-video-${index}`}
                              src={item.img_url2}
                              alt={`Video of ${item.name}`}
                              className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealGItems.includes(index) ? "hidden" : ""
                                }`}
                              autoPlay={isHovered === index}
                              loop
                              muted
                              playsInline
                              style={{
                                opacity: isHovered === index ? 1 : 0,
                                transition: 'opacity 1000ms ease-in-out',
                                position: 'relative',
                                zIndex: 0
                              }}
                            />
                          )}

                          {/* Overlay with text */}
                          {/* Overlay with text - same as your original */}
                          <div
                            className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100 ${deletedRealGItems.includes(index) ? "hidden" : ""
                              }`}
                            style={{ zIndex: 2 }}
                          >
                            {/* Button in top-right corner */}
                            <div className="grid justify-end">
                              <button
                                onClick={() => navigate(`/aichat`)}
                                className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake"
                              >
                                <img
                                  loading="lazy"
                                  src="/assets/img/Group-icon.png"
                                  alt="Icon"
                                  className="w-10 h-10"
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  generatepage(item.assistant_id);
                                }}
                                className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake"
                              >
                                <img
                                  loading="lazy"
                                  src="/assets/img/Group-icon-msg.png"
                                  alt="Icon"
                                  className="w-10 h-10"
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deletegeneration(item.assistant_id, index, "GReal");
                                }}
                                className="flex items-center justify-center bg-[#ff002c] bg-opacity-90 h-10 w-10 rounded-full mx-3 border-2 duration-300 border-red-600 hover:border-red-500 hover:animate-shake"
                              >
                                <i className="fa-regular fa-trash-can fa-lg text-white"></i>
                              </button>
                            </div>

                            {/* Bottom text container */}
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                              <div className="flex justify-between py-1">
                                <span className="text-lg lg:text-lg font-medium">
                                  {item.name}
                                </span>
                                <span className="text-lg lg:text-lg font-medium">
                                  {item.age}
                                </span>
                              </div>
                              <p className="text-sm lg:text-base truncate">
                                {(item.Summary || "")}
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))
                    : !loading && <p className="text-center text-xl">No data available</p>}
                </>

              ) : selectedCategory === "Anime" ? (
                <>
                  {girlAnimedataPublic.length > 0
                    ? girlAnimedataPublic.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => openModal(item.assistant, item)}
                        className={`relative group ${deletedAnimeItems.includes(index) ? "hidden" : ""
                          }`}
                      >
                        {isLoadingsingleimage && (
                          <div className="h-80 w-full xl:h-[500px] absolute rounded-2xl p-2 flex bg-[#2a2a2a] justify-center items-center">
                            <div className="relative w-full h-full">
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height="100%"
                                width="100%"
                                sx={{
                                  borderRadius: "12px",
                                  background: "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                                  backgroundSize: "200% 100%",
                                }}
                              />
                              <div className="absolute inset-0 flex flex-col justify-between">
                                <div className="grid justify-end">
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        )}
                        {/* Actual Image and Content */}
                        <div className="overflow-hidden rounded-xl relative"
                          onClick={() => openModal(item.assistant_id, item)}
                          onMouseEnter={() => {
                            setIsHovered(index);

                            // Only try to load video if it exists
                            if (item.img_url2) {
                              const videoElement = document.getElementById(`hover-video-${index}`);
                              if (videoElement) {
                                videoElement.load();
                                videoElement.play().catch(e => console.log("Video play error:", e));
                              }
                            }
                          }}
                          onMouseLeave={() => setIsHovered(null)}
                        >
                          {/* Base image - always present */}
                          <img
                            loading="lazy"
                            src={item.img_url}
                            alt={`Img of ${item.name}`}
                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealGItems.includes(index) ? "hidden" : ""
                              } ${isHovered === index && item.img_url2
                                ? 'opacity-0 transition-opacity duration-1000 ease-in-out'
                                : isHovered === index
                                  ? 'scale-105 shadow-lg transition-transform duration-1000 ease-in-out'
                                  : 'transition-transform duration-1000 ease-in-out'
                              }`}
                            onClick={() => openModal(item.assistant_id, item)}
                            onLoad={handleImageLoad}
                            style={item.img_url2 ? {
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              zIndex: 1
                            } : {}}
                          />

                          {/* Video element - only rendered if item has video URL */}
                          {item.img_url2 && (
                            <video
                              id={`hover-video-${index}`}
                              src={item.img_url2}
                              alt={`Video of ${item.name}`}
                              className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealGItems.includes(index) ? "hidden" : ""
                                }`}
                              autoPlay={isHovered === index}
                              loop
                              muted
                              playsInline
                              style={{
                                opacity: isHovered === index ? 1 : 0,
                                transition: 'opacity 1000ms ease-in-out',
                                position: 'relative',
                                zIndex: 0
                              }}
                            />
                          )}

                          {/* Overlay with text */}
                          <div className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100 ${deletedRealGItems.includes(index) ? "hidden" : ""}`}>
                            {/* Button in top-right corner */}
                            <div className="grid justify-end">
                              <button onClick={(e) => { e.stopPropagation(); newCharAdded(item.assistant) }} className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                <img loading="lazy" src="/assets/img/Group-icon.png" alt="Icon" className="w-10 h-10" />
                              </button>
                              <button onClick={(e) => { e.stopPropagation(); generatepage(item.assistant) }} className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake">
                                <img loading="lazy" src="/assets/img/Group-icon-msg.png" alt="Icon" className="w-10 h-10" />
                              </button>
                            </div>

                            {/* Bottom text container */}
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                              <div className="flex justify-between py-1">
                                <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                              </div>
                              <p className="text-sm lg:text-base truncate">{(item.Summary || "")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : !loading && <p className="text-center text-xl"></p>}
                </>
              ) : (
                <>
                  {boyRealmedataPublic.length > 0
                    ? boyRealmedataPublic.map((item, index) => (
                      <div key={index} className="relative group" onClick={() => openModal(item.assistant, item)}>
                        {/* Image with hover effects */}
                        {isLoadingsingleimage && (
                          <div className="h-80 w-full xl:h-[500px] absolute rounded-2xl p-2 flex bg-[#2a2a2a] justify-center items-center">
                            <div className="relative w-full h-full">
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height="100%"
                                width="100%"
                                sx={{
                                  borderRadius: "12px",
                                  background: "linear-gradient(to right, #2a2a2a, #222222, #2a2a2a)",
                                  backgroundSize: "200% 100%",
                                }}
                              />
                              <div className="absolute inset-0 flex flex-col justify-between">
                                <div className="grid justify-end">
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                  <Skeleton variant="rectangular" animation="pulse" sx={{
                                    borderRadius: "9999px",
                                    background: "black", height: "40px", width: "40px", padding: "8px 12px"
                                  }} />
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        )}
                        {/* Actual Image and Content */}
                        <div className="overflow-hidden rounded-xl relative"
                          onClick={() => openModal(item.assistant_id, item)}
                          onMouseEnter={() => {
                            setIsHovered(index);

                            // Only try to load video if it exists
                            if (item.img_url2) {
                              const videoElement = document.getElementById(`hover-video-${index}`);
                              if (videoElement) {
                                videoElement.load();
                                videoElement.play().catch(e => console.log("Video play error:", e));
                              }
                            }
                          }}
                          onMouseLeave={() => setIsHovered(null)}
                        >
                          {/* Base image - always present */}
                          <img
                            loading="lazy"
                            src={item.img_url}
                            alt={`Img of ${item.name}`}
                            className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealGItems.includes(index) ? "hidden" : ""
                              } ${isHovered === index && item.img_url2
                                ? 'opacity-0 transition-opacity duration-1000 ease-in-out'
                                : isHovered === index
                                  ? 'scale-105 shadow-lg transition-transform duration-1000 ease-in-out'
                                  : 'transition-transform duration-1000 ease-in-out'
                              }`}
                            onClick={() => openModal(item.assistant_id, item)}
                            onLoad={handleImageLoad}
                            style={item.img_url2 ? {
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              zIndex: 1
                            } : {}}
                          />

                          {/* Video element - only rendered if item has video URL */}
                          {item.img_url2 && (
                            <video
                              id={`hover-video-${index}`}
                              src={item.img_url2}
                              alt={`Video of ${item.name}`}
                              className={`h-80 w-full xl:h-[500px] rounded-xl object-cover ${deletedRealGItems.includes(index) ? "hidden" : ""
                                }`}
                              autoPlay={isHovered === index}
                              loop
                              muted
                              playsInline
                              style={{
                                opacity: isHovered === index ? 1 : 0,
                                transition: 'opacity 1000ms ease-in-out',
                                position: 'relative',
                                zIndex: 0
                              }}
                            />
                          )}

                          {/* Overlay with text */}
                          <div className={`absolute inset-0 flex flex-col justify-between opacity-100 transition-opacity duration-300 group-hover:opacity-100 ${deletedRealGItems.includes(index) ? "hidden" : ""}`}>
                            {/* Button in top-right corner */}
                            <div className="grid justify-end">
                              <button onClick={(e) => { e.stopPropagation(); newCharAdded(item.assistant) }} className="text-white font-medium rounded-2xl px-3 py-2 hover:animate-shake">
                                <img loading="lazy" src="/assets/img/Group-icon.png" alt="Icon" className="w-10 h-10" />
                              </button>
                              <button onClick={(e) => { e.stopPropagation(); generatepage(item.assistant) }} className="text-white font-medium rounded-2xl px-3 pb-2 hover:animate-shake">
                                <img loading="lazy" src="/assets/img/Group-icon-msg.png" alt="Icon" className="w-10 h-10" />
                              </button>
                            </div>

                            {/* Bottom text container */}
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-2 rounded-b-lg">
                              <div className="flex justify-between py-1">
                                <span className="text-lg lg:text-lg font-medium">{item.name}</span>
                                <span className="text-lg lg:text-lg font-medium">{item.age}</span>
                              </div>
                              <p className="text-sm lg:text-base truncate">{(item.Summary || "")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    : !loading && <p className="text-center text-xl"></p>}
                </>
              )}
            </div>
          }

          {/* Modal */}
          {isModalOpen && modalData && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div
                className="bg-white p-8 rounded w-[85%] md:w-[80%] mt-10 xxs:h-[70%] md:h-[80%] flex flex-col items-center justify-center"
                style={{
                  // backgroundImage: `url(${modalImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  // filter: 'contrast(0.5)',
                }}
              >
                <div className="relative -top-[3%] left-[50%]">
                  <button
                    onClick={closeModal}
                    className="text-black border-none  py-2 rounded"
                  >
                    <i className="fa-regular fa-2xl fa-circle-xmark"></i>
                  </button>
                </div>
                <div
                  className="flex flex-col gap-5 sm:gap-16 sm:flex-row overflow-scroll"
                  style={{ scrollbarWidth: "none" }}
                >
                  <div>
                    {console.log(modalData)}
                    <img
                      loading="lazy"
                      src={modalData.Image}
                      alt="Generated Img"
                      className="w-full h-[300px] sm:h-[330px] md:h-[400px] lg:w-[400px] xl:w-[500px] lg:h-[500px] xl:h-[600px] 2xl:w-[570px] 2xl:h-[621px] rounded-xl"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="flex flex-col gap-8">
                    <div className="flex justify-between">
                      <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                        {modalData.name}
                      </h2>
                      <h2 className="text-[#b30016d9] text-lg sm:text-xl font-bold md:text-2xl lg:text-4xl whitespace-nowrap">
                        <span
                          onClick={() => toggleLike(modalData.assistant_id)}
                          className="ms-5"
                        >
                          {liked ? (
                            <>
                              <i className="fa-solid fa-sm fa-heart"></i>{" "}
                              <span className="text-lg">{likesCount} Liked</span>
                            </>
                          ) : (
                            <>
                              <i className="fa-regular fa-sm fa-heart"></i>{" "}
                              <span className="text-lg">{likesCount} Likes</span>
                            </>
                          )}
                        </span>
                      </h2>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 mb-3">
                      {itemsToDisplay.map(({ label, value, src }, index) => (
                        <div key={index} className="flex  items-start gap-2">
                          <img
                            loading="lazy"
                            src={src}
                            className="icon w-5 md:w-10 mt-1 "
                            alt="icon"
                          />

                          <div className="flex flex-col">
                            <span className="text-muted text-gray-800 font-bold text-[14px] mo:text-lg md:text-xl  ">
                              {label}
                            </span>
                            <span className="uppercase text-[12px] mo:text-base md:text-lg">
                              {value}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    {selectedCreations === "myCreations" ?
                      <div>
                        <div class="btn-container">
                          <label class="switch btn-color-mode-switch">
                            <input type="checkbox" name="color_mode" id="color_mode" value="1" checked={publicStatus === true ? true : false} onChange={() => handleToggle(modalData.assistant_id, modalData)} />
                            <label for="color_mode" data-on="Public" data-off="Private" class="btn-color-mode-switch-inner"></label>
                          </label>
                        </div>
                      </div>
                      :
                      ""
                    }

                    <div className="flex items-start">
                      <span className="icon mt-1 mr-2 icon-summary"></span>
                      <div className="flex flex-col">
                        <span className="font-semibold text-muted text-base mo:text-lg md:text-xl lg:text-2xl ">
                          About:
                        </span>
                        <span className="text-[12px] mo:text-base md:text-lg lg:text-xl">
                          {modalData.Summary}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center w-full mt-7">
                  <button
                    className="bg-red-800 text-white font-medium rounded-2xl mr-2 p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%]"
                    onClick={() => newCharAdded(modalData.assistant_id)}
                  >
                    Chat Now
                  </button>
                  <button
                    className="bg-[#db3c3c] text-white font-medium rounded-2xl p-2 sm:p-4 lg:p-4 w-[35%] lg:w-[25%]"
                    onClick={() => generatepage(modalData.assistant_id)}
                  >
                    Generate Image
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {isErrorModal && <UpgradeNow errorCloseModal={() => setIsErrorModal(false)} />}
      </PageWrapper>
      <Footer />
    </>
  );
}

export default MyCreation;
