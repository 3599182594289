import React, { useState } from "react";
import {
  colors,
  hobbies_girls,
  images0,
  images1,
  images2,
  images3,
  images4,
  images5,
  images6,
  images7,
  images8,
  images9,
  images11,
  images0_boys,
  images1_boys,
  images2_boys,
  images3_boys,
  images4_boys,
  images5_boys,
  images8_boys,
  images9_boys,
  images11_boys,
  images12_boys,
  hobbies_boys,
  images12,
} from "../../../assets/data"; // Ensure the correct path to your data file
import {
  Card,
  CardContent,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
} from "@mui/material";

const NewChar = () => {
  const [character, setCharacter] = useState({
    gender: "",
    style: "",
    ethnicity: "",
    age: "",
    eyes: "",
    hair: "",
    color: "",
    body_type: "",
    breast_size: "",
    butt_size: "",
    personality: "",
    voice: "",
    occupation: "",
    relationship: "",
    hobbies: "",
  });
  const [errors, setErrors] = useState({});

  const gender = ["1 Girl", "1 Boy"];

  // Map for options based on gender
  const genderOptions = {
    "1 Girl": {
      style: images0,
      ethnicity: images1,
      age: images2,
      eyes: images3,
      hair: images4,
      body_type: images5,
      breast_size: images6,
      butt_size: images7,
      personality: images8,
      voice: images9,
      occupation: images11,
      relationship: images12,
      hobbies: hobbies_girls,
      color: colors,
    },
    "1 Boy": {
      style: images0_boys,
      ethnicity: images1_boys,
      age: images2_boys,
      eyes: images3_boys,
      hair: images4_boys,
      body_type: images5_boys,
      personality: images8_boys,
      voice: images9_boys,
      occupation: images11_boys,
      relationship: images12_boys,
      hobbies: hobbies_boys,
      color: colors,
    },
  };

  const handleChange = (key) => (event) => {
    setCharacter((prev) => ({ ...prev, [key]: event.target.value }));
    setErrors((prev) => ({ ...prev, [key]: "" })); // Clear error on selection
  };

  const handleSubmit = () => {
    let newErrors = {};
    Object.keys(character).forEach((key) => {
      if (!character[key]) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      alert("Character created successfully!");
    }
  };

  // Accept error as a prop for each dropdown
  const Dropdown = ({ label, options, selected, onChange, error }) => (
    <FormControl fullWidth required error={Boolean(error)}>
      <InputLabel>{label}</InputLabel>
      <Select value={selected} onChange={onChange} label={label} required className="capitalize">
        {options.map((option, index) => (
          <MenuItem key={index} value={option.title || option.color || option} className="capitalize">
            {option.title || option.color || option}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );

  return (
    <div className="flex flex-col items-center gap-4 p-6">
      <h2 className="text-2xl font-semibold">Create Your Character</h2>
      <Card className="w-full max-w-4xl p-4">
        <CardContent className="flex flex-col gap-4">
          {/* Gender Dropdown */}
          <Dropdown
            label="Select Gender"
            options={gender}
            selected={character.gender}
            onChange={handleChange("gender")}
            error={errors.gender}
          />

          <Grid container spacing={2}>
            {/* Style Dropdown based on Gender */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Style"
                options={genderOptions[character.gender]?.style || []}
                selected={character.style}
                onChange={handleChange("style")}
                error={errors.style}
              />
            </Grid>

            {/* Ethnicity */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Ethnicity"
                options={genderOptions[character.gender]?.ethnicity || []}
                selected={character.ethnicity}
                onChange={handleChange("ethnicity")}
                error={errors.ethnicity}
              />
            </Grid>

            {/* Age */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Age"
                options={genderOptions[character.gender]?.age || []}
                selected={character.age}
                onChange={handleChange("age")}
                error={errors.age}
              />
            </Grid>

            {/* Eye Color */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Eye Color"
                options={genderOptions[character.gender]?.eyes || []}
                selected={character.eyes}
                onChange={handleChange("eyes")}
                error={errors.eyes}
              />
            </Grid>

            {/* Hair Style */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Hair Style"
                options={genderOptions[character.gender]?.hair || []}
                selected={character.hair}
                onChange={handleChange("hair")}
                error={errors.hair}
              />
            </Grid>

            {/* Hair Color */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Hair Color"
                options={genderOptions[character.gender]?.color || []}
                selected={character.color}
                onChange={handleChange("color")}
                error={errors.color}
              />
            </Grid>

            {/* Body Type */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Body Type"
                options={genderOptions[character.gender]?.body_type || []}
                selected={character.body_type}
                onChange={handleChange("body_type")}
                error={errors.body_type}
              />
            </Grid>

            {/* Only show Breast Size and Butt Size for Girls */}
            {character.gender === "1 Girl" && (
              <>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    label="Select Breast Size"
                    options={genderOptions[character.gender]?.breast_size || []}
                    selected={character.breast_size}
                    onChange={handleChange("breast_size")}
                    error={errors.breast_size}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    label="Select Butt Size"
                    options={genderOptions[character.gender]?.butt_size || []}
                    selected={character.butt_size}
                    onChange={handleChange("butt_size")}
                    error={errors.butt_size}
                  />
                </Grid>
              </>
            )}

            {/* Personality */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Personality"
                options={genderOptions[character.gender]?.personality || []}
                selected={character.personality}
                onChange={handleChange("personality")}
                error={errors.personality}
              />
            </Grid>

            {/* Voice */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Voice"
                options={genderOptions[character.gender]?.voice || []}
                selected={character.voice}
                onChange={handleChange("voice")}
                error={errors.voice}
              />
            </Grid>

            {/* Occupation */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Occupation"
                options={genderOptions[character.gender]?.occupation || []}
                selected={character.occupation}
                onChange={handleChange("occupation")}
                error={errors.occupation}
              />
            </Grid>

            {/* Relationship */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Relationship"
                options={genderOptions[character.gender]?.relationship || []}
                selected={character.relationship}
                onChange={handleChange("relationship")}
                error={errors.relationship}
              />
            </Grid>

            {/* Hobbies */}
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Select Hobbies"
                options={genderOptions[character.gender]?.hobbies || []}
                selected={character.hobbies}
                onChange={handleChange("hobbies")}
                error={errors.hobbies}
              />
            </Grid>
          </Grid>

          <Button variant="contained" className="mt-4 rounded-lg text-lg !bg-red-800 !hover:bg-red-700 text-white py-2 px-4" onClick={handleSubmit}>
            Create Character
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewChar;
