import React from "react";
import Footer from "../components/Footer";
import PageWrapper from "../../utilities/Metatags";

function CookiePolicy() {
  return (
    <>
      <PageWrapper
        title={"Cookie Policy | Eromantic AI - How We Use Cookies"}
        description={
          "Learn how Eromantic AI uses cookies to enhance your experience. Our Cookie Policy explains how we collect and manage your data to improve your service"
        }
        keywords={
          "AI Girlfriend, Virtual Girlfriend, Anime Companions, Interactive AI, NSFW-friendly"
        }
      >
        <div className="mx-auto px-4 sm:px-6 md:px-10 lg:px-16">
          <div className="rounded-lg shadow-lg p-6 sm:p-8 md:p-12">
            <p className="text-[#990013] text-center text-2xl md:text-4xl lg:text-5xl font-semibold mb-8 leading-tight">
              Cookie Policy
            </p>

            <h1 className="text-[#990013] text-xl md:text-2xl lg:text-3xl font-semibold mb-4">
              Introduction
            </h1>
            <p className="text-gray-700 text-base md:text-lg lg:text-xl leading-relaxed mb-6">
              This Cookie Policy explains how cookies and similar technologies
              are used on our website. By using our website, you consent to the
              use of cookies in accordance with this Cookie Policy.
            </p>

            <h1 className="text-[#990013] text-xl md:text-2xl lg:text-3xl font-semibold mb-4">
              What are cookies?
            </h1>
            <p className="text-gray-700 text-base md:text-lg lg:text-xl leading-relaxed mb-6">
              Cookies are small text files stored on your device (computer,
              tablet, mobile phone) when you visit a website. They are widely
              used to make websites work more efficiently and provide
              information to website owners.
            </p>

            <h1 className="text-[#990013] text-xl md:text-2xl lg:text-3xl font-semibold mb-4">
              Types of cookies we use:
            </h1>
            <div className="space-y-6 mb-6">
              <div className="p-4 rounded-lg shadow-md">
                <h2 className="text-[#990013] text-lg md:text-xl font-semibold">
                  1. Essential cookies
                </h2>
                <p className="text-gray-700 text-sm md:text-base leading-relaxed">
                  These cookies are necessary for the website to function
                  properly. They enable core functionality such as page
                  navigation and access to secure areas. The website cannot
                  function properly without these cookies.
                </p>
              </div>
              <div className="p-4 rounded-lg shadow-md">
                <h2 className="text-[#990013] text-lg md:text-xl font-semibold">
                  2. Performance cookies
                </h2>
                <p className="text-gray-700 text-sm md:text-base leading-relaxed">
                  These cookies collect information about how visitors use our
                  website, such as which pages are visited most often, and if
                  visitors receive error messages. These cookies do not collect
                  personally identifiable information. The data collected is
                  used to improve the website.
                </p>
              </div>
              <div className="p-4 rounded-lg shadow-md">
                <h2 className="text-[#990013] text-lg md:text-xl font-semibold">
                  3. Functionality cookies
                </h2>
                <p className="text-gray-700 text-sm md:text-base leading-relaxed">
                  These cookies allow the website to remember choices you make
                  (such as content preferences or region) and provide enhanced
                  personalized features. They may also store preferences to
                  avoid repeating warning messages.
                </p>
              </div>
            </div>

            <h1 className="text-[#990013] text-xl md:text-2xl lg:text-3xl font-semibold mb-4">
              Google Analytics
            </h1>
            <p className="text-gray-700 text-base md:text-lg lg:text-xl leading-relaxed mb-6">
              We use Google Analytics, a web analytics service provided by
              Google, Inc. ('Google'). Google Analytics uses cookies to help
              analyze website usage. The information generated (including your
              IP address) is used to evaluate website activity, compile reports,
              and improve functionality.
            </p>

            <h1 className="text-[#990013] text-xl md:text-2xl lg:text-3xl font-semibold mb-4">
              Managing cookies
            </h1>
            <p className="text-gray-700 text-base md:text-lg lg:text-xl leading-relaxed mb-6">
              You can control or delete cookies as you wish. Adjust your browser
              settings to delete or prevent cookie placement. Note that this may
              impact website functionality.
            </p>

            <h1 className="text-[#990013] text-xl md:text-2xl lg:text-3xl font-semibold mb-4">
              Changes to this Cookie Policy
            </h1>
            <p className="text-gray-700 text-base md:text-lg lg:text-xl leading-relaxed mb-6">
              We may update this Cookie Policy periodically to reflect changes.
              Please revisit it regularly to stay informed.
            </p>

            <h1 className="text-[#990013] text-xl md:text-2xl lg:text-3xl font-semibold mb-4">
              Contact us
            </h1>
            <p className="text-gray-700 text-base md:text-lg lg:text-xl leading-relaxed">
              For questions, contact us at{" "}
              <a
                href="mailto:support@eromantic.ai"
                className="text-[#990013] underline"
              >
                support@eromantic.ai
              </a>
              .
            </p>
          </div>
        </div>
      </PageWrapper>

      <Footer />
    </>
  );
}

export default CookiePolicy;
