import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Togal from './Togal';
import { DeleteImg, rename } from '../../assets';
import DeletePopup from './DeletePopup';
import UserApiClient from '../../utilities/apiClients';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const StaticCharacterCard = ({ id, name, gender, age, ethnicity, style, eyes, hair_style, hair_color, body_type, breast_size, butt_size, personality, voice, occupation, hobbies, relationship, region, summary, img_url, img_url2, vid_url, likes, created_at, published }) => {
    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPublished, setIsPublished] = useState(published);

    const handleCharacterDetails = async () => {
        await UserApiClient.get(`api/character_details/${id}/`, {}, true).then(res => {
            if (res.success) {
                navigate(`/admin/character/${id}`, { state: { character: res.data } });
            }
        });
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handleDelete = async () => {
        await UserApiClient.delete(`api/delete_character/${id}/`, {}, true).then(res => {
            if (res.success) {
                setIsPopupOpen(false);
                window.location.reload();
            }
        });
    };

    const handleStatusChange = async (event) => {
        const data = new FormData();
        data.append('published', event.target.checked);
        await UserApiClient.put(`api/update_character_status/${id}/`, data, true).then(res => {
            if (res.success) {
                setIsPublished(event.target.checked);
            }
        });
    };

    return (
        <>
            <div className="border-2 border-gray-300 rounded-xl w-full max-w-xs lg:max-w-md font-josefin-sans shadow-lg hover:shadow-2xl transition-shadow p-4 space-y-4">
                {/* Header Section */}
                <div className="flex justify-between items-center">
                    <Togal handleChange={handleStatusChange} value={isPublished} />
                    <div className="flex gap-3">
                        <img loading="lazy" src={DeleteImg} alt="delete" className="cursor-pointer hover:scale-110 transition-transform" onClick={togglePopup} />
                    </div>
                </div>

                {/* Character Image and Details */}
                <div onClick={handleCharacterDetails} className="cursor-pointer">
                    <Carousel
                        autoPlay
                        interval={3000}
                        infiniteLoop
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        transitionTime={500}
                        className="rounded-xl overflow-hidden mb-4"
                    >
                        <img loading="lazy" src={img_url} alt="character" className="w-full h-64 object-cover rounded-lg" />
                        <img loading="lazy" src={img_url2} alt="character" className="w-full h-64 object-cover rounded-lg" />
                    </Carousel>

                    {/* Character Information */}
                    <div className="space-y-2">
                        <p className="text-xl font-semibold text-gray-900">{name} ({age} years old)</p>
                        <p className="text-sm text-gray-600">{ethnicity} | {gender} | {region}</p>
                        <p className="text-sm text-gray-600 mt-1">Style: {style} | Eyes: {eyes}</p>
                        <p className="text-sm text-gray-600">Hair: {hair_style}, {hair_color}</p>
                        <p className="text-sm text-gray-600">Body: {body_type}, Breast: {breast_size}, Butt: {butt_size}</p>
                        <p className="text-sm text-gray-600 mt-2 font-semibold">Personality: {personality}</p>
                        <p className="text-sm text-gray-600">Voice: {voice}</p>
                        <p className="text-sm text-gray-600">Occupation: {occupation}</p>
                        <p className="text-sm text-gray-600">Hobbies: {hobbies}</p>
                        <p className="text-sm text-gray-600">Relationship: {relationship}</p>
                        <p className="text-sm text-gray-600 mt-2">Summary: {summary}</p>
                        <p className="text-sm text-gray-600 mt-2">Likes: {likes}</p>

                        {/* Video */}
                        {vid_url && (
                            <video controls className="w-full mt-2 rounded-lg" src={vid_url} />
                        )}
                        <p className="text-xs text-gray-500 mt-2">Created at {created_at}</p>
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Popup */}
            {isPopupOpen && <DeletePopup togglePopup={togglePopup} handleDelete={handleDelete} />}
        </>
    );
};

export default StaticCharacterCard;
